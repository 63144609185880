import axios from "axios";
import {isExpired} from "react-jwt";

const privateApiClient = axios.create({
    baseURL : `${process.env.REACT_APP_DJANGO_API_URL}/api`,
    headers: {
        "Content-Type": "application/json",
    },
});

export const privateSetupInterceptors = () => {
    let accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    privateApiClient.interceptors.request.use(
        async (config) => {
            const isMyTokenExpired = isExpired(accessToken);
            if (config.url !== `/token/` && accessToken && isMyTokenExpired) {
                const result = await axios.post(`${process.env.REACT_APP_DJANGO_API_URL}/api/token/refresh/`, {
                    refresh: refreshToken
                });
                localStorage.setItem('accessToken', result.data.access)
                localStorage.setItem('refreshToken', result.data.refresh)
                accessToken = result.data.access
            }
            config.headers["Authorization"] = "Bearer " + accessToken;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

export default privateApiClient;