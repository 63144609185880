import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    paper: {
        marginTop: 80,
        margin: 'auto',
        width: '25%',
        minWidth: 300,
        padding: 30
    }
});

export default useStyles;