import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useDispatch, useSelector} from "react-redux";
import {closeSnackBar} from "../../app/appSlice";
import {useEffect} from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const StyledSnackbars = ({variant, severity, text}) => {
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => app.isOpen && dispatch(closeSnackBar()), 2000)
    }, [dispatch, app.isOpen])
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={app.isOpen} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => dispatch(closeSnackBar())}>
                <Alert severity={severity || app.status} variant={variant} sx={{ width: '100%' }}>
                    {text || app.message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
