import {Paper, useMediaQuery} from "@mui/material";
import DcaConfiguration from "../../components/dcaConfiguration/dcaConfiguration";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as React from "react";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {getBotListLaunch, getDCAListLaunch} from "../../features/django/djangoSlice";
import DCABotListActive from "../../components/dcaBotListActive/dcaBotListActive";
import DCABotListActiveMobile from "../../components/dcaBotListActive/dcaBotListActiveMobile";
import DCABotListHistory from "../../components/dcaBotListHistory/dcaBotListHistory";
import DcaBotListHistoryMobile from "../../components/dcaBotListHistory/dcaBotListHistoryMobile";
import DcaConfigurationMobile from "../../components/dcaConfiguration/dcaConfigurationMobile";
import DCAPairListActiveMobile from "../../components/dcaPairListActive/dcaPairListActiveMobile";
import {binanceGetTickerLaunch} from "../../features/binance/binanceSlice";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function DcaBot() {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const handleChange = (event, newValue) => {
        setValue(newValue);

    };

    useEffect(() => {
        dispatch(getDCAListLaunch())
        dispatch(getBotListLaunch())
        dispatch(binanceGetTickerLaunch())
    }, [])

    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: isMobile ? 0 : 2}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {isMobile && (<Tab label="Deals" {...a11yProps(0)} />)}
                        <Tab label="Bots" {...a11yProps(isMobile ? 1 : 0)} />
                        <Tab label="History" {...a11yProps(isMobile ? 2 : 1)} />
                        <Tab label="New bot" {...a11yProps(isMobile ? 3 : 2)} />
                    </Tabs>
                </Box>
                {isMobile && (
                    <TabPanel value={value} index={0}>
                            <DCAPairListActiveMobile/>
                    </TabPanel>
                )}
                <TabPanel value={value} index={isMobile? 1 : 0}>
                    {isMobile ?
                        <DCABotListActiveMobile/>
                        :
                        <DCABotListActive/>
                    }
                </TabPanel>
                <TabPanel value={value} index={isMobile ? 2 : 1}>
                    {isMobile ?
                        <DcaBotListHistoryMobile/>
                        :
                        <DCABotListHistory/>
                    }
                </TabPanel>
                <TabPanel value={value} index={isMobile ? 3 : 2}>
                    {isMobile ?
                        <DcaConfigurationMobile/>
                        :
                        <DcaConfiguration/>
                    }
                </TabPanel>
            </Paper>
        </Box>
    );
}

export default DcaBot
