import * as React from 'react';
import Typography from "@mui/material/Typography";
import {Autocomplete, Chip} from "@mui/material";
import Grid from "@mui/material/Grid";
import useStyles from "./dcaConfiguration.styles";
import {StyledSelect} from "../select/styledSelect";
import {useDispatch, useSelector} from "react-redux";
import {binanceGetExchangeInfoLaunch} from "../../features/binance/binanceSlice";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useFormik} from 'formik';
import * as yup from 'yup';
import {postDCAConfigLaunch} from "../../features/django/djangoSlice";
import {StyledTextField} from "../textField/styledTextField";
import {StyledButton} from "../button/styledButton";
import DCABotConfigurationChart from "../dcaBotConfigurationChart/dcaBotConfigurationChart";

const DcaConfigurationFormMobile = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const exchangeInfo = useSelector((state) => state.binance.exchangeInfo);
    const accountInformation = useSelector((state) => state.django.accountInformation);
    const [exchange, setExchange] = React.useState("");
    const [market, setMarket] = React.useState("");
    const [pairs, setPairs] = React.useState([]);
    const [listPair, setListPair] = React.useState([{text: '', value: ''}]);
    const [availableQuote, setAvailableQuote] = React.useState(0);
    const [investmentAmount, setInvestmentAmount] = React.useState(0);
    const [priceDeviation, setPriceDeviation] = React.useState(0);
    const [freeQuoteAsset, setFreeQuoteAsset] = React.useState(0);
    const [chartData, setChartData] = React.useState([]);

    let listExchange = [
        {text: 'Binance', value: 'BIN'},
    ]

    let listStartSignal = [
        {text: 'Immediately', value: 'NONE'},
        {text: 'RSI', value: 'RSI'},
        {text: 'Stochastic', value: 'STOCH'},
        {text: 'MACD', value: 'MACD'},
        {text: 'Bollinger Bands', value: 'BB'},
        {text: 'Average Directional Index', value: 'ADX'},
        {text: 'Stochastic RSI', value: 'STOCH_RSI'},
        {text: 'Carré biais', value: 'CARRE_BIAIS'},
    ]

    let listStartSignalPeriod = [
        {text: '5 minutes', value: '5m'},
        {text: '15 minutes', value: '15m'},
        {text: '30 minutes', value: '30m'},
        {text: '1 hour', value: '1h'},
        {text: '4 hours', value: '4h'},
        {text: '1 day', value: '1d'},
    ]

    useEffect(() => {
        if (exchange === 'BIN') {
            dispatch(binanceGetExchangeInfoLaunch());
        }
    }, [dispatch, exchange])

    let listMarket = [
        {text: 'USDT', value: 'USDT'},
        {text: 'BTC', value: 'BTC'},
    ];

    useEffect(() => {
        setPairs([])
        setAvailableQuote(0)
        setListPair([{text: '', value: ''}])
        for (let i in exchangeInfo) {
            if (exchangeInfo[i].hasOwnProperty("symbol") && exchangeInfo[i].status === "TRADING" && exchangeInfo[i].quoteAsset === market) {
                setListPair(listPair => [...listPair, {text: exchangeInfo[i].symbol, value: exchangeInfo[i].symbol}])
            }
        }
        if (accountInformation.hasOwnProperty("balances")) {
            for (let i in accountInformation.balances) {
                if (accountInformation.balances[i].asset === market) {
                    setAvailableQuote(accountInformation.balances[i].free)
                }
            }
        }
    }, [exchangeInfo, market, accountInformation])


    const validationSchema = yup.object({
        name: yup
            .string('Name should be a string')
            .max(100, 'Name should be 100 characters or less')
            .required('Name is required'),
        base_order: yup
            .number('Base order should be a number')
            .min(10.30, `Minimum required is 10.30`)
            .max(availableQuote, "Not enough funds available")
            .required('Base order is required')
            .test('is-decimal', 'Too many decimal(s)', value => (value + "").match(new RegExp('^\\d+(\\.\\d{0,2})?$')),),
        max_active_pairs: yup
            .number('Max active pairs should be a number')
            .min(1, 'Minimum required is 1')
            .max(pairs.length, `Not enough pairs selected`)
            .integer('Max active pairs should be a integer')
            .required('Base order is required'),
        averaging_order_quantity: yup
            .number('Averaging order quantity should be a number')
            .min(1, 'Minimum required is 1')
            .max(10, `Not enough pairs selected`)
            .integer('Averaging order quantity should be a integer')
            .required('Averaging order quantity is required'),
        averaging_order_step: yup
            .number('Averaging order step should be a number')
            .min(1, 'Minimum required is 1')
            .max(2, 'Maximum required is 2')
            .required('Averaging order step is required')
            .test('is-decimal', 'Too many decimal(s)', value => (value + "").match(new RegExp('^\\d+(\\.\\d{0,2})?$')),),
        step_multiplier: yup
            .number('Averaging order step multiplier should be a number')
            .min(1, 'Minimum required is 1')
            .max(2, 'Minimum required is 2')
            .required('Averaging order step multiplier is required')
            .test('is-decimal', 'Too many decimal(s)', value => (value + "").match(new RegExp('^\\d+(\\.\\d{0,2})?$')),),
        averaging_order_amount: yup
            .number('Averaging order amount should be a number')
            .min(10.30, `Minimum required is 10.30`)
            .max(availableQuote, "Not enough funds available") // TO BE DONE
            .required('Averaging order amount is required')
            .test('is-decimal', 'Too many decimal(s)', value => (value + "").match(new RegExp('^\\d+(\\.\\d{0,2})?$')),),
        amount_multiplier: yup
            .number('Averaging order amount multiplier should be a number')
            .min(1, 'Minimum required is 1')
            .max(2, 'Minimum required is 2')
            .required('Averaging order amount multiplier is required')
            .test('is-decimal', 'Too many decimal(s)', value => (value + "").match(new RegExp('^\\d+(\\.\\d{0,2})?$')),),
        start_signal: yup
            .string('Start signal should be a string')
            .required('Start signal is required'),
        take_profit_step: yup
            .number('Take profit should be a string')
            .min(0.3, 'Minimum required is 0.3')
            .max(500, 'Minimum required is 500')
            .required('Take profit  is required')
            .test('is-decimal', 'Too many decimal(s)', value => (value + "").match(new RegExp('^\\d+(\\.\\d{0,2})?$')),),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            max_active_pairs: '',
            base_order: '',
            averaging_order_quantity: '',
            averaging_order_amount: '',
            averaging_order_step: '',
            step_multiplier: '',
            amount_multiplier: '',
            take_profit_method: '',
            start_signal: '',
            start_signal_period: '',
            take_profit_step: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(postDCAConfigLaunch({
                name: values.name,
                exchange: exchange,
                profit_currency: market,
                pairs_m2m: pairs,
                market: market,
                max_active_pairs: values.max_active_pairs,
                investment_amount: investmentAmount,
                base_order: values.base_order,
                averaging_order_amount: values.averaging_order_amount,
                averaging_order_quantity: values.averaging_order_quantity,
                averaging_order_step: values.averaging_order_step,
                amount_multiplier: values.amount_multiplier,
                step_multiplier: values.step_multiplier,
                take_profit_step: values.take_profit_step,
                take_profit_method: "AVG_PRICE",
                start_signal: values.start_signal,
                start_signal_period: values.start_signal_period,
                navigate
            }))
        }
    })

    useEffect(() => {
        if (formik.values.averaging_order_amount !== "" && formik.values.amount_multiplier !== "" && formik.values.base_order !== "" && formik.values.averaging_order_quantity !== "" && formik.values.max_active_pairs !== "" && market !== "") {
            let temp_investment_amount = 0
            let data = []
            temp_investment_amount += parseFloat(formik.values.base_order)
            data.push({averaging_order_number: 0, value: temp_investment_amount})
            for (let i = 0; i < formik.values.averaging_order_quantity; i++) {
                temp_investment_amount += formik.values.averaging_order_amount * (formik.values.amount_multiplier ** i);
                data.push({averaging_order_number: i + 1, value: temp_investment_amount})
            }
            temp_investment_amount = temp_investment_amount * formik.values.max_active_pairs
            setInvestmentAmount(temp_investment_amount)

            for (const balance of accountInformation.balances) {
                if (balance.asset === market) {
                    setFreeQuoteAsset(parseFloat(balance.free))
                    break;
                }
            }
            setChartData(data);
        }
    }, [formik.values.averaging_order_amount, formik.values.amount_multiplier, formik.values.base_order, formik.values.averaging_order_quantity, formik.values.max_active_pairs, market, accountInformation.balances])

    useEffect(() => {
        if (formik.values.averaging_order_quantity !== "" && formik.values.averaging_order_step !== "" && formik.values.step_multiplier !== "") {
            let temp_price_deviation = 0
            for (let i = 0; i < formik.values.averaging_order_quantity; i++) {
                temp_price_deviation += formik.values.averaging_order_step * (formik.values.step_multiplier ** i)
                setPriceDeviation(temp_price_deviation)
            }
        }
    }, [formik.values.averaging_order_quantity, formik.values.averaging_order_step, formik.values.step_multiplier])

    return (
        <Grid container direction={"column"}>
            <form onSubmit={formik.handleSubmit}>
                <Grid item style={{paddingBottom: 5}}>
                    <Typography variant="h6" color="primary">Main settings</Typography>
                </Grid>
                <Grid item>
                    <StyledTextField id="name"
                                     variant="outlined"
                                     name="name"
                                     type="text"
                                     placeholder="Long bot TP5%"
                                     label="Name"
                                     value={formik.values.name}
                                     onChange={(e) => {
                                         formik.handleChange(e);
                                     }}
                                     error={formik.touched.name && Boolean(formik.errors.name)}
                                     helperText={formik.touched.name && formik.errors.name}
                                     style={{height: 80, width: "100%"}}/>
                </Grid>
                <Grid item style={{paddingBottom: 24}}>
                    <StyledSelect
                        labelId="exchange"
                        id="outlined"
                        value={exchange}
                        label="Exchange"
                        listItems={listExchange}
                        handleChange={setExchange}
                    />
                </Grid>
                <Grid item style={{paddingBottom: 24}}>
                    <StyledSelect
                        labelId="market"
                        id="outlined"
                        value={market}
                        label="Market"
                        listItems={listMarket}
                        handleChange={setMarket}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        multiple
                        options={listPair}
                        value={listPair.filter((item) => pairs.includes(item.value))}
                        onChange={(event, newValue) => {
                            setPairs(newValue.map((item) => item.value));
                        }}
                        getOptionLabel={(option) => option.text}
                        renderInput={(params) => (
                            <StyledTextField {...params} label="Select cryptocurrency pairs"
                                             variant="outlined"/>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip label={option.text} {...getTagProps({index})} />
                            ))
                        }
                    />
                </Grid>
                <Grid item style={{paddingTop: 15, paddingBottom: 5}}>
                    <Typography variant="h6" color="primary">Base investment</Typography>
                </Grid>
                <Grid item>
                    <StyledTextField id="base_order"
                                     variant="outlined"
                                     name="base_order"
                                     type="text"
                                     placeholder="ex: 100.00"
                                     label="Base order amount ($)"
                                     value={formik.values.base_order}
                                     onChange={formik.handleChange}
                                     error={formik.touched.base_order && Boolean(formik.errors.base_order)}
                                     helperText={formik.touched.base_order && formik.errors.base_order}
                                     style={{height: 80, width: "100%"}}/>
                </Grid>
                <Grid item>
                    <StyledTextField id="max_active_pairs"
                                     variant="outlined"
                                     name="max_active_pairs"
                                     type="text"
                                     placeholder="ex: 4"
                                     label="Max active pairs"
                                     value={formik.values.max_active_pairs}
                                     onChange={formik.handleChange}
                                     error={formik.touched.max_active_pairs && Boolean(formik.errors.max_active_pairs)}
                                     helperText={formik.touched.max_active_pairs && formik.errors.max_active_pairs}
                                     style={{height: 80, width: "100%"}}/>
                </Grid>
                <Grid item style={{paddingBottom: 5}}>
                    <Typography variant="h6" color="primary">Averaging strategy</Typography>
                </Grid>

                <Grid item>
                    <StyledTextField id="averaging_order_quantity"
                                     variant="outlined"
                                     name="averaging_order_quantity"
                                     type="text"
                                     placeholder="ex: 5"
                                     label="Averaging order quantity"
                                     value={formik.values.averaging_order_quantity}
                                     onChange={(e) => {
                                         formik.handleChange(e);
                                     }}
                                     error={formik.touched.averaging_order_quantity && Boolean(formik.errors.averaging_order_quantity)}
                                     helperText={formik.touched.averaging_order_quantity && formik.errors.averaging_order_quantity}
                                     style={{height: 80, width: "100%"}}/>
                </Grid>
                <Grid item>
                    <StyledTextField id="averaging_order_step"
                                     variant="outlined"
                                     name="averaging_order_step"
                                     type="text"
                                     placeholder="ex: 1.20"
                                     label="Averaging order step (% from initial order)"
                                     value={formik.values.averaging_order_step}
                                     onChange={(e) => {
                                         formik.handleChange(e);
                                     }}
                                     error={formik.touched.averaging_order_step && Boolean(formik.errors.averaging_order_step)}
                                     helperText={formik.touched.averaging_order_step && formik.errors.averaging_order_step}
                                     style={{height: 80, width: "100%"}}/>
                </Grid>
                <Grid item>
                    <StyledTextField id="step_multiplier"
                                     variant="outlined"
                                     name="step_multiplier"
                                     type="text"
                                     placeholder="ex: 1.2"
                                     label="Step coefficient"
                                     value={formik.values.step_multiplier}
                                     onChange={(e) => {
                                         formik.handleChange(e);
                                     }}
                                     error={formik.touched.step_multiplier && Boolean(formik.errors.step_multiplier)}
                                     helperText={formik.touched.step_multiplier && formik.errors.step_multiplier}
                                     style={{height: 80, width: "100%"}}/>
                </Grid>
                <Grid item>
                    <StyledTextField id="averaging_order_amount"
                                     variant="outlined"
                                     name="averaging_order_amount"
                                     type="text"
                                     placeholder="ex: 100.00"
                                     label="Averaging order amount ($)"
                                     value={formik.values.averaging_order_amount}
                                     onChange={(e) => {
                                         formik.handleChange(e);
                                     }}
                                     error={formik.touched.averaging_order_amount && Boolean(formik.errors.averaging_order_amount)}
                                     helperText={formik.touched.averaging_order_amount && formik.errors.averaging_order_amount}
                                     style={{height: 80, width: "100%"}}/>
                </Grid>
                <Grid item>
                    <StyledTextField id="amount_multiplier"
                                     variant="outlined"
                                     name="amount_multiplier"
                                     type="text"
                                     placeholder="ex: 1.2"
                                     label="Amount coefficient"
                                     value={formik.values.amount_multiplier}
                                     onChange={(e) => {
                                         formik.handleChange(e);
                                     }}
                                     error={formik.touched.amount_multiplier && Boolean(formik.errors.amount_multiplier)}
                                     helperText={formik.touched.amount_multiplier && formik.errors.amount_multiplier}
                                     style={{height: 80, width: "100%"}}/>
                </Grid>

                <Grid item style={{paddingBottom: 5}}>
                    <Typography variant="h6" color="primary">Start signals</Typography>
                </Grid>
                <Grid item style={{paddingBottom: 24}}>
                    <StyledSelect
                        labelId="start_signal"
                        id="outlined"
                        value={formik.values.start_signal}
                        label="Start signal"
                        listItems={listStartSignal}
                        handleChange={formik.handleChange('start_signal')}
                        error={formik.touched.start_signal && Boolean(formik.errors.start_signal)}
                        helperText={formik.touched.start_signal && formik.errors.start_signal}
                    />
                </Grid>
                <Grid item style={{paddingBottom: 24}}>
                    <StyledSelect
                        labelId="start_signal_period"
                        id="outlined"
                        value={formik.values.start_signal_period}
                        label="Start signal timeframe"
                        listItems={listStartSignalPeriod}
                        handleChange={formik.handleChange('start_signal_period')}
                        error={formik.touched.start_signal_period && Boolean(formik.errors.start_signal_period)}
                        helperText={formik.touched.start_signal_period && formik.errors.start_signal_period}
                    />
                </Grid>
                <Grid item style={{paddingBottom: 5}}>
                    <Typography variant="h6" color="primary">Take profit</Typography>
                </Grid>
                <Grid item>
                    <StyledTextField id="take_profit_step"
                                     variant="outlined"
                                     name="take_profit_step"
                                     type="text"
                                     placeholder="ex: 3.00"
                                     label="Take profit (%)"
                                     value={formik.values.take_profit_step}
                                     onChange={(e) => {
                                         formik.handleChange(e);
                                     }}
                                     error={formik.touched.take_profit_step && Boolean(formik.errors.take_profit_step)}
                                     helperText={formik.touched.take_profit_step && formik.errors.take_profit_step}
                                     style={{height: 80, width: "100%"}}/>
                </Grid>
                <Grid item>
                    <Grid item style={{paddingBottom: 5}}>
                        <Typography variant="h6" color="primary">Bot summary</Typography>
                    </Grid>
                    <Grid container flexDirection="row" className={classes.kpi}>
                        <Grid item style={{paddingBottom: 5}} xs={7}>
                            <Typography variant="body2" style={{paddingBottom: 7, paddingTop: 10}}
                                        color="text.secondary">Available {market}</Typography>
                            <Typography variant="body2" style={{paddingBottom: 7, paddingTop: 10}} color="text.secondary">Max amount for
                                bot usage</Typography>
                            <Typography variant="body2" style={{paddingBottom: 7, paddingTop: 7}} color="text.secondary">Max safety
                                price deviation</Typography>
                            <Typography variant="body2" style={{paddingBottom: 7, paddingTop: 7}} color="text.secondary">% of {market} available used</Typography>
                            <Typography variant="body2" style={{paddingBottom: 10, paddingTop: 7}} color="text.secondary">% of {market} total used</Typography>
                        </Grid>
                        <Grid item style={{paddingBottom: 5}} xs={5}>
                            <Typography variant="body2" style={{paddingBottom: 7, paddingTop: 10}} align="right"
                                        color="primary">{freeQuoteAsset.toFixed(2) + " " + market} </Typography>
                            <Typography variant="body2" style={{paddingBottom: 7, paddingTop: 10}} align="right"
                                        color="error">{investmentAmount.toFixed(2) + " " + market} </Typography>
                            <Typography variant="body2" style={{paddingBottom: 7, paddingTop: 7}} align="right"
                                        color="text.secondary">{priceDeviation.toFixed(2)} %</Typography>
                            <Typography variant="body2" style={{paddingBottom: 7, paddingTop: 7}} align="right"
                                        color="text.secondary">{isNaN((investmentAmount / parseFloat(freeQuoteAsset) * 100)) ? "0.00" : (investmentAmount / parseFloat(freeQuoteAsset) * 100).toFixed(2)} %</Typography>
                            <Typography variant="body2" style={{paddingBottom: 10, paddingTop: 7}} align="right"
                                        color="text.secondary">{isNaN((investmentAmount / parseFloat(accountInformation.accountTotalUSDT) * 100)) ? "0.00" : (investmentAmount / parseFloat(accountInformation.accountTotalUSDT) * 100).toFixed(2)} %</Typography>
                        </Grid>
                    </Grid>
                    <Grid container flexDirection="column">
                        <Grid item style={{paddingTop: 10, paddingBottom: 10}}>
                            <StyledButton
                                variant="contained"
                                label="Create bot"
                                type="submit"
                                fullWidth={true}
                            />
                        </Grid>
                        {typeof (chartData) !== "undefined" && chartData.length !== 0 ?
                            <Grid item className={classes.chart}>
                                <Typography variant="subtitle2" color="primary"
                                            style={{paddingLeft: 28, paddingBottom: 10}}>Used amount per
                                    order</Typography>
                                <DCABotConfigurationChart chartData={chartData}/>
                            </Grid>
                            :
                            ""}
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
        ;
}

export default DcaConfigurationFormMobile;