import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useStyles from "./manualAveragingModal.styles";
import {StyledButton} from "../button/styledButton";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {postManualAveragingLaunch} from "../../features/django/djangoSlice";
import {DcaChart} from "../dcaChart/dcaChart";
import {Paper, useMediaQuery} from "@mui/material";
import {StyledTextField} from "../textField/styledTextField";
import {useFormik} from 'formik';
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {binanceGetExchangeInfoPairLaunch} from "../../features/binance/binanceSlice";


const ManualAveragingModal = ({bot_id, pair, base_asset, quote_asset, current_position_size, handleModalClose}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const exchangeInfoPair = useSelector((state) => state.binance.exchangeInfoPair);
    const [pairPriceFilter, setPairPriceFilter] = React.useState([]);
    const [pairMinNotional, setPairMinNotional] = React.useState([]);
    const [lotSizeFilter, setLotSizeFilter] = React.useState([]);
    const availableFunds = useSelector((state) => {
        const balances = state.django.accountInformation.balances;
        const usdtBalance = balances.find((balance) => balance.asset === quote_asset);
        return usdtBalance ? usdtBalance.free : 0;
    });
    const dcaGridData = useSelector((state) => state.django.dcaGridData);


    useEffect(() => {
        if (exchangeInfoPair.hasOwnProperty("filters")) {
            for (let i in exchangeInfoPair.filters) {
                if (exchangeInfoPair.filters[i].hasOwnProperty("filterType") && exchangeInfoPair.filters[i].filterType === "PRICE_FILTER") {
                    setPairPriceFilter(exchangeInfoPair.filters[i])
                }
                if (exchangeInfoPair.filters[i].hasOwnProperty("filterType") && exchangeInfoPair.filters[i].filterType === "LOT_SIZE") {
                    setLotSizeFilter(exchangeInfoPair.filters[i])
                }
                if (exchangeInfoPair.filters[i].hasOwnProperty("filterType") && exchangeInfoPair.filters[i].filterType === "NOTIONAL") {
                    setPairMinNotional(exchangeInfoPair.filters[i])
                }
            }
        }
    }, [pair, exchangeInfoPair]);

    useEffect(() => {
        dispatch(binanceGetExchangeInfoPairLaunch({pair}))
    }, [])

    const countDecimals = (value) => {
        if (value && Math.floor(value) !== value) {
            return value.toString().split('.')[1].length || 0;
        }
        return 0;
    };

    const validationSchema = yup.object({
        price: yup
            .number('Price')
            .min((pairPriceFilter.hasOwnProperty("minPrice") ? Number(pairPriceFilter.minPrice) : 0), "Price is too low")
            .max((pairPriceFilter.hasOwnProperty("maxPrice") ? Number(pairPriceFilter.maxPrice) : 0), "Price is too high")
            .required('Price is required')
            .test('is-decimal', 'Too many decimal(s), ', value => (value + "").match(new RegExp('^\\d+(\\.\\d{0,' + (pairPriceFilter.hasOwnProperty("tickSize") ? countDecimals(pairPriceFilter.tickSize) : 0) + '})?$')),),
        quantity: yup
            .number('Quantity')
            .min((lotSizeFilter.hasOwnProperty("minQty") ? Number(lotSizeFilter.minQty) : 0), "Quantity is too low")
            .max((lotSizeFilter.hasOwnProperty("maxQty") ? Number(lotSizeFilter.maxQty) : 0), 'Quantity is too high')
            .required('Quantity is required')
            .test('minNotional', 'Total value is too low', function (value) {
                const formValues = this.parent; // Get the parent object to access the price field
                return (Number(formValues.price) * Number(value)) >= Number(pairMinNotional.minNotional);
            })
            .test('availableFunds', 'Not enough funds', function (value) {
                const formValues = this.parent; // Get the parent object to access the price field
                return (Number(formValues.price) * Number(value)) <= Number(availableFunds);
            })
            .test('is-decimal', 'Too many decimal(s)', value => (value + "").match(new RegExp('^\\d+(\\.\\d{0,' + (lotSizeFilter.hasOwnProperty("stepSize") ? countDecimals(lotSizeFilter.stepSize) : 0) + '})?$')),),
    });


    const formik = useFormik({
        initialValues: {
            bot_id: bot_id,
            pair: pair,
            price: '',
            quantity: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(postManualAveragingLaunch({
                bot_id: bot_id,
                pair: pair,
                price: values.price,
                quantity: values.quantity,
                navigate
            }))
            handleModalClose()
        }
    })

    let breakEvenPrice = 0;
    let newBreakEvenPrice = 0;
    if (typeof (dcaGridData[0]) !== "undefined") {
        let totalCost = 0;
        let totalAmount = 0;

        for (let i in dcaGridData) {
            if (dcaGridData[i].order_status === "FILLED") {
                totalCost += dcaGridData[i].order_executed_qty * dcaGridData[i].order_price;
                totalAmount += dcaGridData[i].order_executed_qty;
            }
        }
        breakEvenPrice = totalCost / totalAmount
        totalCost += parseFloat(formik.values.price) * parseFloat(formik.values.quantity)
        totalAmount += parseFloat(formik.values.quantity)

        newBreakEvenPrice = totalCost / totalAmount
    }

    return (
        <Box className={classes.modal} sx={{width: '90%', height: isMobile ? '90%' : '80%'}}>
            {isMobile ?
                <Paper className={classes.paper}>
                    <Grid container flexDirection="column">
                        <Grid item>
                            <DcaChart bot_id={bot_id} pair={pair} base_asset={base_asset}
                                      new_break_even_price={newBreakEvenPrice} modal_type="averaging"/>
                        </Grid>
                        <Grid item>

                            <Grid container flexDirection="column">
                                <form onSubmit={formik.handleSubmit}>

                                    <Grid item style={{padding: 5}}>
                                        <StyledTextField id="price"
                                                         variant="outlined"
                                                         name="price"
                                                         type="text"
                                                         placeholder="ex: 15000.00"
                                                         label="Price"
                                                         value={formik.values.price}
                                                         onChange={formik.handleChange}
                                                         error={formik.touched.price && Boolean(formik.errors.price)}
                                                         helperText={formik.touched.price && formik.errors.price}
                                                         style={{height: 80, width: "100%"}}/>
                                    </Grid>
                                    <Grid item style={{padding: 5}}>
                                        <StyledTextField id="quantity"
                                                         variant="outlined"
                                                         name="quantity"
                                                         type="text"
                                                         placeholder="ex: 0.35"
                                                         label="Quantity"
                                                         value={formik.values.quantity}
                                                         onChange={formik.handleChange}
                                                         error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                                                         helperText={formik.touched.quantity && formik.errors.quantity}
                                                         style={{height: 80, width: "100%"}}/>
                                    </Grid>
                                    <Grid item style={{padding: 5}}>
                                        <Grid container flexDirection="row" className={classes.kpi}>
                                            <Grid item style={{paddingBottom: 5}} md={6}>
                                                <Typography style={{paddingBottom: 7, paddingTop: 10}}
                                                            color="text.secondary" variant="body2">Available
                                                    balance</Typography>
                                                <Typography style={{paddingBottom: 10, paddingTop: 7}}
                                                            color="text.secondary" variant="body2">New
                                                    balance</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 10}}
                                                            color="text.secondary" variant="body2">Current
                                                    size</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}}
                                                            color="text.secondary" variant="body2">New size</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}}
                                                            color="text.secondary"
                                                            variant="body2">Investment</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}}
                                                            color="text.secondary" variant="body2">DCA
                                                    price</Typography>
                                                <Typography style={{paddingBottom: 10, paddingTop: 7}}
                                                            color="text.secondary" variant="body2">New
                                                    price</Typography>
                                            </Grid>
                                            <Grid item style={{paddingBottom: 5}} md={6}>
                                                <Typography style={{paddingBottom: 7, paddingTop: 10}} align="right"
                                                            color="text.secondary"
                                                            variant="body2">{parseFloat(availableFunds).toFixed(2)} {quote_asset}</Typography>
                                                <Typography style={{paddingBottom: 10, paddingTop: 7}} align="right"
                                                            color="warning.main"
                                                            variant="body2">≈ {(parseFloat(availableFunds) - (formik.values.price * formik.values.quantity)).toFixed(2)} {quote_asset}</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 10}} align="right"
                                                            color="text.secondary"
                                                            variant="body2">{current_position_size.toFixed(lotSizeFilter.hasOwnProperty("stepSize") ? lotSizeFilter.stepSize.replace(/0+$/, '').split('.')[1].length : 0) + " " + base_asset} </Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}} align="right"
                                                            color="warning.main"
                                                            variant="body2">{isNaN(current_position_size + parseFloat(formik.values.quantity)) ? 0.00 : (current_position_size + parseFloat(formik.values.quantity))} {base_asset}</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}} align="right"
                                                            color="warning.main"
                                                            variant="body2">≈ {(current_position_size + formik.values.price * formik.values.quantity).toFixed(2)} {quote_asset}</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}} align="right"
                                                            color="text.secondary"
                                                            variant="body2">{breakEvenPrice.toFixed(pairPriceFilter.hasOwnProperty("tickSize") ? pairPriceFilter.tickSize.replace(/0+$/, '').split('.')[1].length : 0)} {quote_asset}</Typography>
                                                <Typography style={{paddingBottom: 10, paddingTop: 7}} align="right"
                                                            color="warning.main"
                                                            variant="body2">≈ {isNaN(newBreakEvenPrice) ? 0.00 : newBreakEvenPrice.toFixed(pairPriceFilter.hasOwnProperty("tickSize") ? pairPriceFilter.tickSize.replace(/0+$/, '').split('.')[1].length : 0)} {quote_asset}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.button}>
                                            <StyledButton
                                                variant="contained"
                                                label="Add order"
                                                type="submit"
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper> :
                <Grid container flexDirection="row">
                    <Grid item md={2}>
                        <Paper className={classes.paper}>
                            <Grid container flexDirection="column">
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid item>
                                        <Typography id="modal-manual-averaging" variant="h6" color="primary"
                                                    style={{padding: 20}}>
                                            Manual averaging
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{padding: 5}}>
                                        <StyledTextField id="price"
                                                         variant="outlined"
                                                         name="price"
                                                         type="text"
                                                         placeholder="ex: 15000.00"
                                                         label="Price"
                                                         value={formik.values.price}
                                                         onChange={formik.handleChange}
                                                         error={formik.touched.price && Boolean(formik.errors.price)}
                                                         helperText={formik.touched.price && formik.errors.price}
                                                         style={{height: 80, width: "100%"}}/>
                                    </Grid>
                                    <Grid item style={{padding: 5}}>
                                        <StyledTextField id="quantity"
                                                         variant="outlined"
                                                         name="quantity"
                                                         type="text"
                                                         placeholder="ex: 0.35"
                                                         label="Quantity"
                                                         value={formik.values.quantity}
                                                         onChange={formik.handleChange}
                                                         error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                                                         helperText={formik.touched.quantity && formik.errors.quantity}
                                                         style={{height: 80, width: "100%"}}/>
                                    </Grid>
                                    <Grid item style={{padding: 5}}>
                                        <Grid container flexDirection="row" className={classes.kpi}>
                                            <Grid item style={{paddingBottom: 5}} md={6}>
                                                <Typography style={{paddingBottom: 7, paddingTop: 10}}
                                                            color="text.secondary" variant="body2">Available
                                                    balance</Typography>
                                                <Typography style={{paddingBottom: 10, paddingTop: 7}}
                                                            color="text.secondary" variant="body2">New
                                                    balance</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 10}}
                                                            color="text.secondary" variant="body2">Current
                                                    size</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}}
                                                            color="text.secondary" variant="body2">New size</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}}
                                                            color="text.secondary"
                                                            variant="body2">Investment</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}}
                                                            color="text.secondary" variant="body2">DCA
                                                    price</Typography>
                                                <Typography style={{paddingBottom: 10, paddingTop: 7}}
                                                            color="text.secondary" variant="body2">New
                                                    price</Typography>
                                            </Grid>
                                            <Grid item style={{paddingBottom: 5}} md={6}>
                                                <Typography style={{paddingBottom: 7, paddingTop: 10}} align="right"
                                                            color="text.secondary"
                                                            variant="body2">{parseFloat(availableFunds).toFixed(2)} {quote_asset}</Typography>
                                                <Typography style={{paddingBottom: 10, paddingTop: 7}} align="right"
                                                            color="warning.main"
                                                            variant="body2">≈ {(parseFloat(availableFunds) - (formik.values.price * formik.values.quantity)).toFixed(2)} {quote_asset}</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 10}} align="right"
                                                            color="text.secondary"
                                                            variant="body2">{current_position_size.toFixed(lotSizeFilter.hasOwnProperty("stepSize") ? lotSizeFilter.stepSize.replace(/0+$/, '').split('.')[1].length : 0) + " " + base_asset} </Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}} align="right"
                                                            color="warning.main"
                                                            variant="body2">{isNaN(current_position_size + parseFloat(formik.values.quantity)) ? 0.00 : (current_position_size + parseFloat(formik.values.quantity))} {base_asset}</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}} align="right"
                                                            color="warning.main"
                                                            variant="body2">≈ {(current_position_size + formik.values.price * formik.values.quantity).toFixed(2)} {quote_asset}</Typography>
                                                <Typography style={{paddingBottom: 7, paddingTop: 7}} align="right"
                                                            color="text.secondary"
                                                            variant="body2">{breakEvenPrice.toFixed(pairPriceFilter.hasOwnProperty("tickSize") ? pairPriceFilter.tickSize.replace(/0+$/, '').split('.')[1].length : 0)} {quote_asset}</Typography>
                                                <Typography style={{paddingBottom: 10, paddingTop: 7}} align="right"
                                                            color="warning.main"
                                                            variant="body2">≈ {isNaN(newBreakEvenPrice) ? 0.00 : newBreakEvenPrice.toFixed(pairPriceFilter.hasOwnProperty("tickSize") ? pairPriceFilter.tickSize.replace(/0+$/, '').split('.')[1].length : 0)} {quote_asset}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.button}>
                                            <StyledButton
                                                variant="contained"
                                                label="Add order"
                                                type="submit"
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item md={10}>
                        <DcaChart bot_id={bot_id} pair={pair} base_asset={base_asset}
                                  new_break_even_price={newBreakEvenPrice}/>
                    </Grid>
                </Grid>
            }
        </Box>
    )
        ;
}

export default ManualAveragingModal;