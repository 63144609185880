import * as React from 'react';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from "react-redux";
import useStyles from "./dcaBotListActive.styles";
import {green, grey, orange, red} from "@mui/material/colors";
import {Accordion, AccordionDetails, AccordionSummary, CardContent, Container, Tooltip} from "@mui/material";
import {DcaControlPanel} from "../dcaControlPanel/dcaControlPanel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useEffect, useMemo} from "react";
import {binanceGetTickerLaunch} from "../../features/binance/binanceSlice";
import Grid from "@mui/material/Grid";
import {daysDiff} from "../../utils/utils";


const DCABotListActive = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dcaList = (useSelector((state) => state.django.dcaListData)).filter(pilot => pilot.bot_status !== "DEACTIVATED");
    const dcaListActive = (useSelector((state) => state.django.dcaListData)).filter(pilot => pilot.bot_status === "ACTIVE");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const tickerData = useSelector((state) => state.binance.tickerData);

    useEffect(() => {
        dispatch(binanceGetTickerLaunch())
    }, [])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const getTrendingColor = (priceChangePercent) => {
        if (priceChangePercent > 0)
            return green[400]
        else if (priceChangePercent < 0)
            return red[400]
        return ""
    }

    const getStatusColor = (status, pid_status) => {
        if (pid_status === "NOT RUNNING")
            return red[500]
        else if (status === "ACTIVE")
            return green[400]
        else if (status === "RANGE")
            return orange[500]
        else if (status === "DEACTIVATED")
            return grey[500]
        else
            return ""
    }


    const displayPairs = (pairs) => {
        const visiblePairs = pairs.slice(0, 5);
        const hiddenPairs = pairs.slice(5);
        return (
            <>
                {visiblePairs}
                {hiddenPairs.length > 0 && (
                    <Tooltip title={<Box>{hiddenPairs}</Box>} arrow>
                        <Typography className={classes.heading} component="span">
                            +{hiddenPairs.length} more
                        </Typography>
                    </Tooltip>
                )}
            </>
        );
    };

    function calculateCurrentValues(dcaListActive, tickerData) {
        return dcaListActive.map((row) => {
            if (!row || !row.dca_grid) {
                return {
                    id: null,
                    value: 0,
                    originalValue: 0,
                };
            }

            const filledOrders = row.dca_grid.filter(
                (order) => order.order_status === "FILLED"
            );

            let currentValue = 0;
            let originalValue = 0;

            filledOrders.forEach((order) => {
                const currentPrice = tickerData.find(
                    (ticker) => ticker.symbol === order.pair
                )?.price;

                if (currentPrice) {
                    currentValue +=
                        order.order_original_qty * parseFloat(currentPrice);
                    originalValue += parseFloat(order.order_quote_qty);
                }
            });

            return {
                id: row.id,
                value: currentValue.toFixed(2),
                originalValue: originalValue.toFixed(2),
            };
        });
    }

    const currentValues = useMemo(
        () => calculateCurrentValues(dcaList, tickerData),
        [dcaList, tickerData]
    );

    return (
        <div>
            <Container maxWidth="false">
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-start">
                            <Typography color="primary" sx={{textAlign: 'left', marginRight: '16px'}}>Pair ({dcaListActive.length}/{dcaList.length})</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-end" direction="row">
                            <Grid item>
                                <Typography color="primary" variant="body2"
                                            sx={{textAlign: 'right', marginRight: '20px'}}>
                                    PNL
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography color="primary" variant="body2"
                                            sx={{textAlign: 'right', marginRight: '44px'}}>Profit</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {dcaList
                .sort((a, b) => b.id - a.id)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                    const pairs = row.pairs_m2m.map((pair, index) => (
                            <img
                                key={index}
                                src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${pair.pair.slice(
                                    0,
                                    pair.pair.length - row.profit_currency.length
                                ).toLowerCase()}.svg`}
                                className={classes.icon}
                                alt="token_logo"
                            />
                    ));
                    return (
                    <Accordion key={row.id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={row.id}>
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Typography sx={{width: '100%', flexShrink: 0}} color={getStatusColor(row.bot_status, row.pid_status)}>{row.id} - {row.name}</Typography>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Typography variant="body2" sx={{textAlign: 'right', marginRight: '16px'}}
                                                color={getTrendingColor(parseFloat(row.pnl) / row.investment_amount * 100)}>{row.pnl !== null ? (parseFloat(row.pnl) / row.investment_amount * 100).toFixed(2) : parseFloat("0.00").toFixed(2)}%</Typography>
                                    <Typography
                                        variant="body2">{(row.pnl !== null ? parseFloat(row.pnl).toFixed(2) : parseFloat("0.00").toFixed(2))}$</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" sx={{textAlign: 'right', marginRight: '16px'}}
                                                color={row.pnl !== 0 ? getTrendingColor(parseFloat(((parseFloat((currentValues.find((cv) => cv.id === row.id)?.value) || 0.00)+parseFloat(row.pnl)) / (parseFloat((currentValues.find((cv) => cv.id === row.id)?.originalValue) || 0.00)+parseFloat(row.pnl)) - 1) * 100)) : ""}>{(row.pnl !== null && row.pnl !== 0 ? parseFloat(((parseFloat((currentValues.find((cv) => cv.id === row.id)?.value) || 0.00)+parseFloat(row.pnl)) / (parseFloat((currentValues.find((cv) => cv.id === row.id)?.originalValue) || 0.00)+parseFloat(row.pnl)) - 1) * 100).toFixed(2) : parseFloat("0.00").toFixed(2))}%</Typography>
                                    <Typography
                                        variant="body2">{(row.pnl !== null ? (parseFloat((currentValues.find((cv) => cv.id === row.id)?.value) || 0.00)+parseFloat(row.pnl)).toFixed(2) : parseFloat("0.00").toFixed(2))}$</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CardContent className={classes.kpi}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">ID:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'inline' }} >
                                            {row.id}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Status:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color={getStatusColor(row.bot_status)}>{row.bot_status}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Nb deals:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'inline' }} >
                                            {(row.cycles !== null ? parseFloat(row.cycles) : 0)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Avg profit:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" color={getTrendingColor(parseFloat(row.pnl) / row.investment_amount * 100)} sx={{ display: 'inline' }} >
                                            {(((row.pnl / ((new Date().getTime() - new Date(row.creation_date).getTime()) / (1000 * 3600 * 24))) / row.investment_amount * 100).toFixed(2))}%
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Start:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'inline' }}>
                                            {row.creation_date !== null ? (new Date(row.creation_date)).toLocaleString('en-GB',{ day: '2-digit', month: '2-digit', year: 'numeric' }) : ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Init:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'inline' }} >
                                            {(row.investment_amount !== null ? parseFloat(row.investment_amount).toFixed(2) : parseFloat("0.00").toFixed(2))}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Start:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'inline' }}>
                                            {row.start_signal !== null ? row.start_signal + " (" +row.start_signal_period + ")": ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Base:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'inline' }}>
                                            {row.base_order !== null ? row.base_order: 0} $
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Av.a:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'inline' }}>
                                            {row.averaging_order_amount !== null ? row.averaging_order_amount + "x" + row.averaging_order_quantity + "x" +row.amount_multiplier: ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Av.s:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'inline' }}>
                                            {row.averaging_order_step !== null ? row.averaging_order_step + "% (x" +row.step_multiplier+")":0}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Take profit:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'inline' }}>
                                            {row.take_profit_step !== null ? row.take_profit_step + " %" : ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ display: 'inline' }}>
                                            {row.pnl !== null ?daysDiff(row.start_date, row.stop_date):""}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Pairs:&nbsp;
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'inline' }}>{displayPairs(pairs)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="flex-end" justifyContent="center">
                                    <Grid item sx={{ mt: 2 }}>
                                        <DcaControlPanel bot_status={row.bot_status} bot_id={row.id}
                                                         pairs_m2m={row.pairs_m2m}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </AccordionDetails>
                    </Accordion>
                    )
            })}
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={dcaList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default DCABotListActive;