import React from 'react';
import {TextField} from "@mui/material";

export const StyledTextField = ({...props}) => {
    return (
        <TextField
            {...props}
        />
    );
}
