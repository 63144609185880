import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {FormHelperText} from "@mui/material";

export const StyledSelect = ({variant,value, label, labelId, id, listItems, handleChange, error, helperText}) => {

    return (
        <Box sx={{minWidth: 120}}>
            <FormControl fullWidth variant={variant} error={error}>
                <InputLabel id={id}>{label}</InputLabel>
                <Select
                    labelId={labelId}
                    id={id}
                    value={value}
                    label={label}
                    onChange={(item) => {
                        handleChange(item.target.value)
                    }}
                >
                    {listItems.map(({text, value}) => (
                        <MenuItem key={value} value={value}> {text}</MenuItem>
                    ))}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </Box>
    );
}
