import React, {useEffect} from 'react';
import AccountLineChart from "../accountLineChart/accountLineChart";
import {useDispatch, useSelector} from "react-redux";
import {Paper} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AccountPieChart from "../accountPieChart/accountPieChart";
import useStyles from "./aggregatedAccountBalance.styles";
import {getHistoryLaunch} from "../../features/django/djangoSlice";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{paddingTop: 3, paddingBottom: 3}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AggregatedAccountBalanceMobile = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const accountInformation = useSelector((state) => state.django.accountInformation);
    const accountBalances = useSelector((state) => state.django.accountInformation && state.django.accountInformation.aggregate ? state.django.accountInformation.aggregate.accounts : []);
    const historyData = (useSelector((state) => state.django.historyData));
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const formattedDate = tomorrow.toISOString().split('T')[0];
        dispatch(getHistoryLaunch({
            date: formattedDate
        }));
    }, [])

    return (
        <Paper style={{padding: 10}}>
            <Typography variant="h6" id="chartTitle" sx={{pl: {sm: 2}, pr: {xs: 1, sm: 1}}}
                        style={{paddingBottom: 20, paddingTop: 10}} color="primary">
                Aggregated account balance
            </Typography>
            <Grid container flexDirection="line">
                <Grid item xs={12}>
                    <Grid container style={{height: "100%"}} flexDirection="line"
                          justifyContent="space-around">
                        <Grid item className={classes.kpi} xs={3.8}>
                            <Typography variant="subtitle2" color="primary">Total value</Typography>
                            <Typography>{accountInformation && accountInformation.aggregate ? parseFloat(accountInformation.aggregate.accountTotalUSDT).toFixed(0) + ' $' : '$ 00.00'}</Typography>                        </Grid>
                        <Grid item className={classes.kpi} xs={3.8}>
                            <Typography variant="subtitle2" color="primary">Available $</Typography>
                            <Typography>{accountInformation && accountInformation.aggregate ? (parseFloat(accountInformation.aggregate.accountTotalAvailableUSDT) / parseFloat(accountInformation.aggregate.accountTotalUSDT) * 100).toFixed(0) + ' %': '00.00'}</Typography>
                        </Grid>
                        <Grid item className={classes.kpi} xs={3.8}>
                            <Typography variant="subtitle2" color="primary">Bot profit (24h)</Typography>
                            {historyData && historyData[6] && typeof historyData[6].value !== "undefined" ?
                                <div>
                                    <Typography
                                        sx={{display: 'inline'}}>{historyData[6].value.toFixed(2)}&nbsp;$&nbsp;</Typography>
                                </div>
                                :
                                <Typography>0.00 $</Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{width: '100%'}}>
                    <Paper sx={{width: '100%'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Profit" {...a11yProps(0)} />
                                <Tab label="Portfolio" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Grid container>
                                <Grid item xs={12}>
                                    {typeof (historyData) !== "undefined" ?
                                        <AccountLineChart chartData={historyData}/>
                                        :
                                        ""}
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Grid container>
                                <Grid item xs={12}>
                                    {typeof (accountBalances) !== "undefined" ?
                                        <AccountPieChart chartData={accountBalances}/>
                                        :
                                        ""
                                    }
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </Paper>
                </Box>
            </Grid>
        </Paper>
    );
}
