import * as React from 'react';
import PropTypes from 'prop-types'
import moment from 'moment'

import {
    Bar,
    BarChart,
    ResponsiveContainer, Tooltip,
    XAxis,
    YAxis,
} from 'recharts'

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`PNL : ${payload[0].value.toFixed(3)}`}</p>
            </div>
        );
    }
}
const BotBarChart = ({chartData}) => {
    const thirtyDaysAgo = moment().subtract(30, 'days').valueOf();

    return (
        <ResponsiveContainer width='95%' height={200}>
            <BarChart data={chartData} syncId="" margin={{bottom: 30}}>
                <XAxis
                    dataKey='time'
                    domain={[thirtyDaysAgo, 'dataMax + 86400000']}
                    name='Time'
                    tickFormatter={timeStr => moment(timeStr).format('DD-MM')}
                    type='number'
                    scale='time'
                    allowDataOverflow={true}
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                />
                <YAxis dataKey='value' name='Value'/>
                <Tooltip content={<CustomTooltip/>}/>
                <Bar
                    dataKey='value'
                    fill="#5893df"
                    // barSize={30}
                >
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}

BotBarChart.propTypes = {
    chartData: PropTypes.arrayOf(
        PropTypes.shape({
            time: PropTypes.number,
            value: PropTypes.number
        })
    ).isRequired
}

export default BotBarChart