import {put, takeEvery} from 'redux-saga/effects'
import axios from "axios";
import {
    cryptoComGetKlinesFailure,
    cryptoComGetKlinesSuccess,
    cryptoComGetExchangeInfoFailure,
    cryptoComGetExchangeInfoSuccess,
    cryptoComGetTickerFailure,
    cryptoComGetTickerSuccess,
    cryptoComGetExchangeInfoPairFailure,
    cryptoComGetExchangeInfoPairSuccess
} from "./cryptoComSlice";
import {failure} from "../../app/appSlice";
import privateApiClient from "../../services/privateApiClient";


function* getTickerCall() {
    try {
        const dcaList = yield privateApiClient.get(`/dca-config/?include_grid=true&format=json`,);
        const dcaListActive = dcaList.data.filter(pilot => pilot.bot_status === "ACTIVE");
        const uniqueActivePairs = dcaListActive.reduce((acc, item) => acc.concat(item.active_pairs), []);
        const uniqueActivePairsStr = '["' + uniqueActivePairs.join('","') + '"]';
        if (uniqueActivePairsStr !=='[""]') {
            const response = yield axios.get(`https://api.cryptoCom.com/api/v3/ticker/price?symbols=${uniqueActivePairsStr}`);
            if (response.status !== 200) {
                yield put(failure({message: "Get ticker fail"}));
                yield put(cryptoComGetTickerFailure({error: response.toString()}));
            }
            yield put(cryptoComGetTickerSuccess({data: response.data}));
        }
    } catch (error) {
        yield put(failure({message: "Get ticker fail"}));
        yield put(cryptoComGetTickerFailure({error: error.toString()}));
    }
}

function* getKlinesCall(action) {
    try {
        const {symbol, interval} = action.payload;
        let formated_interval
        switch (interval) {
            case '1d':
                formated_interval = '1D';
                break;
            case '1w':
                formated_interval = '7D';
                break;
            default:
                formated_interval = interval;
                break;
        }

        const response = yield axios.get(`https://api.crypto.com/exchange/v1/public/get-candlestick?instrument_name=${symbol}&timeframe=${formated_interval}&count=300`);

        if (response.status !== 200) {
            yield put(failure({message: "Get klines fail"}));
            yield put(cryptoComGetKlinesFailure({error: response.toString()}));
        }
        let klines = []

        for (let i in response.data.result.data) {
            klines.push({
                "open": response.data.result.data[i]["o"],
                "high": response.data.result.data[i]["h"],
                "low": response.data.result.data[i]["l"],
                "close": response.data.result.data[i]["c"],
                "time": response.data.result.data[i]["t"] / 1000
            })
        }

        yield put(cryptoComGetKlinesSuccess({data: klines}));
    } catch (error) {
        yield put(failure({message: "Get klines fail"}));
        yield put(cryptoComGetKlinesFailure({error: error.toString()}));
    }
}

function* getExchangeInfoCall() {
    try {
        const compatibleQuoteAsset = ["BTC", "USDT"];
        const response = yield axios.get('https://api.crypto.com/v2/public/get-instruments');

        let listAssets = [];

        if (response.status !== 200) {
            yield put(failure({ message: "Get instruments fail" }));
            yield put(cryptoComGetExchangeInfoFailure({ error: response.toString() }));
        }

        for (let i = 0; i < response.data.result.instruments.length; i++) {
            const instrument = response.data.result.instruments[i];
            const quoteAsset = instrument.instrument_name.split('_')[1];
            const baseAsset = instrument.instrument_name.split('_')[0];
            const symbol = baseAsset+quoteAsset;
            const tickSize = instrument.price_tick_size;
            const minPrice = instrument.min_price;
            const maxPrice = instrument.max_price;
            const minNotional = 10.00;

            if (compatibleQuoteAsset.includes(quoteAsset)) {
                const { instrument_name, quote_currency, base_currency, price_tick_size, min_price, max_price, ...rest } = instrument;
                const status = "TRADING"
                const exchange = "CRO"
                listAssets.push({ symbol, exchange, status, quoteAsset, baseAsset, tickSize, minPrice, maxPrice, minNotional, ...rest });
            }
        }

        yield put(cryptoComGetExchangeInfoSuccess({ data: listAssets }));
    } catch (error) {
        yield put(failure({ message: "Get instruments fail" }));
        yield put(cryptoComGetExchangeInfoFailure({ error: error.toString() }));
    }
}



function* getExchangeInfoPairCall(action) {
    try {
        const {pair} = action.payload;
        const response = yield axios.get(`https://api.cryptoCom.com/api/v3/exchangeInfo?symbol=${pair}`);

        if (response.status !== 200) {
            yield put(failure({message: "Get exchangeInfo pair fail"}));
            yield put(cryptoComGetExchangeInfoPairFailure({error: response.toString()}));
        }

        yield put(cryptoComGetExchangeInfoPairSuccess({data: response.data.symbols[0]}));
    } catch (error) {
        yield put(failure({message: "Get exchangeInfo pair fail"}));
        yield put(cryptoComGetExchangeInfoPairFailure({error: error.toString()}));
    }
}

function* cryptoComSaga() {
    yield takeEvery("cryptoCom/cryptoComGetTickerLaunch", getTickerCall);
    yield takeEvery("cryptoCom/cryptoComGetKlinesLaunch", getKlinesCall);
    yield takeEvery("cryptoCom/cryptoComGetExchangeInfoLaunch", getExchangeInfoCall);
    yield takeEvery("cryptoCom/cryptoComGetExchangeInfoPairLaunch", getExchangeInfoPairCall);
}

export default cryptoComSaga;