import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useStyles from "./stopBotModal.styles";
import {StyledButton} from "../button/styledButton";
import Grid from "@mui/material/Grid";
import {useDispatch} from "react-redux";
import {
    getBotListLaunch,
    getDCAListLaunch,
    postStopBotLaunch,
    postStopDCALaunch
} from "../../features/django/djangoSlice";


const StopBotModal = ({bot_id, bot_type, handleModalClose}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Box className={classes.modal}>
            <Grid container flexDirection="column">
                <Grid item>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{padding: 20}}>
                        Do you want to stop the bot and sell all yout coins?
                    </Typography>
                </Grid>
                <Grid container flexDirection="row" justifyContent="space-around" >
                    <Grid item className={classes.button}>
                        {bot_type === "dca"?
                            <StyledButton
                                      variant="contained"
                                      label="Stop the bot"
                                      fullWidth={true}
                                      action={() => {
                                          dispatch(postStopDCALaunch({
                                              bot_id: bot_id
                                          }))
                                          handleModalClose()
                                          setTimeout(() => {
                                              dispatch(getDCAListLaunch())
                                          }, 10000)
                                      }}/>:
                            <StyledButton
                                variant="contained"
                                label="Stop the bot"
                                fullWidth={true}
                                action={() => {
                                    dispatch(postStopBotLaunch({
                                        bot_id: bot_id
                                    }))
                                    handleModalClose()
                                    setTimeout(() => {
                                        dispatch(getBotListLaunch())
                                    }, 10000)
                                }}/>}
                    </Grid>
                    <Grid item className={classes.button}>
                        <StyledButton
                                      variant="contained"
                                      label="Cancel"
                                      fullWidth={true}
                                      color="error"
                                      action={() => {
                                          handleModalClose()
                                      }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
        ;
}

export default StopBotModal;