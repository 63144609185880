import * as React from 'react';
import PropTypes from 'prop-types'

import {
    Line,
    LineChart,
    ResponsiveContainer, Tooltip,
    XAxis,
    YAxis,
} from 'recharts'


const DCABotConfigurationChart = ({chartData}) => {
    const minYValue = Math.min(...chartData.map(d => d.value))
    const yDomain = [minYValue, 'auto']
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const value = Number(payload[0].value).toFixed(2);
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${value} $`}</p>
                </div>
            );
        }
        return null;
    };
    return (
        <ResponsiveContainer width='95%' height={205}>
            <LineChart data={chartData} syncId="">
                <XAxis
                    dataKey='averaging_order_number'
                    domain={['dataMin', 'dataMax']}
                    name='Averaging order number'
                    type='number'
                    allowDataOverflow={true}
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                    tick={{fontSize: 12}}
                    tickCount={Math.max(...chartData.map(d => d.averaging_order_number)) + 1}
                />

                <YAxis
                    tickFormatter={(value) => value.toFixed(0)}
                    domain={yDomain}
                    allowDecimals={true}
                />
                <Tooltip content={<CustomTooltip/>}/>
                <Line
                    dataKey='value'
                    stroke="#2ec5d3"
                    type="monotone"
                >
                </Line>
            </LineChart>
        </ResponsiveContainer>
    )
}

DCABotConfigurationChart.propTypes = {
    chartData: PropTypes.arrayOf(
        PropTypes.shape({
            time: PropTypes.number,
            value: PropTypes.number
        })
    ).isRequired
}

export default DCABotConfigurationChart
