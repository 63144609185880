import React, {useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardContent,
    CircularProgress,
    Container, Paper,
    Typography
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getBotHistoryLaunch} from "../../features/django/djangoSlice";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {green, red} from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import useStyles from "./statisticsModal.styles";

import Box from "@mui/material/Box";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BotBarChart from "../botBarChart/botBarChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";


const StatisticsModalMobile = ({bot_id}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const botHistoryData = (useSelector((state) => state.django.botHistoryData));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    useEffect(() => {
        dispatch(getBotHistoryLaunch({
            botId: bot_id
        }))
    }, [bot_id, dispatch])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value));
        setPage(0);
    };


    const getSideColor = (side) => {
        if (side === "SELL")
            return red[400]
        else
            return green[400]
    }

    return (
        <div>
            {botHistoryData && botHistoryData.filledData ?
                <Box className={classes.modal} sx={{width: '90%'}}>
                    <Paper sx={{width: '100%'}}>
                        <div className={classes.root}>
                            <AttachMoneyIcon color="primary" style={{marginLeft: 10, marginRight: 5, marginTop: 10}}
                                             fontSize="5px"/>
                            <Typography
                                sx={{display: 'inline', marginTop: 10}}
                                variant="h6"
                                id="tableTitle"
                                color="primary"
                            >
                                Daily profit
                            </Typography>
                            <BotBarChart chartData={botHistoryData.chartData}/>
                            <ReceiptIcon color="primary" style={{marginLeft: 10, marginRight: 10, marginTop: 10}}
                                         fontSize="4px"/>
                            <Typography
                                sx={{display: 'inline', marginTop: 10}}
                                variant="h6"
                                id="tableTitle"
                                color="primary"
                            >
                                Transactions
                            </Typography>
                            <Container maxWidth="false">
                                <Grid container justifyContent="flex-start" alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color="primary" sx={{textAlign: 'left'}}>
                                            Order ID
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body2" color="primary"
                                                    sx={{textAlign: 'left', marginLeft: '11px'}}>
                                            Side
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body2" color="primary"
                                                    sx={{textAlign: 'right', marginRight: '40px'}}>
                                            Profit
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                            {botHistoryData && botHistoryData.filledData && botHistoryData.filledData.length > 0 && botHistoryData.filledData !== [] ?
                                botHistoryData.filledData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <Accordion key={row.id}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={row.order_id}>
                                                <Grid container justifyContent="flex-start" alignItems="center">
                                                    <Grid item xs={7}>
                                                        <Typography>{row.order_id}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography variant="body2" color={getSideColor(row.order_side)}
                                                                    sx={{textAlign: 'left'}}>
                                                            {row.order_side}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography variant="body2"
                                                                    sx={{textAlign: 'right', marginRight: '16px'}}>
                                                            {row.order_pnl}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <CardContent className={classes.kpi}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{display: 'inline'}}
                                                                        color="textSecondary">Quantity:&nbsp;
                                                            </Typography>
                                                            <Typography variant="body1" color="textSecondary"
                                                                        sx={{display: 'inline'}}>
                                                                {row.order_side === "SELL" ? row.sell_order_executed_qty : row.buy_order_executed_qty}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{display: 'inline'}}
                                                                        color="textSecondary">Price:&nbsp;
                                                            </Typography>
                                                            <Typography variant="body1" color="textSecondary"
                                                                        sx={{display: 'inline'}}>
                                                                {row.order_price}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{display: 'inline'}}
                                                                        color="textSecondary">Buy quote:&nbsp;
                                                            </Typography>
                                                            <Typography variant="body1"
                                                                        sx={{display: 'inline'}}>
                                                                {row.buy_order_quote_qty !== null ? row.buy_order_quote_qty.toFixed(3) : ""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{display: 'inline'}}
                                                                        color="textSecondary">Sell quote:&nbsp;
                                                            </Typography>
                                                            <Typography variant="body1" color="textSecondary"
                                                                        sx={{display: 'inline'}}>
                                                                {row.sell_order_quote_qty !== null ? row.sell_order_quote_qty.toFixed(3) : ""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{display: 'inline'}}
                                                                        color="textSecondary">Time:&nbsp;
                                                            </Typography>
                                                            <Typography variant="body1" sx={{display: 'inline'}}>
                                                                {row.order_created_time !== null ? (new Date(row.order_created_time)).toLocaleString() : ""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{display: 'inline'}}
                                                                        color="textSecondary">Buy order ID:&nbsp;
                                                            </Typography>
                                                            <Typography variant="body1" sx={{display: 'inline'}}>
                                                                {row.order_side === "SELL" ? row.buy_order_id : ""}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </AccordionDetails>
                                        </Accordion>
                                    )) :
                                <Typography variant="body1" color="textSecondary"></Typography>}
                            <TablePagination
                                component="div"
                                count={botHistoryData.filledData.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[5, 10]}
                                labelDisplayedRows={({from, to, count}) => `${from}-${to} / ${count}`}
                            />
                        </div>
                    </Paper>
                </Box> :
                <CircularProgress disableShrink/>}
        </div>
    );
};

export default StatisticsModalMobile;