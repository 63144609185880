import React from 'react';
import {ThemeProvider} from "@mui/material";
import {CssBaseline} from "@mui/material";
import {theme} from "./utils/materialUiTheme";
import {StyledHeader} from "./components/header/styledHeader";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/home/home";
import GridBot from "./pages/gridBot/gridBot";
import LoginForm from "./pages/login/login";
import Test from "./pages/test/test";
import Error from "./pages/error/error";
import {CustomDrawer} from "./components/drawer/drawer";
import {PrivateRoute} from "./components/privateRoute/privateRoute";
import {isExpired} from "react-jwt";
import DcaBot from "./pages/dcaBot/dcaBot";

const pages = [
    {title: "Home", path: "/"},
    {title: "Grid Bots", path: "/grid_bot"},
    // {title: "My binance", path: "/binance"},
];

const App = () => {
    const [open, setOpen] = React.useState(false);
    const accessToken = localStorage.getItem('accessToken');
    const isMyTokenExpired = isExpired(accessToken);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <StyledHeader pages={pages} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
            <CustomDrawer open={open} handleDrawerClose={handleDrawerClose} body={() =>  (
                    <Routes>
                        {/*Private routes*/}
                        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>}/>
                        <Route path="/grid_bot" element={<PrivateRoute><GridBot /></PrivateRoute>}/>
                        <Route path="/dca_bot" element={<PrivateRoute><DcaBot /></PrivateRoute>}/>
                        {/*<Route path="/binance" element={<PrivateRoute><MyExchanges /></PrivateRoute>}/>*/}
                        {process.env.REACT_APP_ENVIRONMENT !== "prod" &&
                            <Route path="/test" element={<PrivateRoute><Test /></PrivateRoute>}/>
                        }
                        {/*Public routes*/}
                        <Route path="/login" element={isMyTokenExpired ? <LoginForm/>:<Navigate to="/" />}/>
                        <Route path="*" element={<Error/>}/>
                    </Routes>
            )}/>
        </ThemeProvider>
    );
}

export default App;
