import * as React from 'react';
import {Grid, Paper} from "@mui/material";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import useStyles from "./summaryBalance.styles";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {getAccountInformationLaunch} from "../../features/django/djangoSlice";

const SummaryBalance = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const accountInformationData = useSelector((state) => state.django.accountInformation);
    const accessToken = useSelector((state) => state.django.accessToken);
    // const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        if (accessToken !== undefined)
            dispatch(getAccountInformationLaunch())
    }, [dispatch, accessToken])

    return (
        <div>
            <Paper elevation={20} className={classes.root} sx={{display: {xs: "none", md: "block"}}}>
                <Grid key="summary_balance" display="flex" alignItems="center">
                    <Typography
                        variant={"heading5"}>{<AccountBalanceWalletIcon fontSize="4px"/>} Real summary balance
                    </Typography>
                </Grid>
                <Grid key="total_usdt" display="flex" alignItems="center">
                    <Typography variant={"heading5"}>
                        {<AttachMoneyIcon fontSize="4px"/>}{
                        accountInformationData && accountInformationData.aggregate && parseFloat(accountInformationData.aggregate.accountTotalUSDT).toFixed(2)
                    }
                    </Typography>
                </Grid>
            </Paper>
            <Grid key="total_usdt" display="flex" alignItems="center" sx={{display: {xs: "block", md: "none"}}}>
                <Typography variant={"subtitle2"}>
                    {<AccountBalanceWalletIcon className={classes.icon} fontSize="4px"/>}&nbsp;{
                    accountInformationData && accountInformationData.aggregate && parseFloat(accountInformationData.aggregate.accountTotalUSDT).toFixed(2)
                } $
                </Typography>
            </Grid>
        </div>
    )
        ;
}

export default SummaryBalance;