import * as React from 'react';
import PropTypes from 'prop-types'

import {
    Bar,
    BarChart,
    ResponsiveContainer, Tooltip,
    XAxis,
    YAxis,
} from 'recharts'

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`PNL (${payload[0].payload.pair}) : ${payload[0].value.toFixed(3)}`}</p>
            </div>
        );
    }
};

const BotPairBarChart = ({ chartData }) => {
    return (
        <ResponsiveContainer width="95%" height={200}>
            <BarChart data={chartData} syncId="" margin={{ bottom: 30 }}>
                <XAxis dataKey="pair" />
                <YAxis dataKey="pnl" />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="pnl" fill="#5893df"></Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

BotPairBarChart.propTypes = {
    chartData: PropTypes.arrayOf(
        PropTypes.shape({
            time: PropTypes.number,
            value: PropTypes.number
        })
    ).isRequired
}

export default BotPairBarChart