import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useStyles from "./stopPairModal.styles";
import {StyledButton} from "../button/styledButton";
import Grid from "@mui/material/Grid";
import {useDispatch} from "react-redux";
import {getDCAListLaunch, postStopDCAPairLaunch} from "../../features/django/djangoSlice";


const StopPairModal = ({bot_id, pair, handleModalClose}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Box className={classes.modal}>
            <Grid container flexDirection="column">
                <Grid item>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{padding: 20}}>
                        {`Do you want to stop the current ${pair} deal`}
                    </Typography>
                </Grid>
                <Grid container flexDirection="row" justifyContent="space-around" >
                    <Grid item className={classes.button}>
                        <StyledButton
                                  variant="contained"
                                  label="Stop"
                                  fullWidth={true}
                                  action={() => {
                                      dispatch(postStopDCAPairLaunch({
                                          bot_id: bot_id,
                                          pair: pair
                                      }))
                                      handleModalClose()
                                      setTimeout(() => {
                                          dispatch(getDCAListLaunch())
                                      }, 10000)
                                  }}/>
                    </Grid>
                    <Grid item className={classes.button}>
                        <StyledButton
                                      variant="contained"
                                      label="Cancel"
                                      fullWidth={true}
                                      color="error"
                                      action={() => {
                                          handleModalClose()
                                      }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
        ;
}

export default StopPairModal;