import * as React from 'react';
import {CircularProgress, Paper} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useStyles from "./statisticsModal.styles";
import {getBotHistoryLaunch} from "../../features/django/djangoSlice";
import {useDispatch, useSelector} from "react-redux";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import {visuallyHidden} from "@mui/utils";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import {green, grey, red} from "@mui/material/colors";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {useEffect} from "react";
import BotBarChart from "../botBarChart/botBarChart";
import Grid from "@mui/material/Grid";
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {capitalizeFirstLetter, daysDiff} from "../../utils/utils";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'order_created_time',
        numeric: false,
        disablePadding: false,
        label: 'Time',
    },
    {
        id: 'order_side',
        numeric: false,
        disablePadding: false,
        label: 'Side',
    },
    {
        id: 'order_id',
        numeric: false,
        disablePadding: false,
        label: 'Order ID',
    },
    {
        id: 'order_original_qty',
        numeric: false,
        disablePadding: false,
        label: 'Quantity',
    },
    {
        id: 'order_price',
        numeric: false,
        disablePadding: false,
        label: 'Price',
    },
    {
        id: 'buy_order_quote_qty',
        numeric: false,
        disablePadding: false,
        label: 'Buy quote',
    },
    {
        id: 'order_quote_qty',
        numeric: false,
        disablePadding: false,
        label: 'Sell quote',
    },
    {
        id: 'order_pnl',
        numeric: false,
        disablePadding: false,
        label: 'PNL',
    },
    {
        id: 'buy_order_id',
        numeric: false,
        disablePadding: false,
        label: 'Buy order ID',
    }
];

function EnhancedTableHead(props) {
    const {order, orderBy, onRequestSort} =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar() {

    return (
        <Toolbar
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1}
            }}
        >
            <ReceiptIcon color="primary" style={{marginRight:10}}/>
            <Typography
                sx={{flex: '1 1 100%'}}
                variant="h6"
                id="tableTitle"
                component="div"
                color="primary"
            >
                Transactions
            </Typography>
        </Toolbar>
    );
}


const StatisticsModal = ({bot_id}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const botConfigData = (useSelector((state) => state.django.botListData)).filter(pilot => pilot.id === bot_id)[0];
    const botHistoryData = (useSelector((state) => state.django.botHistoryData));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        dispatch(getBotHistoryLaunch({
            botId: bot_id
        }))
    }, [bot_id, dispatch])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const getTrendingColor = (price) => {
        if (price > 0)
            return green[400]
        else if (price < 0)
            return red[400]
        return ""
    }

    const getSideColor = (side) => {
        if (side === "SELL")
            return red[400]
        else
            return green[400]
    }

    const getStatusColor = (status) => {
        if (status === "ACTIVE")
            return green[400]
        else if (status === "DEACTIVATED")
            return grey[500]
        else
            return ""
    }

    return (
        <div>
            {botHistoryData ?
                <Box className={classes.modal} sx={{width: '80%'}}>
                    <Paper sx={{width: '100%'}}>
                        <Typography variant="h5" id="chartTitle" sx={{pl: {sm: 2}, pr: {xs: 1, sm: 1}}}
                                    style={{paddingBottom: 10, paddingTop: 10}}>
                            <img
                                src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${botConfigData.base_asset.toLowerCase()}.svg`}
                                className={classes.icon}
                                alt="token_logo"/>{botConfigData.base_asset} / {botConfigData.quote_asset}
                        </Typography>
                        <Grid container justifyContent="space-around">
                            <Grid item xs={1.8} className={classes.kpi}>
                                    <Typography variant="subtitle2" color="primary">Bot status</Typography>
                                    <Typography color={getStatusColor(botConfigData.bot_status)}>{capitalizeFirstLetter(botConfigData.bot_status.toLowerCase())}</Typography>
                            </Grid>
                            <Grid item xs={1.8} className={classes.kpi}>
                                <Typography variant="subtitle2" color="primary">Total PNL</Typography>
                                <Typography>{botConfigData.pnl !== null ? botConfigData.pnl.toFixed(2):0.00} <Box color={grey[500]} display='inline'>{botConfigData.quote_asset}</Box> / <Box color={getTrendingColor(parseFloat((botConfigData.pnl / botConfigData.investment_amount - 1) * 100))} display='inline'>{(botConfigData.pnl !== null ? parseFloat((botConfigData.pnl / botConfigData.investment_amount - 1) * 100).toFixed(2) : parseFloat("0.00").toFixed(2))}%</Box></Typography>
                            </Grid>
                            <Grid item xs={1.8} className={classes.kpi}>
                                    <Typography variant="subtitle2" color="primary">Total profit</Typography>
                                    <Typography>{(botConfigData.profit !== null ? parseFloat(botConfigData.profit).toFixed(2) : parseFloat("0.00").toFixed(2))} <Box color={grey[500]} display='inline'>{botConfigData.quote_asset}</Box> / <Box color={getTrendingColor(parseFloat(botConfigData.profit) / botConfigData.investment_amount * 100)} display='inline'>{botConfigData.profit !== null ? (parseFloat(botConfigData.profit) / botConfigData.investment_amount * 100).toFixed(2) : parseFloat("0.00").toFixed(2)}%</Box></Typography>
                            </Grid>
                            <Grid item xs={1.8} className={classes.kpi}>
                                    <Typography variant="subtitle2" color="primary">Trading time</Typography>
                                    <Typography>{botConfigData.pnl !== null ?daysDiff(botConfigData.start_date, botConfigData.stop_date):""}</Typography>
                            </Grid>
                            <Grid item xs={1.8} className={classes.kpi}>
                                    <Typography variant="subtitle2" color="primary">Transactions</Typography>
                                    <Typography> {botHistoryData.filledData.length}</Typography>
                            </Grid>
                            <Grid item xs={1.8} className={classes.kpi}>
                                <Typography variant="subtitle2" color="primary">Configuration</Typography>
                                <Typography>{botConfigData.low_price !== null ? botConfigData.low_price : ""} | {botConfigData.high_price !== null ? botConfigData.high_price : ""} | {botConfigData.grid_step_size !== null ? botConfigData.grid_step_size : ""}% | {botConfigData.grid_lines !== null ? botConfigData.grid_lines : ""}</Typography>
                            </Grid>
                        </Grid>
                        <Toolbar
                            sx={{
                                pl: {sm: 2},
                                pr: {xs: 1, sm: 1}
                            }}
                            style={{marginTop:5}}
                        >
                            <AttachMoneyIcon color="primary" style={{marginRight:5}}/>
                            <Typography
                                sx={{flex: '1 100%'}}
                                variant="h6"
                                id="chartTitle"
                                component="div"
                                color="primary"
                            >
                                Daily profit
                            </Typography>
                        </Toolbar>
                        <BotBarChart chartData={botHistoryData.chartData} />
                        <EnhancedTableToolbar/>
                        <TableContainer>
                            <Table
                                sx={{minWidth: 750}}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={botHistoryData.filledData.length}
                                />
                                <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                                    {stableSort(botHistoryData.filledData, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow key={row.order_created_time}>
                                                    <TableCell
                                                        align="left">{row.order_created_time !== null ? (new Date(row.order_created_time)).toLocaleString() : ""}</TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="body2"
                                                                    color={getSideColor(row.order_side)}>
                                                            {row.order_side}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">{row.order_id}</TableCell>
                                                    <TableCell align="left">{row.order_side === "SELL" ? row.sell_order_executed_qty : row.buy_order_executed_qty}</TableCell>
                                                    <TableCell align="left">{row.order_price}</TableCell>
                                                    <TableCell
                                                        align="left">{row.buy_order_quote_qty}</TableCell>
                                                    <TableCell
                                                        align="left">{row.sell_order_quote_qty}</TableCell>
                                                    <TableCell align="left"><Typography variant="body2"
                                                                                        color={getTrendingColor(row.order_pnl)}>
                                                        {row.order_pnl}
                                                    </Typography></TableCell>
                                                    <TableCell align="left">{row.order_side === "SELL" ? row.buy_order_id : ""}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Toolbar
                            sx={{
                                pl: {sm: 2},
                                pr: {xs: 1, sm: 1}
                            }}
                        >
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense}/>}
                                label="Dense padding"
                                sx={{flex: '1 1 20%'}}
                            />
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={botHistoryData.filledData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Toolbar>
                    </Paper>
                </Box> :
                <CircularProgress disableShrink/>}
        </div>
    )
        ;
}

export default StatisticsModal;