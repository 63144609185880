import * as React from 'react';
import {Grid, Paper} from "@mui/material";
import {useEffect} from "react";
import Typography from "@mui/material/Typography";
import useStyles from "./liveFeed.styles";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import {green, red} from "@mui/material/colors";
import {useDispatch, useSelector} from "react-redux";
import {binanceGet24hTickerLaunch} from "../../features/binance/binanceSlice";

const LiveFeed = (props) => {
    const {coinList} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const coinsData = useSelector((state) => state.binance.coinsData);

    useEffect(() => {
        setTimeout(() => dispatch(binanceGet24hTickerLaunch({coinList})), coinsData.length === 0 ? 0 : 5000)
    }, [dispatch, coinsData, coinList])

    const getTrendingColor = (priceChangePercent) => {
        if (priceChangePercent > 0)
            return green[400]
        else if (priceChangePercent < 0)
            return red[400]
        return "primary"
    }

    const getTrendingIcon = (priceChangePercent) => {
        if (priceChangePercent > 0)
            return <TrendingUpIcon fontSize="4px" sx={{color: green[400]}}/>
        else if (priceChangePercent < 0)
            return <TrendingDownIcon fontSize="4px" sx={{color: red[400]}}/>
        return <TrendingFlatIcon fontSize="4px"/>
    };

    return (
        <div>
            <Paper elevation={20} className={classes.root} sx={{display: {xs: "none", md: "block"}}}>
                {coinsData && coinsData.map((coin) =>
                    <Grid key={coin.symbol} display="flex" alignItems="center">
                        <Typography
                            sx={{flexGrow: 1}}
                            variant={"heading5"}> {coin.symbol.replace("USDT", "/USDT")} {parseFloat(coin.lastPrice).toFixed(2)}$
                        </Typography>
                        <Typography variant={"subtitle2"} color={getTrendingColor(parseFloat(coin.priceChangePercent))}>
                            {parseFloat(coin.priceChangePercent).toFixed(2)}%
                        </Typography>
                        <Typography>
                            {
                                getTrendingIcon(parseFloat(coin.priceChangePercent))
                            }
                        </Typography>
                    </Grid>
                )}
            </Paper>
            {coinsData && coinsData.map((coin) =>
                <Grid key={coin.symbol} display="flex" alignItems="center" sx={{display: {xs: "block", md: "none"}}}>
                    <img
                        src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${coin.symbol.replace("USDT", "").toLowerCase()}.svg`}
                        className={classes.icon} alt="token_logo"/>
                    <Typography
                        sx={{flexGrow: 1, display: "inline"}}
                        variant={"subtitle2"}>&nbsp;{parseFloat(coin.lastPrice).toFixed(0)}$&nbsp;
                    </Typography>
                    <Typography sx={{flexGrow: 1, display: "inline"}} variant={"subtitle2"} color={getTrendingColor(parseFloat(coin.priceChangePercent))}>
                        {parseFloat(coin.priceChangePercent).toFixed(2)}%
                    </Typography>
                    <Typography sx={{flexGrow: 1, display: "inline"}}>
                        {
                            getTrendingIcon(parseFloat(coin.priceChangePercent))
                        }
                    </Typography>
                </Grid>
            )}
        </div>
    );
}

export default LiveFeed;