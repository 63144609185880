import * as React from 'react';
import {styled} from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {Container, Grid} from "@mui/material";
import Logo from '../../assets/logo/logo_transparent-56px.png';
import LiveFeed from "../liveFeed/liveFeed";
import SummaryBalance from "../summaryBalance/summaryBalance";
import {isExpired} from "react-jwt";

const drawerWidth = 0;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
    }), ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut, duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const StyledHeader = ({open, handleDrawerOpen}) => {
    const accessToken = localStorage.getItem('accessToken');
    const isMyTokenExpired = isExpired(accessToken);

    return (<AppBar position="fixed" open={open}>
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{mr: 0, ...(open)}}
            >
                <MenuIcon/>
            </IconButton>
            <Container maxWidth="false">
                <Grid container direction="row">
                    <Grid item xs={8} md={4}>
                        <Grid container justifyContent="flex-start">
                            <Grid item>
                                <LiveFeed coinList={"BTCUSDT, ETHUSDT"}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} style={{textAlign: 'center'}} sx={{display: {xs: "none", md: "block"}}}>
                        <a href="/">
                            <img alt="DB-Grid-Bot-Logo" src={Logo}/>
                        </a>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                {!isMyTokenExpired && <SummaryBalance/>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Toolbar>
    </AppBar>);
}