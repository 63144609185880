import * as React from 'react';
import {CircularProgress, Paper} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useStyles from "./dcaStatisticsModal.styles";
import {getDCAHistoryLaunch} from "../../features/django/djangoSlice";
import {useDispatch, useSelector} from "react-redux";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import {visuallyHidden} from "@mui/utils";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import {green, grey, red} from "@mui/material/colors";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {useEffect} from "react";
import BotBarChart from "../botBarChart/botBarChart";
import Grid from "@mui/material/Grid";
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {daysDiff} from "../../utils/utils";
import BotPairBarChart from "../botPairBarChart/botPairBarChart";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'order_created_time',
        numeric: false,
        disablePadding: false,
        label: 'Time',
    },
    {
        id: 'pair',
        numeric: false,
        disablePadding: false,
        label: 'Pair',
    },
    {
        id: 'line_id',
        numeric: false,
        disablePadding: false,
        label: 'Order type',
    },
    {
        id: 'order_id',
        numeric: false,
        disablePadding: false,
        label: 'Order ID',
    },
    {
        id: 'order_original_qty',
        numeric: false,
        disablePadding: false,
        label: 'Quantity',
    },
    {
        id: 'order_price',
        numeric: false,
        disablePadding: false,
        label: 'Price',
    },
    {
        id: 'buy_order_price',
        numeric: false,
        disablePadding: false,
        label: 'Buy price',
    },
    {
        id: 'order_quote_qty',
        numeric: false,
        disablePadding: false,
        label: 'Order quote',
    },
    {
        id: 'buy_order_original_qty',
        numeric: false,
        disablePadding: false,
        label: 'Buy quantity',
    },
    {
        id: 'order_pnl',
        numeric: false,
        disablePadding: false,
        label: 'PNL',
    }
];

function EnhancedTableHead(props) {
    const {order, orderBy, onRequestSort} =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar() {

    return (
        <Toolbar
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1}
            }}
        >
            <ReceiptIcon color="primary" style={{marginRight:10}}/>
            <Typography
                sx={{flex: '1 1 100%'}}
                variant="h6"
                id="tableTitle"
                component="div"
                color="primary"
            >
                Transactions
            </Typography>
        </Toolbar>
    );
}


const DcaStatisticsModal = ({bot_id}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dcaConfigData = (useSelector((state) => state.django.dcaListData)).filter(pilot => pilot.id === bot_id)[0];
    const dcaHistoryData = (useSelector((state) => state.django.dcaHistoryData));
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        dispatch(getDCAHistoryLaunch({
            botId: bot_id
        }))
    }, [bot_id, dispatch])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const getTrendingColor = (price) => {
        if (price > 0)
            return green[400]
        else if (price < 0)
            return red[400]
        return ""
    }

    const getOrderTypeColor = (order_type) => {
        if (order_type === 0)
            return ""
        else if (order_type === 1)
            return green[400]
        else if (order_type === 2)
            return green[400]
        else
            return red[400]
    }


    const processHistoryPairData = (filledData) => {
        const groupedData = filledData.reduce((acc, curr) => {
            if (curr.order_pnl !== null) {
                if (!acc[curr.pair]) {
                    acc[curr.pair] = { pair: curr.pair, pnl: 0 };
                }
                acc[curr.pair].pnl += curr.order_pnl;
            }
            return acc;
        }, {});

        return Object.values(groupedData);
    };

    const processedData = dcaHistoryData ? processHistoryPairData(dcaHistoryData.filledData) : [];

    return (
        <div>
            {dcaHistoryData ?
                <Box className={classes.modal} sx={{width: '80%'}}>
                    <Paper sx={{width: '100%'}}>
                        <Typography variant="h5" id="chartTitle" sx={{pl: {sm: 2}, pr: {xs: 1, sm: 1}}}
                                    style={{paddingBottom: 10, paddingTop: 10}}>
                            Bot {dcaConfigData.id} - {dcaConfigData.name}
                        </Typography>
                        <Grid container justifyContent="space-around">
                            <Grid item xs={1.6} className={classes.kpi}>
                                <Typography variant="subtitle2" color="primary">Start signal</Typography>
                                <Typography> {dcaConfigData.start_signal !== null ? dcaConfigData.start_signal + " (" +dcaConfigData.start_signal_period + ")": ""}</Typography>
                            </Grid>
                            <Grid item xs={1.6} className={classes.kpi}>
                                <Typography variant="subtitle2" color="primary">Base amount</Typography>
                                <Typography>{dcaConfigData.base_order !== null ? dcaConfigData.base_order + " " + dcaConfigData.profit_currency: ""}</Typography>
                            </Grid>
                            <Grid item xs={1.6} className={classes.kpi}>
                                <Typography variant="subtitle2" color="primary">Averaging amount</Typography>
                                <Typography>{dcaConfigData.averaging_order_amount !== null ? dcaConfigData.averaging_order_amount + " " + dcaConfigData.profit_currency + " / " + dcaConfigData.averaging_order_quantity + " (x" +dcaConfigData.amount_multiplier + ")": ""}</Typography>
                            </Grid>
                            <Grid item xs={1.6} className={classes.kpi}>
                                <Typography variant="subtitle2" color="primary">Averaging step</Typography>
                                <Typography>{dcaConfigData.averaging_order_step !== null ? dcaConfigData.averaging_order_step + "% (x" +dcaConfigData.step_multiplier + ")": ""}</Typography>
                            </Grid>
                            <Grid item xs={1.6} className={classes.kpi}>
                                <Typography variant="subtitle2" color="primary">Take profit</Typography>
                                <Typography>{dcaConfigData.take_profit_step !== null ? dcaConfigData.take_profit_step + " %" : ""}</Typography>
                            </Grid>
                            <Grid item xs={1.6} className={classes.kpi}>
                                <Typography variant="subtitle2" color="primary">Trading time</Typography>
                                <Typography>{dcaConfigData.pnl !== null ?daysDiff(dcaConfigData.start_date, dcaConfigData.stop_date):""}</Typography>
                            </Grid>
                            <Grid item xs={1.6} className={classes.kpi}>
                                    <Typography variant="subtitle2" color="primary">Deals (transactions)</Typography>
                                    <Typography>{dcaConfigData.cycles !== null ? dcaConfigData.cycles +" ("+dcaHistoryData.filledData.length+")" : ""}</Typography>
                            </Grid>
                        </Grid>
                        <Toolbar
                            sx={{
                                pl: {sm: 2},
                                pr: {xs: 1, sm: 1}
                            }}
                            style={{marginTop:5}}
                        >
                            <AttachMoneyIcon color="primary" style={{marginRight:5}}/>
                            <Typography
                                sx={{flex: '1 100%'}}
                                variant="h6"
                                id="chartTitle"
                                component="div"
                                color="primary"
                            >
                                Profit by day & pair
                            </Typography>
                        </Toolbar>
                        <Grid container justifyContent="center" gap={1}>
                            <Grid item xs={5.9} className={classes.chart}>
                                <BotBarChart chartData={dcaHistoryData.chartData} />
                            </Grid>
                            <Grid item xs={5.9} className={classes.chart}>
                                <BotPairBarChart chartData={processedData} />
                            </Grid>
                        </Grid>
                        <EnhancedTableToolbar/>
                        <TableContainer>
                            <Table
                                sx={{minWidth: 750}}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={dcaHistoryData.filledData.length}
                                />
                                <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                                    {stableSort(dcaHistoryData.filledData, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow key={row.order_created_time}>
                                                    <TableCell
                                                        align="left">{row.order_created_time !== null ? (new Date(row.order_created_time)).toLocaleString() : ""}</TableCell>
                                                    <TableCell align="left">{row.pair}</TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="body2"
                                                                    color={getOrderTypeColor(row.line_id)}>
                                                            {row.line_id===0?"Base":row.line_id===1?"TP":row.line_id===2?"Sell off": "Averaging"}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">{row.order_id}</TableCell>
                                                    <TableCell align="left">{row.order_original_qty}</TableCell>
                                                    <TableCell align="left">{row.buy_order_original_qty}</TableCell>
                                                    <TableCell align="left">{row.order_price}</TableCell>
                                                    <TableCell
                                                        align="left">{row.buy_order_price}</TableCell>
                                                    <TableCell
                                                        align="left">{row.order_quote_qty}</TableCell>
                                                    <TableCell align="left"><Typography variant="body2"
                                                                                        color={getTrendingColor(row.order_pnl)}>
                                                        {row.order_pnl}
                                                    </Typography></TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Toolbar
                            sx={{
                                pl: {sm: 2},
                                pr: {xs: 1, sm: 1}
                            }}
                        >
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense}/>}
                                label="Dense padding"
                                sx={{flex: '1 1 20%'}}
                            />
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={dcaHistoryData.filledData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Toolbar>
                    </Paper>
                </Box> :
                <CircularProgress disableShrink/>}
        </div>
    )
        ;
}

export default DcaStatisticsModal;