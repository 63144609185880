import moment from 'moment';
import {put, takeEvery} from 'redux-saga/effects'
import {failure} from "../../app/appSlice";
import {
    getBotConfigFailure,
    getBotConfigSuccess,
    getBotListFailure,
    getBotListSuccess,
    getBotGridFailure,
    getBotGridSuccess,
    getBotHistoryFailure,
    getBotHistorySuccess,
    postRunBotFailure,
    postRunBotSuccess,
    postStopBotFailure,
    postStopBotSuccess,
    postSellPairFailure,
    postSellPairSuccess,
    postUpdatePNLFailure,
    postUpdatePNLSuccess,
    postAuthentificationFailure,
    postAuthentificationSuccess,
    postBotConfigFailure,
    postBotConfigSuccess,
    postDCAConfigFailure,
    postDCAConfigSuccess,
    copyBotConfigFailure,
    deleteBotConfigFailure,
    deleteBotConfigSuccess,
    refreshTokenAction,
    getHistoryFailure,
    getHistorySuccess,
    getLogFileFailure,
    getLogFileSuccess,
    getDCAConfigSuccess,
    getDCAConfigFailure,
    getDCAListSuccess,
    getDCAListFailure,
    getDCAGridSuccess,
    getDCAGridFailure,
    getDCAHistorySuccess,
    getDCAHistoryFailure,
    postRunDCASuccess,
    postRunDCAFailure,
    postStopDCASuccess,
    postStopDCAFailure,
    postStopDCAPairSuccess,
    postStopDCAPairFailure,
    deleteDCAConfigFailure,
    deleteDCAConfigSuccess,
    postManualAveragingSuccess,
    postManualAveragingFailure,
    getAccountInformationFailure,
    getAccountInformationSuccess,
    getAccountSnapshotSuccess, getAccountSnapshotFailure,
} from "./djangoSlice";
import privateApiClient from "../../services/privateApiClient";
import {sumObj} from "../../utils/utils";
import axios from "axios";

function* getBotListCall() {
    try {
        const response = yield privateApiClient.get(`/bot-config/?format=json`,);
        if (response.status !== 200) {
            yield put(failure({message: "Token need to be refresh"}));
            yield put(getBotListFailure({error: response.toString()}));
        }
        yield put(getBotListSuccess(response.data));
    } catch (error) {
        yield put(failure({message: "Error during token refresh"}));
        yield put(getBotListFailure({error: error.toString()}));
    }
}

function* getDCAListCall() {
    try {
        //Step 1, get the list of bots and status
        const response = yield privateApiClient.get(`/dca-config/?include_grid=true&format=json`,);
        if (response.status !== 200) {
            yield put(failure({message: "Token need to be refresh"}));
            yield put(getDCAListFailure({error: response.toString()}));
        }
        yield put(getDCAListSuccess(response.data));

        //Step 2, update the ACTIVE bots
        const activeBots = response.data.filter((bot) => bot.bot_status === 'ACTIVE');
        const botListPids = activeBots.map((bot) => String(bot.pid)).join(",");
        const check_pid = yield privateApiClient.get(`/check-pid/?pid=${botListPids}`);

        if (check_pid.status !== 200) {
            yield put(failure({message: "CheckPID has failed"}));
            yield put(postUpdatePNLFailure({error: check_pid.toString()}));
        }

        const mergedBotList = response.data.map((bot) => {
            if (check_pid.data && check_pid.data.bot_status) {
                const statusObj = check_pid.data.bot_status.find((status) => status.pid === bot.pid);
                if (statusObj) {
                    return { ...bot, pid_status: statusObj.status };
                }
            }
            return bot;
        });


        yield put(getDCAListSuccess(mergedBotList));

    } catch (error) {
        yield put(failure({message: "Error during token refresh"}));
        yield put(getDCAListFailure({error: error.toString()}));
    }
}

function* getBotConfigCall(action) {
    try {
        const {botId} = action.payload;
        const response = yield privateApiClient.get(`/bot-config/${botId}/?format=json`,);
        if (response.status !== 200) {
            yield put(failure({message: "Token need to be refresh"}));
            yield put(getBotConfigFailure({error: response.toString()}));
        }
        yield put(getBotConfigSuccess(response.data));
    } catch (error) {
        yield put(failure({message: "Error during token refresh"}));
        yield put(getBotConfigFailure({error: error.toString()}));
    }
}

function* getDCAConfigCall(action) {
    try {
        const {botId} = action.payload;
        const response = yield privateApiClient.get(`/dca-config/${botId}/?format=json`,);
        if (response.status !== 200) {
            yield put(failure({message: "Token need to be refresh"}));
            yield put(getDCAConfigFailure({error: response.toString()}));
        }
        yield put(getDCAConfigSuccess(response.data));
    } catch (error) {
        yield put(failure({message: "Error during token refresh"}));
        yield put(getDCAConfigFailure({error: error.toString()}));
    }
}

function* copyBotConfigCall(action) {
    try {
        const {bot_id} = action.payload;
        const getresponse = yield privateApiClient.get(`/bot-config/${bot_id}/?format=json`,);
        if (getresponse.status !== 200) {
            yield put(failure({message: "Token need to be refresh"}));
            yield put(getBotConfigFailure({error: getresponse.toString()}));
        }
        const formdata = new FormData();
        formdata.append("exchange", getresponse.data.exchange);
        formdata.append("profit_currency", getresponse.data.profit_currency);
        formdata.append("pair", getresponse.data.pair);
        formdata.append("base_asset", getresponse.data.base_asset);
        formdata.append("quote_asset", getresponse.data.quote_asset);
        formdata.append("start_mode", getresponse.data.start_mode);
        formdata.append("exchange", getresponse.data.exchange);
        formdata.append("investment_amount", getresponse.data.investment_amount);
        formdata.append("low_price", getresponse.data.low_price);
        formdata.append("high_price", getresponse.data.high_price);
        formdata.append("grid_step_size", getresponse.data.grid_step_size);
        formdata.append("grid_lines", getresponse.data.grid_lines);

        const response = yield privateApiClient.post(`/bot-config/`, formdata);

        if (response.status !== 201) {
            yield put(failure({message: "Get bot config fail"}));
            yield put(postBotConfigFailure({error: response.toString()}));
        }
        yield put(postBotConfigSuccess(response.data));
        const update = yield privateApiClient.get(`/bot-config/?format=json`,);
        yield put(getBotListSuccess(update.data));
    } catch (error) {
        yield put(failure({message: "Copy bot config fail"}));
        yield put(copyBotConfigFailure({error: error.toString()}));
    }
}

function* copyDCAConfigCall(action) {
    try {
        const {bot_id} = action.payload;
        const getresponse = yield privateApiClient.get(`/dca-config/${bot_id}/?format=json`,);
        if (getresponse.status !== 200) {
            yield put(failure({message: "Token need to be refresh"}));
            yield put(getDCAConfigFailure({error: getresponse.toString()}));
        }

        const pairs_m2m = getresponse.data.pairs_m2m.map(pairObj => pairObj.pair);

        const data = {
            name: getresponse.data.name,
            exchange: getresponse.data.exchange,
            profit_currency: getresponse.data.profit_currency,
            pairs_m2m: pairs_m2m,
            market: getresponse.data.market,
            max_active_pairs: getresponse.data.max_active_pairs,
            investment_amount: getresponse.data.investment_amount,
            base_order: getresponse.data.base_order,
            averaging_order_amount: getresponse.data.averaging_order_amount,
            averaging_order_quantity: getresponse.data.averaging_order_quantity,
            averaging_order_step: getresponse.data.averaging_order_step,
            amount_multiplier: getresponse.data.amount_multiplier,
            step_multiplier: getresponse.data.step_multiplier,
            take_profit_step: getresponse.data.take_profit_step,
            take_profit_method: getresponse.data.take_profit_method,
            start_signal: getresponse.data.start_signal,
            start_signal_period: getresponse.data.start_signal_period
        };

        const response = yield privateApiClient.post(`/dca-config/`, data);

        if (response.status !== 201) {
            yield put(failure({ message: "Get dca config fail" }));
            yield put(postDCAConfigFailure({ error: response.toString() }));
        }
        yield put(postDCAConfigSuccess(response.data));
        const update = yield privateApiClient.get(`/dca-config/?format=json`);
        yield put(getDCAListSuccess(update.data));
    } catch (error) {
        yield put(failure({ message: "Get DCA config fail" }));
        yield put(postDCAConfigFailure({ error: error.toString() }));
    }
}

function* postBotConfigCall(action) {
    try {
        const {
            exchange,
            profit_currency,
            pair,
            base_asset,
            quote_asset,
            start_mode,
            investment_amount,
            low_price,
            high_price,
            grid_step_size,
            grid_lines
        } = action.payload;

        const formdata = new FormData();
        formdata.append("exchange", exchange);
        formdata.append("profit_currency", profit_currency);
        formdata.append("pair", pair);
        formdata.append("base_asset", base_asset);
        formdata.append("quote_asset", quote_asset);
        formdata.append("start_mode", start_mode);
        formdata.append("exchange", exchange);
        formdata.append("investment_amount", investment_amount);
        formdata.append("low_price", low_price);
        formdata.append("high_price", high_price);
        formdata.append("grid_step_size", grid_step_size);
        formdata.append("grid_lines", grid_lines);

        const response = yield privateApiClient.post(`/bot-config/`, formdata);

        if (response.status !== 201) {
            yield put(failure({message: "Get bot config fail"}));
            yield put(postBotConfigFailure({error: response.toString()}));
        }
        yield put(postBotConfigSuccess(response.data));
        const update = yield privateApiClient.get(`/bot-config/?format=json`,);
        yield put(getBotListSuccess(update.data));
    } catch (error) {
        yield put(failure({message: "Get bot config fail"}));
        yield put(postBotConfigFailure({error: error.toString()}));
    }
}

async function createPairs(pairs_m2m, market) {
    const pair_response = await privateApiClient.get(`/pair/`);

    for (const pair of pairs_m2m) {
        const found = pair_response.data.some((item) => item.pair === pair);
        if (!found) {
            const formdata = new FormData();
            formdata.append("pair", pair);
            formdata.append("base_asset", pair.replace(market, ""));
            formdata.append("quote_asset", market);
            await privateApiClient.post(`/pair/`, formdata);
        }
    }
}

function* postDCAConfigCall(action) {
    try {
        const {
            name,
            exchange,
            profit_currency,
            pairs_m2m,
            market,
            max_active_pairs,
            investment_amount,
            base_order,
            averaging_order_amount,
            averaging_order_quantity,
            averaging_order_step,
            amount_multiplier,
            step_multiplier,
            take_profit_step,
            take_profit_method,
            start_signal,
            start_signal_period,
        } = action.payload;


        const data = {
            name,
            exchange,
            profit_currency,
            pairs_m2m, // Use the updated key here
            max_active_pairs,
            investment_amount,
            base_order,
            averaging_order_amount,
            averaging_order_quantity,
            averaging_order_step,
            amount_multiplier,
            step_multiplier,
            take_profit_step,
            take_profit_method,
            start_signal,
            start_signal_period,
        };

        // Call the async function to create pairs
        yield createPairs(pairs_m2m, market);

        const response = yield privateApiClient.post(`/dca-config/`, data);

        if (response.status !== 201) {
            yield put(failure({ message: "Get dca config fail" }));
            yield put(postDCAConfigFailure({ error: response.toString() }));
        }
        yield put(postDCAConfigSuccess(response.data));
        yield privateApiClient.get(`/dca-config/?format=json`);
        // yield put(getDCAListSuccess(update.data));
    } catch (error) {
        yield put(failure({ message: "Get DCA config fail" }));
        yield put(postDCAConfigFailure({ error: error.toString() }));
    }
}

function* deleteBotConfigCall(action) {
    try {
        const {
            bot_id
        } = action.payload;
        const response = yield privateApiClient.delete(`/bot-config/${bot_id}/?format=json`,);
        if (response.status !== 204) {
            yield put(failure({message: "Token need to be refresh"}));
            yield put(deleteBotConfigFailure({error: response.toString()}));
        }
        yield put(deleteBotConfigSuccess(response.data));
        const update = yield privateApiClient.get(`/bot-config/?format=json`,);
        yield put(getBotListSuccess(update.data));
    } catch (error) {
        yield put(failure({message: "Error during token refresh"}));
        yield put(deleteBotConfigFailure({error: error.toString()}));
    }
}

function* deleteDCAConfigCall(action) {
    try {
        const {
            bot_id
        } = action.payload;
        const response = yield privateApiClient.delete(`/dca-config/${bot_id}/?format=json`,);
        if (response.status !== 204) {
            yield put(failure({message: "Token need to be refresh"}));
            yield put(deleteDCAConfigFailure({error: response.toString()}));
        }
        yield put(deleteDCAConfigSuccess(response.data));
        const update = yield privateApiClient.get(`/dca-config/?format=json`,);
        yield put(getDCAListSuccess(update.data));
    } catch (error) {
        yield put(failure({message: "Error during token refresh"}));
        yield put(deleteDCAConfigFailure({error: error.toString()}));
    }
}

function* getBotGridCall(action) {
    try {
        const {botId} = action.payload;

        const response = yield privateApiClient.get(`${process.env.REACT_APP_DJANGO_API_URL}/api/bot-grid/?format=json&bot_id=${botId}`);

        if (response.status !== 200) {
            yield put(failure({message: "Get bot grid fail"}));
            yield put(getBotGridFailure({error: response.toString()}));
        }
        yield put(getBotGridSuccess(response.data));
    } catch (error) {
        yield put(failure({message: "Get bot grid fail"}));
        yield put(getBotGridFailure({error: error.toString()}));
    }
}

function* getDCAGridCall(action) {
    try {
        const {botId, pair} = action.payload;

        const response = yield privateApiClient.get(`${process.env.REACT_APP_DJANGO_API_URL}/api/dca-grid/?format=json&bot_id=${botId}&pair=${pair}`);

        if (response.status !== 200) {
            yield put(failure({message: "Get DCA grid fail"}));
            yield put(getDCAGridFailure({error: response.toString()}));
        }
        yield put(getDCAGridSuccess(response.data));
    } catch (error) {
        yield put(failure({message: "Get DCA grid fail"}));
        yield put(getDCAGridFailure({error: error.toString()}));
    }
}

function* getBotHistoryCall(action) {
    try {
        const {botId} = action.payload;

        const response = yield privateApiClient.get(`/bot-history/?format=json&bot_id=${botId}`);

        if (response.status !== 200) {
            yield put(failure({message: "Get bot history fail"}));
            yield put(getBotHistoryFailure({error: response.toString()}));
        }
        const botHistoryFilledData = response.data.sort((a, b) => b.id - a.id)
        let chartData = []
        for (let i = 0; i < botHistoryFilledData.length; i++) {
            chartData[i] = {}
            chartData[i].time = new Date((new Date(botHistoryFilledData[i].order_created_time)).toISOString().split('T')[0]).getTime()
            chartData[i].value = botHistoryFilledData[i].order_pnl
        }
        if (process.env.REACT_APP_ENVIRONMENT !== "prod") {
            chartData.push({time: 1668643200000, value: 0.60})
            chartData.push({time: 1668556800000, value: 0.91})
            chartData.push({time: 1668729600000, value: 0.191})
            chartData.push({time: 1668816000000, value: 0.191})
            chartData.push({time: 1668902400000, value: 0.291})
        }
        //END OF TEST CODE
        chartData = sumObj(chartData)
        const data = {
            filledData: botHistoryFilledData,
            chartData: chartData
        };

        yield put(getBotHistorySuccess(data));
    } catch (error) {
        yield put(failure({message: "Get bot history fail"}));
        yield put(getBotHistoryFailure({error: error.toString()}));
    }
}

function* getDCAHistoryCall(action) {
    try {
        const {botId} = action.payload;

        const response = yield privateApiClient.get(`/dca-history/?format=json&bot_id=${botId}`);

        if (response.status !== 200) {
            yield put(failure({message: "Get dca history fail"}));
            yield put(getDCAHistoryFailure({error: response.toString()}));
        }
        const botHistoryFilledData = response.data.sort((a, b) => b.id - a.id)
        let chartData = []
        for (let i = 0; i < botHistoryFilledData.length; i++) {
            chartData[i] = {}
            chartData[i].time = new Date((new Date(botHistoryFilledData[i].order_created_time)).toISOString().split('T')[0]).getTime()
            chartData[i].value = botHistoryFilledData[i].order_pnl
        }
        if (process.env.REACT_APP_ENVIRONMENT !== "prod") {
            chartData.push({time: 1668643200000, value: 0.60})
            chartData.push({time: 1668556800000, value: 0.91})
            chartData.push({time: 1668729600000, value: 0.191})
            chartData.push({time: 1668816000000, value: 0.191})
            chartData.push({time: 1668902400000, value: 0.291})
        }
        //END OF TEST CODE
        chartData = sumObj(chartData)
        const data = {
            filledData: botHistoryFilledData,
            chartData: chartData
        };

        yield put(getDCAHistorySuccess(data));
    } catch (error) {
        yield put(failure({message: "Get dca history fail"}));
        yield put(getDCAHistoryFailure({error: error.toString()}));
    }
}

function* getHistoryCall(action) {
    try {
        const {date} = action.payload;

        const response = yield privateApiClient.get(`/bot-history/?format=json&date=${date}`);
        const response_dca = yield privateApiClient.get(`/dca-history/?format=json&date=${date}`);

        if (response.status !== 200 || response_dca.status !== 200) {
            yield put(failure({message: "Get history fail"}));
            yield put(getHistoryFailure({error: response.toString()}));
        }
        const dailyPNL = {};
        response.data.forEach(order => {
            const date = moment(order.order_created_time).format('YYYY-MM-DD');
            const pnl = order.order_pnl || 0;
            if (order.line_id !== -3) {
                if (dailyPNL[date]) {
                    dailyPNL[date] += pnl;
                } else {
                    dailyPNL[date] = pnl;
                }
            }
        });
        response_dca.data.forEach(order => {
            const date = moment(order.order_created_time).format('YYYY-MM-DD');
            const pnl = order.order_pnl || 0;
            if (order.line_id !== -3) {
                if (dailyPNL[date]) {
                    dailyPNL[date] += pnl;
                } else {
                    dailyPNL[date] = pnl;
                }
            }
        });
        response.data.historyWeekly = Object.entries(dailyPNL).map(([date, totalPNL]) => ({
            date,
            time: moment(date, 'YYYY-MM-DD').valueOf(),
            value: totalPNL
        }));

        yield put(getHistorySuccess(response.data.historyWeekly));
    } catch (error) {
        yield put(failure({message: "Get history fail"}));
        yield put(getHistoryFailure({error: error.toString()}));
    }
}

function* postRunBotCall(action) {
    try {
        const {
            bot_id
        } = action.payload;

        const formdata = new FormData();
        formdata.append("bot_id", bot_id);

        const response = yield privateApiClient.post(`/runbot/`, formdata);

        if (response.status !== 200) {
            yield put(failure({message: "Bot has failed to start"}));
            yield put(postRunBotFailure({error: response.toString()}));
        }
        yield put(postRunBotSuccess(response.data));
    } catch (error) {
        yield put(failure({message: "Bot has failed to start"}));
        yield put(postRunBotFailure({error: error.toString()}));
    }
}

function* postRunDCACall(action) {
    try {
        const {
            bot_id
        } = action.payload;

        const formdata = new FormData();
        formdata.append("bot_id", bot_id);

        const response = yield privateApiClient.post(`/rundcabot/`, formdata);

        if (response.status !== 200) {
            yield put(failure({message: "DCA Bot has failed to start"}));
            yield put(postRunDCAFailure({error: response.toString()}));
        }
        yield put(postRunDCASuccess(response.data));
    } catch (error) {
        yield put(failure({message: "DCA Bot has failed to start"}));
        yield put(postRunDCAFailure({error: error.toString()}));
    }
}

function* postStopBotCall(action) {
    try {
        const {
            bot_id
        } = action.payload;

        const formdata = new FormData();
        formdata.append("bot_id", bot_id);

        const response = yield privateApiClient.post(`/stopbot/`, formdata);

        if (response.status !== 200) {
            yield put(failure({message: "Bot has failed to stop"}));
            yield put(postStopBotFailure({error: response.toString()}));
        }
        yield put(postStopBotSuccess(response.data));
        const update = yield privateApiClient.get(`/bot-config/?format=json`,);
        yield put(getBotListSuccess(update.data));
    } catch (error) {
        yield put(failure({message: "Bot has failed to stop"}));
        yield put(postStopBotFailure({error: error.toString()}));
    }
}

function* postStopDCACall(action) {
    try {
        const {
            bot_id
        } = action.payload;

        const formdata = new FormData();
        formdata.append("bot_id", bot_id);

        const response = yield privateApiClient.post(`/stopdcabot/`, formdata);

        if (response.status !== 200) {
            yield put(failure({message: "DCA Bot has failed to stop"}));
            yield put(postStopDCAFailure({error: response.toString()}));
        }
        yield put(postStopDCASuccess(response.data));
        const update = yield privateApiClient.get(`/dca-config/?format=json`,);
        yield put(getDCAListSuccess(update.data));
    } catch (error) {
        yield put(failure({message: "DCA Bot has failed to stop"}));
        yield put(postStopDCAFailure({error: error.toString()}));
    }
}

function* postManualAveragingCall(action) {
    try {
        const {
            bot_id,
            pair,
            price,
            quantity,
        } = action.payload;

        const formdata = new FormData();
        formdata.append("bot_id", bot_id);
        formdata.append("pair", pair);
        formdata.append("price", price);
        formdata.append("quantity", quantity);

        const response = yield privateApiClient.post(`/manualaveraging/`, formdata);

        if (response.status !== 200) {
            yield put(failure({message: "Manual averaging failed"}));
            yield put(postManualAveragingFailure({error: response.toString()}));
        }
        yield put(postManualAveragingSuccess(response.data));
        yield privateApiClient.get(`/dca-config/?format=json`,);
        // yield put(getDCA>ListSuccess(update.data));
    } catch (error) {
        yield put(failure({message: "Manual averaging failed"}));
        yield put(postManualAveragingFailure({error: error.toString()}));
    }
}

function* postStopDCAPairCall(action) {
    try {
        const {
            bot_id,
            pair
        } = action.payload;

        const formdata = new FormData();
        formdata.append("bot_id", bot_id);
        formdata.append("pair", pair);

        const response = yield privateApiClient.post(`/stopdcapair/`, formdata);

        if (response.status !== 200) {
            yield put(failure({message: "DCA pair has failed to stop"}));
            yield put(postStopDCAPairFailure({error: response.toString()}));
        }
        yield put(postStopDCAPairSuccess(response.data));
        const update = yield privateApiClient.get(`/dca-config/?format=json`,);
        yield put(getDCAListSuccess(update.data));
    } catch (error) {
        yield put(failure({message: "DCA pair has failed to stop"}));
        yield put(postStopDCAPairFailure({error: error.toString()}));
    }
}

function* postSellPairCall(action) {
    try {
        const {
            bot_id
        } = action.payload;

        const formdata = new FormData();
        formdata.append("bot_id", bot_id);

        const response = yield privateApiClient.post(`/sellpair/`, formdata);

        if (response.status !== 200) {
            yield put(failure({message: "Bot has failed to sell the token"}));
            yield put(postSellPairFailure({error: response.toString()}));
        }
        yield put(postSellPairSuccess(response.data));
    } catch (error) {
        yield put(failure({message: "Bot has failed to sell the token"}));
        yield put(postSellPairFailure({error: error.toString()}));
    }
}

function* postUpdatePNLCall() {
    try {
        //Step 1, get the list of bots and status
        let response = yield privateApiClient.get(`/bot-config/?bot_status=ACTIVE,RANGE&format=json`,);
        if (response.status !== 200) {
            yield put(failure({message: "Token need to be refresh"}));
            yield put(getBotListFailure({error: response.toString()}));
        }
        yield put(getBotListSuccess(response.data));
        const botListIds = response.data.map((bot) => String(bot.id)).join(",");

        //Step 2, update the ACTIVE bots
        const formdata = new FormData();
        formdata.append("bot_id", botListIds);

        const update = yield privateApiClient.post(`/updatepnl/`, formdata);

        if (update.status !== 200) {
            yield put(failure({message: "PNL has failed to update"}));
            yield put(postUpdatePNLFailure({error: update.toString()}));
        }
        yield put(postUpdatePNLSuccess(update.data));

        //Step 3, check if the bot processes are still running
        const botListPids = response.data.map((bot) => String(bot.pid)).join(",");
        const check_pid = yield privateApiClient.get(`/check-pid/?pid=${botListPids}`);

        if (check_pid.status !== 200) {
            yield put(failure({message: "CheckPID has failed"}));
            yield put(postUpdatePNLFailure({error: check_pid.toString()}));
        }

        //Step 4, refresh the bot_list data with the updated PNL
        response = yield privateApiClient.get(`/bot-config/?format=json`,);
        if (response.status !== 200) {
            yield put(failure({message: "Token need to be refresh"}));
            yield put(getBotListFailure({error: response.toString()}));
        }

        const mergedBotList = response.data.map((bot) => {
            const statusObj = check_pid.data && check_pid.data.bot_status ? check_pid.data.bot_status.find((status) => status.pid === bot.pid) : undefined;
            if (statusObj) {
                return {...bot, pid_status: statusObj.status};
            }
            return bot;
        });

        yield put(getBotListSuccess(mergedBotList));

    } catch (error) {
        yield put(failure({message: error.toString()}));
        yield put(getBotListFailure({error: error.toString()}));
    }
}

function* postAuthentificationCall(action) {
    try {
        const {username, password} = action.payload;

        const formdata = new FormData();
        formdata.append("username", username);
        formdata.append("password", password);

        const response = yield privateApiClient.post(`/token/`,
            formdata
        );

        if (response.status !== 200) {
            yield put(failure({message: `An error has occured: ${response.toString()}`}));
            yield put(postAuthentificationFailure({error: response.toString()}));
        }
        if (response.data.access && response.data.refresh) {
            localStorage.setItem('accessToken', response.data.access)
            localStorage.setItem('refreshToken', response.data.refresh)
            yield put(refreshTokenAction(response.data.access))
        }
        yield put(postAuthentificationSuccess({data: response.data}));
        window.location.assign("/");
    } catch (error) {
        yield put(failure({message: `An error has occured: ${error}`}));
        yield put(postAuthentificationFailure({error: error.toString()}));
    }
}

function* getLogFileCall(action) {
    try {
        const {botId, botType} = action.payload;
        const response = yield privateApiClient.get(`/get-log/?bot_id=${botId}&bot_type=${botType}`,);
        if (response.status !== 200) {
            yield put(failure({message: "Token need to be refresh"}));
            yield put(getLogFileFailure({error: response.toString()}));
        }
        yield put(getLogFileSuccess(response.data));
    } catch (error) {
        yield put(failure({message: "Error during token refresh"}));
        yield put(getLogFileFailure({error: error.toString()}));
    }
}

function* getAccountInformationCall() {
    try {
        const response = yield privateApiClient.get(`/account-information/`);
        if (response.status !== 200) {
            yield put(failure({message: "Get account information fail"}));
            yield put(getAccountInformationFailure({error: response.toString()}));
        }

        let chartData = []
        // for (let i = 0; i < response.data.balances.length; i++) {
        //     chartData[i] = {}
        //     chartData[i].asset = response.data.balances[i].asset
        //     chartData[i].amount = parseFloat(response.data.balances[i].free) + parseFloat(response.data.balances[i].locked)
        //     chartData[i].free = parseFloat(response.data.balances[i].free)
        //     chartData[i].locked = parseFloat(response.data.balances[i].locked)
        //     if (chartData[i].asset !== 'USDT') {
        //         chartData[i].value = parseFloat(response.data.balances[i].totalUSDT)
        //         chartData[i].priceChangePercent24h = parseFloat(response.data.balances[i].priceChangePercent24h)
        //         chartData[i].price = parseFloat(response.data.balances[i].price)
        //     } else {
        //         chartData[i].value = parseFloat(response.data.balances[i].free) + parseFloat(response.data.balances[i].locked)
        //         chartData[i].priceChangePercent24h = 0
        //         chartData[i].price = 1
        //     }
        //     chartData[i].share = chartData[i].value / response.data.accountTotalUSDT * 100
        // }
        //
        // response.data.accountBalances = chartData.aggregate.filter(pilot => pilot.value > 10.00);

        yield put(getAccountInformationSuccess({data: response.data}));
    } catch (error) {
        yield put(failure({message: "Get account information fail"}));
        yield put(getAccountInformationFailure({error: error.toString()}));
    }
}

function* getAccountSnapshotCall() {
    try {
        const tickers = (yield axios.get(`https://api.cryptoCom.com/api/v3/ticker/price`)).data;
        const response = yield privateApiClient.get(`/account-snapshot/`);
        if (response.status !== 200) {
            yield put(failure({message: "Get account snapshot fail"}));
            yield put(getAccountSnapshotFailure({error: response.toString()}));
        }
        const accountSnapshotData = response.data.snapshotVos;
        let chartData = []
        for (let i = 0; i < accountSnapshotData.length; i++) {
            chartData[i] = {}
            chartData[i].time = accountSnapshotData[i].updateTime
            chartData[i].value = 0
            let filteredData = accountSnapshotData[i].data.balances.filter(item => parseFloat(item.free) > 0 || parseFloat(item.locked) > 0);
            for (let j = 0; j < filteredData.length; j++) {
                if (tickers.filter(item => item.symbol === filteredData[j]["asset"]+"USDT")[0])  {
                    chartData[i].value += ((parseFloat(filteredData[j]["locked"])+parseFloat(filteredData[j]["free"])) * tickers.filter(item => item.symbol === filteredData[j]["asset"]+"USDT")[0]["price"]);
                }
                if (filteredData[j]["asset"] === "USDT" ){
                    chartData[i].value +=  parseFloat(filteredData[j]["free"])+parseFloat(filteredData[j]["locked"])
                }
            }
        }
        response.data.chartData = chartData;
        yield put(getAccountSnapshotSuccess(response.data))
    } catch
        (error) {
        yield put(failure({message: "Get account snapshot fail"}));
        yield put(getAccountSnapshotFailure({error: error.toString()}));
    }
}

function* djangoSaga() {
    yield takeEvery("django/getBotConfigLaunch", getBotConfigCall);
    yield takeEvery("django/getDCAConfigLaunch", getDCAConfigCall);
    yield takeEvery("django/getBotListLaunch", getBotListCall);
    yield takeEvery("django/getDCAListLaunch", getDCAListCall);
    yield takeEvery("django/copyBotConfigLaunch", copyBotConfigCall);
    yield takeEvery("django/copyDCAConfigLaunch", copyDCAConfigCall);
    yield takeEvery("django/postBotConfigLaunch", postBotConfigCall);
    yield takeEvery("django/postDCAConfigLaunch", postDCAConfigCall);
    yield takeEvery("django/deleteBotConfigLaunch", deleteBotConfigCall);
    yield takeEvery("django/deleteDCAConfigLaunch", deleteDCAConfigCall);
    yield takeEvery("django/getBotGridLaunch", getBotGridCall);
    yield takeEvery("django/getDCAGridLaunch", getDCAGridCall);
    yield takeEvery("django/getBotHistoryLaunch", getBotHistoryCall);
    yield takeEvery("django/getDCAHistoryLaunch", getDCAHistoryCall);
    yield takeEvery("django/getHistoryLaunch", getHistoryCall);
    yield takeEvery("django/postRunBotLaunch", postRunBotCall);
    yield takeEvery("django/postRunDCALaunch", postRunDCACall);
    yield takeEvery("django/postStopBotLaunch", postStopBotCall);
    yield takeEvery("django/postStopDCALaunch", postStopDCACall);
    yield takeEvery("django/postManualAveragingLaunch", postManualAveragingCall);
    yield takeEvery("django/postStopDCAPairLaunch", postStopDCAPairCall);
    yield takeEvery("django/postSellPairLaunch", postSellPairCall);
    yield takeEvery("django/postUpdatePNLLaunch", postUpdatePNLCall);
    yield takeEvery("django/postAuthentificationLaunch", postAuthentificationCall);
    yield takeEvery("django/getLogFileLaunch", getLogFileCall);
    yield takeEvery("django/getAccountInformationLaunch", getAccountInformationCall);
    yield takeEvery("django/getAccountSnapshotLaunch", getAccountSnapshotCall);

}

export default djangoSaga;