import {AggregatedAccountBalance} from "../../components/aggregatedAccountBalance/aggregatedAccountBalance";
import Grid from "@mui/material/Grid";
import TokenList from "../../components/tokenList/tokenList";
import {useMediaQuery} from "@mui/material";
import {AggregatedAccountBalanceMobile} from "../../components/aggregatedAccountBalance/aggregatedAccountBalanceMobile";
import TokenListMobile from "../../components/tokenList/tokenListMobile";


function Home() {
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
    if (isDesktop) {
    return (
        <Grid container flexDirection="column">
            <Grid item style={{paddingBottom: 5}} xs={12}>
                <AggregatedAccountBalance/>
            </Grid>
            <Grid item style={{paddingTop: 5}} xs={12}>
                <TokenList/>
            </Grid>
        </Grid>
    )
    } else {
        return (
            <Grid container flexDirection="column">
                <Grid item style={{paddingTop: 5}} xs={12}>
                    <AggregatedAccountBalanceMobile/>
                </Grid>
                <Grid item style={{paddingTop: 5}} xs={12}>
                    <TokenListMobile/>
                </Grid>
            </Grid>
        )
    }
}

export default Home

