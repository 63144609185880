import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    botConfigData: [],
    dcaConfigData: [],
    botListData: [],
    dcaListData: [],
    botGridData: [],
    dcaGridData: [],
    accountInformation: [],
    accountSnapshot: [],
    botHistoryData: undefined,
    dcaHistoryData: undefined,
    historyData:[],
    logFile:"",
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    status: 'idle',
    selectedPair: {'pair':'BTCUSDT', 'baseAsset':'BTC','quoteAsset':'USDT','exchange':'CRO', 'tickSize':0.01000000, 'grid':[]},
};

export const djangoSlice = createSlice({
    name: 'django',
    initialState,
    reducers: {
        refreshTokenAction: (state, action) => {
            state.accessToken = action.payload;
        },
        getBotConfigLaunch: state => {
            state.status = 'loading'
        },
        getBotConfigSuccess: (state, action) => {
            state.status = 'success';
            state.botConfigData = action.payload;
        },
        getBotConfigFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.botConfigData = [];
        },
        getDCAConfigLaunch: state => {
            state.status = 'loading'
        },
        getDCAConfigSuccess: (state, action) => {
            state.status = 'success';
            state.dcaConfigData = action.payload;
        },
        getDCAConfigFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.dcaConfigData = [];
        },
        getBotListLaunch: state => {
            state.status = 'loading'
        },
        getBotListSuccess: (state, action) => {
            state.status = 'success';
            state.botListData = action.payload;
        },
        getBotListFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.botListData = [];
        },
        getDCAListLaunch: state => {
            state.status = 'loading'
        },
        getDCAListSuccess: (state, action) => {
            state.status = 'success';
            state.dcaListData = action.payload;
        },
        getDCAListFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.dcaListData = [];
        },
        postBotConfigLaunch: state => {
            state.status = 'loading'
        },
        postBotConfigSuccess: (state) => {
            state.status = 'success';
        },
        postBotConfigFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        postDCAConfigLaunch: state => {
            state.status = 'loading'
        },
        postDCAConfigSuccess: (state) => {
            state.status = 'success';
        },
        postDCAConfigFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        deleteBotConfigLaunch: state => {
            state.status = 'loading'
        },
        deleteBotConfigSuccess: (state) => {
            state.status = 'success';
        },
        deleteBotConfigFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        deleteDCAConfigLaunch: state => {
            state.status = 'loading'
        },
        deleteDCAConfigSuccess: (state) => {
            state.status = 'success';
        },
        deleteDCAConfigFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        getBotGridLaunch: state => {
            state.status = 'loading'
        },
        getBotGridSuccess: (state, action) => {
            state.status = 'success';
            state.botGridData = action.payload;
        },
        getBotGridFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.botGridData = "[]";
        },
        getDCAGridLaunch: state => {
            state.status = 'loading'
        },
        getDCAGridSuccess: (state, action) => {
            state.status = 'success';
            state.dcaGridData = action.payload;
        },
        getDCAGridFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.dcaGridData = "[]";
        },
        getLogFileLaunch: state => {
            state.status = 'loading'
        },
        getLogFileSuccess: (state, action) => {
            state.status = 'success';
            state.logFile = action.payload;
        },
        getLogFileFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.logFile = "";
        },
        getBotHistoryLaunch: state => {
            state.status = 'loading'
        },
        getBotHistorySuccess: (state, action) => {
            state.status = 'success';
            state.botHistoryData = action.payload;
        },
        getBotHistoryFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.botHistoryData= [];
        },
        getDCAHistoryLaunch: state => {
            state.status = 'loading'
        },
        getDCAHistorySuccess: (state, action) => {
            state.status = 'success';
            state.dcaHistoryData = action.payload;
        },
        getDCAHistoryFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.dcaHistoryData = [];
        },
        getHistoryLaunch: state => {
            state.status = 'loading'
        },
        getHistorySuccess: (state, action) => {
            state.status = 'success';
            state.historyData = action.payload;
        },
        getHistoryFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.historyData= [];
        },
        postRunBotLaunch: state => {
            state.status = 'loading'
        },
        postRunBotSuccess: (state) => {
            state.status = 'success';
        },
        postRunBotFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        postRunDCALaunch: state => {
            state.status = 'loading'
        },
        postRunDCASuccess: (state) => {
            state.status = 'success';
        },
        postRunDCAFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        postStopBotLaunch: state => {
            state.status = 'loading'
        },
        postStopBotSuccess: (state) => {
            state.status = 'success';
        },
        postStopBotFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        postStopDCALaunch: state => {
            state.status = 'loading'
        },
        postStopDCASuccess: (state) => {
            state.status = 'success';
        },
        postStopDCAFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        postStopDCAPairLaunch: state => {
            state.status = 'loading'
        },
        postStopDCAPairSuccess: (state) => {
            state.status = 'success';
        },
        postStopDCAPairFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        postManualAveragingLaunch: state => {
            state.status = 'loading'
        },
        postManualAveragingSuccess: (state) => {
            state.status = 'success';
        },
        postManualAveragingFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        postSellPairLaunch: state => {
            state.status = 'loading'
        },
        postSellPairSuccess: (state) => {
            state.status = 'success';
        },
        postSellPairFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        copyBotConfigLaunch: state => {
            state.status = 'loading'
        },
        copyBotConfigSuccess: (state) => {
            state.status = 'success';
        },
        copyBotConfigFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        copyDCAConfigLaunch: state => {
            state.status = 'loading'
        },
        copyDCAConfigSuccess: (state) => {
            state.status = 'success';
        },
        copyDCAConfigFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        postUpdatePNLLaunch: state => {
            state.status = 'loading'
        },
        postUpdatePNLSuccess: (state) => {
            state.status = 'success';
        },
        postUpdatePNLFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
        },
        postAuthentificationLaunch: state => {
            state.status = 'loading'
        },
        postAuthentificationSuccess: (state, action) => {
            state.status = 'success';
            state.accessToken = action.payload.access;
            state.refreshToken = action.payload.refresh;
        },
        postAuthentificationFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.accessToken = null;
            state.refreshToken = null;
        },
        getAccountInformationLaunch: state => {
            state.status = 'loading'
        },
        getAccountInformationSuccess: (state, action) => {
            state.status = 'success';
            state.accountInformation = action.payload.data;
        },
        getAccountInformationFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.accountInformation = []
        },
        getAccountSnapshotLaunch: state => {
            state.status = 'loading'
        },
        getAccountSnapshotSuccess: (state, action) => {
            state.status = 'success';
            state.accountSnapshot = action.payload;
        },
        getAccountSnapshotFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.accountSnapshot = [];
        },
        saveSelectedPair: (state, action)  => {
            state.status = 'success'
            state.selectedPair = action.payload
        },
    }
});

export const {
    refreshTokenAction,
    getBotConfigLaunch,
    getBotConfigSuccess,
    getBotConfigFailure,
    getDCAConfigLaunch,
    getDCAConfigSuccess,
    getDCAConfigFailure,
    getBotListLaunch,
    getBotListSuccess,
    getBotListFailure,
    getDCAListLaunch,
    getDCAListSuccess,
    getDCAListFailure,
    getLogFileLaunch,
    getLogFileSuccess,
    getLogFileFailure,
    postBotConfigLaunch,
    postBotConfigSuccess,
    postBotConfigFailure,
    postDCAConfigLaunch,
    postDCAConfigSuccess,
    postDCAConfigFailure,
    deleteBotConfigLaunch,
    deleteBotConfigSuccess,
    deleteBotConfigFailure,
    deleteDCAConfigLaunch,
    deleteDCAConfigSuccess,
    deleteDCAConfigFailure,
    getBotGridLaunch,
    getBotGridSuccess,
    getBotGridFailure,
    getDCAGridLaunch,
    getDCAGridSuccess,
    getDCAGridFailure,
    getBotHistoryLaunch,
    getBotHistorySuccess,
    getBotHistoryFailure,
    getDCAHistoryLaunch,
    getDCAHistorySuccess,
    getDCAHistoryFailure,
    getHistoryLaunch,
    getHistorySuccess,
    getHistoryFailure,
    postRunBotLaunch,
    postRunBotSuccess,
    postRunBotFailure,
    postRunDCALaunch,
    postRunDCASuccess,
    postRunDCAFailure,
    postStopBotLaunch,
    postStopBotSuccess,
    postStopBotFailure,
    postStopDCALaunch,
    postStopDCASuccess,
    postStopDCAFailure,
    postStopDCAPairLaunch,
    postStopDCAPairSuccess,
    postStopDCAPairFailure,
    postManualAveragingLaunch,
    postManualAveragingSuccess,
    postManualAveragingFailure,
    postSellPairLaunch,
    postSellPairSuccess,
    postSellPairFailure,
    postUpdatePNLLaunch,
    postUpdatePNLSuccess,
    postUpdatePNLFailure,
    copyBotConfigLaunch,
    copyBotConfigSuccess,
    copyBotConfigFailure,
    copyDCAConfigLaunch,
    copyDCAConfigSuccess,
    copyDCAConfigFailure,
    postAuthentificationLaunch,
    postAuthentificationSuccess,
    postAuthentificationFailure,
    getAccountInformationLaunch,
    getAccountInformationSuccess,
    getAccountInformationFailure,
    getAccountSnapshotLaunch,
    getAccountSnapshotSuccess,
    getAccountSnapshotFailure,
    saveSelectedPair,
} = djangoSlice.actions;

export default djangoSlice.reducer;
