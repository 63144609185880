import * as React from 'react';
import Box from "@mui/material/Box";
import useStyles from "./dcaPairChartModal.styles";
import {DcaChart} from "../dcaChart/dcaChart";


const DcaPairChartModal = ({bot_id, pair, base_asset}) => {
    const classes = useStyles();

    return (
        <Box className={classes.modal} sx={{width: '80%', height: '80%'}}>
            <DcaChart bot_id={bot_id} pair={pair} base_asset={base_asset} modal_type="chart"/>
        </Box>
    )
        ;
}

export default DcaPairChartModal;