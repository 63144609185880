import * as React from 'react';
import PropTypes from 'prop-types'
import moment from 'moment'

import {
    Line,
    LineChart,
    ResponsiveContainer, Tooltip,
    XAxis,
    YAxis,
} from 'recharts'


const AccountLineChart = ({chartData}) => {
    const minYValue = Math.min(...chartData.map(d => d.value))
    const yDomain = [minYValue * 0.99, 'auto']
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const value = Number(payload[0].value).toFixed(2);
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${value} $`}</p>
                </div>
            );
        }
        return null;
    };
    return (
        <ResponsiveContainer width='95%' height={205}>
            <LineChart data={chartData} syncId="" margin={{bottom: 30, left: 10, right: 10}}>
                <XAxis
                    dataKey='time'
                    domain={['dataMin-86400000', 'dataMax + 86400000']}
                    name='Time'
                    tickFormatter={timeStr => moment(timeStr).format('DD-MM')}
                    type='number'
                    scale='time'
                    allowDataOverflow={true}
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                />
                <YAxis
                    tickFormatter={(value) => value.toFixed(0)}
                    domain={yDomain}
                    allowDecimals={true}
                />
                <Tooltip content={<CustomTooltip/>}/>
                <Line
                    dataKey='value'
                    stroke="#2ec5d3"
                    type="monotone"
                >
                </Line>
            </LineChart>
        </ResponsiveContainer>
    )
}

AccountLineChart.propTypes = {
    chartData: PropTypes.arrayOf(
        PropTypes.shape({
            time: PropTypes.number,
            value: PropTypes.number
        })
    ).isRequired
}

export default AccountLineChart
