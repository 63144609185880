import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    coinsData: [],
    tickerData: [],
    klines: [],
    status: 'idle',
    exchangeInfo: [],
    exchangeInfoPair:[],
};

export const binanceSlice = createSlice({
    name: 'binance',
    initialState,
    reducers: {
        binanceGet24hTickerLaunch: state => {
            state.status = 'loading'
        },
        binanceGet24hTickerSuccess: (state, action) => {
            state.coinsData = action.payload.data
        },
        binanceGet24hTickerFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.coinsData = []
        },
        binanceGetTickerLaunch: state => {
            state.status = 'loading'
        },
        binanceGetTickerSuccess: (state, action) => {
            state.tickerData = action.payload.data
        },
        binanceGetTickerFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.tickerData = []
        },
        binanceGetKlinesLaunch: state => {
            state.status = 'loading'
        },
        binanceGetKlinesSuccess: (state, action) => {
            state.status = 'success';
            state.klines = action.payload.data
        },
        binanceGetKlinesFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.klines = []
        },
        binanceGetExchangeInfoLaunch: state => {
            state.status = 'loading'
        },
        binanceGetExchangeInfoSuccess: (state, action) => {
            state.status = 'success';
            state.exchangeInfo = action.payload.data
        },
        binanceGetExchangeInfoFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.exchangeInfo = []
        },
        binanceGetExchangeInfoPairLaunch: state => {
            state.status = 'loading'
        },
        binanceGetExchangeInfoPairSuccess: (state, action) => {
            state.status = 'success';
            state.exchangeInfoPair = action.payload.data
        },
        binanceGetExchangeInfoPairFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.exchangeInfoPair = []
        },
    },
});

export const {
    binanceGet24hTickerLaunch,
    binanceGet24hTickerSuccess,
    binanceGet24hTickerFailure,
    binanceGetTickerLaunch,
    binanceGetTickerSuccess,
    binanceGetTickerFailure,
    binanceGetKlinesLaunch,
    binanceGetKlinesSuccess,
    binanceGetKlinesFailure,
    binanceGetExchangeInfoLaunch,
    binanceGetExchangeInfoFailure,
    binanceGetExchangeInfoSuccess,
    binanceGetExchangeInfoPairLaunch,
    binanceGetExchangeInfoPairFailure,
    binanceGetExchangeInfoPairSuccess,
} = binanceSlice.actions;

export default binanceSlice.reducer;
