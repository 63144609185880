import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {visuallyHidden} from '@mui/utils';
import {useSelector} from "react-redux";
import useStyles from "./dcaBotListHistory.styles";
import {green, grey, orange, red} from "@mui/material/colors";
import {Tooltip} from "@mui/material";
import {DcaControlPanel} from "../dcaControlPanel/dcaControlPanel";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'Bot ID & Name',
    }, {
        id: 'cycles',
        numeric: false,
        disablePadding: false,
        label: 'Deals',
    },
    {
        id: 'investment_amount',
        numeric: false,
        disablePadding: false,
        label: 'Investment amount',
    },
    {
        id: 'profit',
        numeric: false,
        disablePadding: false,
        label: 'Bot profit',
    },
    {
        id: 'avg_daily',
        numeric: false,
        disablePadding: false,
        label: 'Avg. daily',
    }, {
        id: 'creation_date',
        numeric: false,
        disablePadding: false,
        label: 'Creation date',
    },
    {
        id: 'pair_m2m',
        numeric: false,
        disablePadding: false,
        label: 'Pairs',
    }, {
        id: 'bot_status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    }, {
        id: 'control_panel',
        numeric: false,
        disablePadding: false,
        label: '',
    },
];

function EnhancedTableHead(props) {
    const {order, orderBy, onRequestSort} = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};


const DCABotListHistory = () => {
    const classes = useStyles();
    const dcaList = (useSelector((state) => state.django.dcaListData)).filter(pilot => pilot.bot_status === "DEACTIVATED");
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };


    const getTrendingColor = (priceChangePercent) => {
        if (priceChangePercent > 0)
            return green[400]
        else if (priceChangePercent < 0)
            return red[400]
        return ""
    }

    const getStatusColor = (status, pid_status) => {
        if (pid_status === "NOT RUNNING")
            return red[500]
        else if (status === "ACTIVE")
            return green[400]
        else if (status === "RANGE")
            return orange[500]
        else if (status === "DEACTIVATED")
            return grey[500]
        else
            return ""
    }

    const displayPairs = (pairs) => {
        const visiblePairs = pairs.slice(0, 3);
        const hiddenPairs = pairs.slice(3);
        return (
            <>
                {visiblePairs}
                {hiddenPairs.length > 0 && (
                    <Tooltip title={<Box>{hiddenPairs}</Box>} arrow>
                        <Typography className={classes.heading} component="span">
                            +{hiddenPairs.length} more
                        </Typography>
                    </Tooltip>
                )}
            </>
        );
    };


    return (
        <div>
            <TableContainer>
                <Table
                    sx={{minWidth: 750}}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={dcaList.length}
                    />
                    <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                        {stableSort(dcaList, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                const pairs = row.pairs_m2m.map((pair) => (
                                    <div key={pair.pair}>
                                        <img
                                            src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${pair.pair.slice(
                                                0,
                                                pair.pair.length - row.profit_currency.length
                                            ).toLowerCase()}.svg`}
                                            className={classes.icon}
                                            alt="token_logo"
                                        />
                                        {pair.pair}
                                    </div>
                                ));
                                return (
                                    <React.Fragment key={row.id}>
                                        <TableRow key={row.id}>
                                            <TableCell align="left">
                                                {row.id + " - " + row.name}
                                            </TableCell>
                                            <TableCell
                                                align="left">{row.cycles !== null ? row.cycles : 0}</TableCell>
                                            <TableCell
                                                align="left">{(row.investment_amount !== null ? parseFloat(row.investment_amount).toFixed(2) : parseFloat("0.00").toFixed(2)) + " " + row.profit_currency}</TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body1"
                                                            color={getTrendingColor(parseFloat(row.pnl) / row.investment_amount * 100)}>{row.pnl !== null ? (parseFloat(row.pnl) / row.investment_amount * 100).toFixed(2) : parseFloat("0.00").toFixed(2)}%</Typography>
                                                <Typography
                                                    variant="body2">{(row.pnl !== null ? parseFloat(row.pnl).toFixed(2) : parseFloat("0.00").toFixed(2)) + " " + row.profit_currency}</Typography>
                                            </TableCell>
                                            <TableCell
                                                align="left">
                                                <Typography variant="body1"
                                                            color={getTrendingColor(parseFloat(row.pnl) / row.investment_amount * 100)}>{(((row.pnl / ((new Date().getTime() - new Date(row.creation_date).getTime()) / (1000 * 3600 * 24))) / row.investment_amount * 100).toFixed(2))}%</Typography>
                                                <Typography
                                                    variant="body2">{((row.pnl / ((new Date().getTime() - new Date(row.creation_date).getTime()) / (1000 * 3600 * 24))).toFixed(2)) + " " + row.profit_currency}</Typography>
                                            </TableCell>
                                            <TableCell
                                                align="left">{row.creation_date !== null ? (new Date(row.creation_date)).toLocaleString() : ""}</TableCell>
                                            <TableCell align="left">{displayPairs(pairs)}</TableCell>
                                            <TableCell align="left"><Typography variant="body2"
                                                                                color={getStatusColor(row.bot_status, row.pid_status)}>{row.bot_status}</Typography></TableCell>
                                            <TableCell align="right">
                                                <DcaControlPanel bot_status={row.bot_status} bot_id={row.id}
                                                                 pairs_m2m={row.pairs_m2m}/>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={dcaList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense}/>}
                label="Dense padding"
            />
        </div>
    );
}

export default DCABotListHistory;