import {createChart, ColorType} from 'lightweight-charts';
import React, {useEffect, useRef} from 'react';
import {binanceGetKlinesLaunch} from "../../features/binance/binanceSlice";
import {useDispatch, useSelector} from "react-redux";
import {blueGrey, green, red} from "@mui/material/colors";
import * as LightweightCharts from "lightweight-charts";
import {countDecimals} from "../../utils/utils";
import FormControl from "@mui/material/FormControl";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {getBotGridLaunch} from "../../features/django/djangoSlice";
import Grid from "@mui/material/Grid";
import {cryptoComGetKlinesLaunch} from "../../features/cryptoCom/cryptoComSlice";
import {bybitGetKlinesLaunch} from "../../features/bybit/bybitSlice";

export const Chart = () => {
    const dispatch = useDispatch();
    const chartContainerRef = useRef();
    const selectedPair = useSelector((state) => state.django.selectedPair);
    const klines = useSelector((state) =>
        selectedPair.exchange === 'CRO' ? state.cryptoCom.klines : selectedPair.exchange === 'BYT' ? state.bybit.klines :state.binance.klines
    );
    const botGridData = useSelector((state) => state.django.botGridData);
    const [interval, setInterval] = React.useState("1d");

    const handleChange = (event) => {
        setInterval(event.target.value);
    };

    useEffect(() => {
        if (selectedPair.exchange === "CRO"){
            dispatch(cryptoComGetKlinesLaunch({symbol: selectedPair.baseAsset+"_"+selectedPair.quoteAsset, interval: interval}))
        }
        else if (selectedPair.exchange === "BYT"){
            dispatch(bybitGetKlinesLaunch({symbol: selectedPair.pair, interval: interval}))
        }
        else{
            dispatch(binanceGetKlinesLaunch({symbol: selectedPair.pair, interval: interval, limit: 1000}))
        }
        if (typeof (selectedPair.bot_id) !== "undefined") {
            dispatch(getBotGridLaunch({botId: selectedPair.bot_id}))
        }
    }, [dispatch, selectedPair, interval])

    useEffect(
        () => {
            const handleResize = () => {
                chart.applyOptions({
                    width: chartContainerRef.current.clientWidth,
                    height: chartContainerRef.current.clientHeight
                });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: {type: ColorType.Solid, color: "#24344d"}, textColor: "white",
                },
                grid: {
                    vertLines: {color: blueGrey[800]},
                    horzLines: {color: blueGrey[800]},
                },
                width: chartContainerRef.current.clientWidth, height: chartContainerRef.current.clientHeight,
            });
            // chart.timeScale().fitContent();
            chart.timeScale().applyOptions({
                    timeVisible: true,
                    borderColor: blueGrey[800],
                    barSpacing: 5,
                }
            )

            // Customizing the Crosshair
            chart.applyOptions({
                crosshair: {
                    // Change mode from default 'magnet' to 'normal'.
                    // Allows the crosshair to move freely without snapping to datapoints
                    mode: LightweightCharts.CrosshairMode.Normal,

                    // Vertical crosshair line (showing Date in Label)
                    vertLine: {
                        color: "#C3BCDB44"
                    },

                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        color: "#C3BCDB44",
                        labelBackgroundColor: "#C3BCDB44",
                    },
                },
            });

            const candlestickSeries = chart.addCandlestickSeries({
                upColor: green[400],
                downColor: red[400],
                borderVisible: false,
                wickUpColor: green[400],
                wickDownColor: red[400]
            });

            candlestickSeries.setData(klines);

            candlestickSeries.applyOptions({
                priceFormat: {
                    type: 'price',
                    precision: countDecimals(selectedPair.tickSize),
                    minMove: selectedPair.tickSize,
                },
            });

            if (typeof (botGridData[0]) !== "undefined") {
                for (let i in botGridData) {
                    const myPriceLine = {
                        price: botGridData[i].order_price,
                        color: botGridData[i].line_active === false ? blueGrey[400] : botGridData[i].order_side === "SELL" ? red[400] : green[400],
                        lineWidth: 1,
                        lineStyle: botGridData[i].line_active === false ? 1 : 0,
                        axisLabelVisible: true,
                        // title: 'my label',
                    }
                    candlestickSeries.createPriceLine(myPriceLine);
                    window.addEventListener('resize', handleResize);
                }
            } else if (typeof (selectedPair.grid[0]) !== "undefined") {
                for (let i in selectedPair.grid) {
                    const myPriceLine = {
                        price: selectedPair.grid[i].order_price,
                        color: blueGrey[400],
                        lineWidth: 1,
                        lineStyle: 0,
                        axisLabelVisible: true,
                        // title: 'my label',
                    }
                    candlestickSeries.createPriceLine(myPriceLine);
                    window.addEventListener('resize', handleResize);
                }
            }

            return () => {
                window.removeEventListener('resize', handleResize);
                chart.remove();
            };
        },
        [klines, selectedPair]
    );
    return (
        <Grid item id="chart"
              ref={chartContainerRef}
              style={{position: "relative", height: "100%", width: "100%"}}>
            <Grid item className="lw-attribution"
                  style={{position: "absolute", zIndex: "3", paddingTop: 17, paddingLeft: 100, paddingRight: 10}}>
                · {selectedPair.pair}
            </Grid>
            <FormControl sx={{m: 1, minWidth: 120}} size="small"
                         style={{
                             position: "absolute",
                             zIndex: "3",
                             paddingTop: 0,
                             paddingLeft: 10,
                             paddingRight: 30
                         }}>
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={interval}
                    label="Interval"
                    onChange={handleChange}
                >
                    <MenuItem value="5m">5m</MenuItem>
                    <MenuItem value="15m">15m</MenuItem>
                    <MenuItem value="1h">1h</MenuItem>
                    <MenuItem value="4h">4h</MenuItem>
                    <MenuItem value="1d">1d</MenuItem>
                    <MenuItem value="1w">1w</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );
}
