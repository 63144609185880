import * as React from 'react';
import {useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";
import {StyledButton} from "../../components/button/styledButton";
import {StyledTextField} from "../../components/textField/styledTextField";
import {postAuthentificationLaunch} from "../../features/django/djangoSlice";
import {Paper} from "@mui/material";
import useStyles from "./login.styles";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import {useFormik} from 'formik';
import * as yup from 'yup';

const Login = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const validationSchema = yup.object({
        username: yup
            .string('Enter your username')
            .required('Username is required'),
        password: yup
            .string('Enter your password')
            .min(8, 'Password should be of minimum 8 characters length')
            .required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(postAuthentificationLaunch({
                username: values.username.toLowerCase(),
                password: values.password,
                navigate
            }))
        }
    })

    return (
        <Paper className={classes.paper}>
            <Grid container direction={"column"} spacing={2} alignItems={"center"}>
                <Grid item>
                    <Typography level="h1" component="h1">Login !</Typography>
                </Grid>
                <Grid item>
                    <Typography level="body2">Sign in to continue</Typography>
                </Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid item>
                        <StyledTextField
                            id="username"
                            variant="outlined"
                            name="username"
                            type="text"
                            placeholder="username"
                            label="Username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}/>
                    </Grid>
                    <Grid item>
                        <StyledTextField
                            id="password"
                            variant="outlined"
                            name="password"
                            type="password"
                            placeholder="password"
                            label="Password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}/>
                    </Grid>
                    <Grid item>
                        <StyledButton
                            variant="contained"
                            label="Sign in"
                            type="submit"
                        />
                    </Grid>
                </form>
            </Grid>
        </Paper>
    );
}

export default Login;