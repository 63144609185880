import React from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary, CardContent,
    Container,
    Grid,
    Typography,
} from '@mui/material';
import useStyles from './dcaPairListActive.styles';
import {useSelector} from "react-redux";
import {green, grey, orange, red} from "@mui/material/colors";
import {DcaPairControlPanel} from "../dcaPairControlPanel/dcaPairControlPanel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TablePagination from "@mui/material/TablePagination";

const DCAPairListActiveMobile = () => {
    const classes = useStyles();
    const tickerData = useSelector((state) => state.binance.tickerData);
    const dcaList = (useSelector((state) => state.django.dcaListData)).filter(pilot => pilot.bot_status !== "DEACTIVATED");
    const dcaListActive = (useSelector((state) => state.django.dcaListData)).filter(pilot => pilot.bot_status === "ACTIVE");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const getTrendingColor = (priceChangePercent) => {
        if (priceChangePercent > 0)
            return green[400]
        else if (priceChangePercent < 0)
            return red[400]
        return ""
    }

    const getStatusColor = (status, pid_status) => {
        if (pid_status === "NOT RUNNING")
            return red[500]
        else if (status === "ACTIVE")
            return green[400]
        else if (status === "RANGE")
            return orange[500]
        else if (status === "DEACTIVATED")
            return grey[500]
        else
            return ""
    }

    const getAveragingColor = (numberOfAveragingFilledOrders, averagingOrderQuantity) => {
        if (numberOfAveragingFilledOrders === averagingOrderQuantity)
            return red[400]
        else
            return ""
    }


    const activePairs = dcaListActive.flatMap((dcaConfig) => {
        const {active_pairs, dca_grid, pairs_m2m} = dcaConfig;

        return active_pairs.map((activePair) => {
            const filteredDcaGrid = dca_grid.filter((gridItem) => gridItem.pair === activePair);
            const pairData = pairs_m2m.find((pairItem) => pairItem.pair === activePair);

            return {
                pair: activePair,
                botId: dcaConfig ? dcaConfig.id : null,
                botStatus: dcaConfig ? dcaConfig.bot_status : null,
                pidStatus: dcaConfig ? dcaConfig.pid_status : null,
                averagingOrderQuantity: dcaConfig ? dcaConfig.averaging_order_quantity : null,
                baseAsset: pairData ? pairData.base_asset : null,
                quoteAsset: pairData ? pairData.quote_asset : null,
                tickSize: pairData ? pairData.tick_size : null,
                lotSize: pairData ? pairData.lot_size : null,
                minNotional: pairData ? pairData.min_notional : null,
                dca_grid: filteredDcaGrid
            };
        });
    });

    return (
        <div>
            <Container maxWidth="false">
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-start">
                            <Typography color="primary" sx={{textAlign: 'left', marginRight: '16px'}}>Pair
                                ({dcaListActive.length}/{dcaList.length})</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-end" direction="row">
                            <Grid item>
                                <Typography color="primary" variant="body2"
                                            sx={{textAlign: 'right', marginRight: '20px'}}>
                                    Value
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography color="primary" variant="body2"
                                            sx={{textAlign: 'right', marginRight: '44px'}}>Profit</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {activePairs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((activePair, index) => {
                    const baseLine = activePair.dca_grid.find(
                        (line) => line.line_type === 'BASE'
                    );
                    const takeProfitLine = activePair.dca_grid.find(
                        (line) => line.line_type === 'TAKE_PROFIT'
                    );
                    const currentPrice = tickerData.find((pair) => pair.symbol === activePair.pair) ? tickerData.find((pair) => pair.symbol === activePair.pair).price : 'N/A';
                    const currentValue = takeProfitLine ? (takeProfitLine.order_original_qty * currentPrice).toFixed(2) : 0;
                    const baseAndAveragingFilledOrders = activePair.dca_grid.filter(
                        (line) => (line.line_type === 'BASE' || line.line_type === 'AVERAGING') && line.order_status === 'FILLED'
                    );
                    const totalOrderQuoteQty = baseAndAveragingFilledOrders.reduce((sum, order) => sum + order.order_quote_qty, 0).toFixed(2);
                    const currentProfit = (currentValue - totalOrderQuoteQty).toFixed(2)
                    const averagingFilledOrders = activePair.dca_grid.filter(
                        (line) => line.line_type === 'AVERAGING' && line.order_status === 'FILLED'
                    );
                    const numberOfAveragingFilledOrders = averagingFilledOrders.length;
                    const baseOrderCreatedTime = baseLine ? baseLine.order_created_time : 0;
                    const currentTime = new Date();
                    const durationMs = currentTime - new Date(baseOrderCreatedTime);
                    const durationHours = durationMs / (1000 * 60 * 60);
                    const days = Math.floor(durationHours / 24);
                    const hours = Math.floor(durationHours % 24);
                    const durationFormatted = `${days}D/${hours}H`;
                    return (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={activePair.pair}>
                                <Grid container justifyContent="flex-start" alignItems="center">
                                    <img
                                        src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${activePair.baseAsset.toLowerCase()}.svg`}
                                        className={classes.icon}
                                        alt="token_logo"
                                    />
                                    <Typography sx={{width: '33%', flexShrink: 0}} color={getStatusColor(activePair.botStatus, activePair.pidStatus)}> {activePair.pair}</Typography>
                                </Grid>
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <Grid item>
                                        <Typography variant="body2" sx={{
                                            textAlign: 'right',
                                            marginRight: '16px'
                                        }}>{currentValue}$</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" sx={{textAlign: 'right', marginRight: '16px'}}
                                                    color={getTrendingColor(currentProfit)}>{(currentProfit / totalOrderQuoteQty * 100).toFixed(2)} %</Typography>
                                        <Typography
                                            variant="body2">{currentProfit}$</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <CardContent className={classes.kpi}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Bot ID:&nbsp;
                                            </Typography>
                                            <Typography variant="body1" sx={{ display: 'inline' }} >
                                                {activePair.botId}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Status:&nbsp;
                                            </Typography>
                                            <Typography variant="body1" sx={{ display: 'inline' }} color={getStatusColor(activePair.botStatus, activePair.pidStatus)}>
                                                {activePair.botStatus}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Pair ID:&nbsp;
                                            </Typography>
                                            <Typography variant="body1" sx={{ display: 'inline' }} >
                                                {baseLine ? baseLine.active_pair_number : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Averaging:&nbsp;
                                            </Typography>
                                            <Typography variant="body1" sx={{ display: 'inline' }} color={getAveragingColor(numberOfAveragingFilledOrders, activePair.averagingOrderQuantity)}>
                                                {numberOfAveragingFilledOrders}/{activePair.averagingOrderQuantity}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Init:&nbsp;
                                            </Typography>
                                            <Typography variant="body1" sx={{ display: 'inline' }}>
                                                {totalOrderQuoteQty}$
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Duration:&nbsp;
                                            </Typography>
                                            <Typography variant="body1" sx={{ display: 'inline' }}>
                                                {durationFormatted}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                        <Grid container alignItems="flex-end" justifyContent="center">
                                            <Grid item sx={{ mt: 2 }}>
                                            <DcaPairControlPanel bot_id={activePair.botId} pair={activePair.pair} base_asset={activePair.baseAsset} quote_asset={activePair.quoteAsset} current_position_size={takeProfitLine? takeProfitLine.order_original_qty:0}/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={activePairs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )
}


export default DCAPairListActiveMobile;
