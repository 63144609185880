import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    paper: {
        // paddingLeft: 30
    },
    kpi: {
        padding: ' 10px 15px 10px 15px',
        borderColor: "#C3BCDB44",
        borderStyle: "solid",
        borderWidth:"1px",
        borderRadius: "3px"
    },
    chart: {
        padding: ' 15px 0px 0px 0px',
        borderColor: "#C3BCDB44",
        borderStyle: "solid",
        borderWidth:"1px",
        borderRadius: "3px"
    },
});

export default useStyles;