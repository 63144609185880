import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    tickerData: [],
    klines: [],
    status: 'idle',
    exchangeInfo: [],
    exchangeInfoPair:[]
};

export const bybitSlice = createSlice({
    name: 'bybit',
    initialState,
    reducers: {
        bybitGetTickerLaunch: state => {
            state.status = 'loading'
        },
        bybitGetTickerSuccess: (state, action) => {
            state.tickerData = action.payload.data
        },
        bybitGetTickerFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.tickerData = []
        },
        bybitGetKlinesLaunch: state => {
            state.status = 'loading'
        },
        bybitGetKlinesSuccess: (state, action) => {
            state.status = 'success';
            state.klines = action.payload.data
        },
        bybitGetKlinesFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.klines = []
        },
        bybitGetExchangeInfoLaunch: state => {
            state.status = 'loading'
        },
        bybitGetExchangeInfoSuccess: (state, action) => {
            state.status = 'success';
            state.exchangeInfo = action.payload.data
        },
        bybitGetExchangeInfoFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.exchangeInfo = []
        },
        bybitGetExchangeInfoPairLaunch: state => {
            state.status = 'loading'
        },
        bybitGetExchangeInfoPairSuccess: (state, action) => {
            state.status = 'success';
            state.exchangeInfoPair = action.payload.data
        },
        bybitGetExchangeInfoPairFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.exchangeInfoPair = []
        },
    },
});

export const {
    bybitGetTickerLaunch,
    bybitGetTickerSuccess,
    bybitGetTickerFailure,
    bybitGetKlinesLaunch,
    bybitGetKlinesSuccess,
    bybitGetKlinesFailure,
    bybitGetExchangeInfoLaunch,
    bybitGetExchangeInfoFailure,
    bybitGetExchangeInfoSuccess,
    bybitGetExchangeInfoPairLaunch,
    bybitGetExchangeInfoPairFailure,
    bybitGetExchangeInfoPairSuccess,
} = bybitSlice.actions;

export default bybitSlice.reducer;
