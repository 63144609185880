import BotConfiguration from "../../components/botConfiguration/botConfiguration";

import Grid from "@mui/material/Grid";
import {Chart} from "../../components/chart/chart";
import {BotList} from "../../components/botList/botList";
import {useMediaQuery} from "@mui/material";


function GridBot() {
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
    if (isDesktop) {
        return (
            <Grid container flexDirection="column">
                <Grid container flexDirection="row">
                    <Grid item md={2}
                          style={{paddingRight: 5, paddingBottom: 5}}>
                        <BotConfiguration/>
                    </Grid>
                    <Grid item md={10}
                          style={{paddingLeft: 5, paddingBottom: 5}}>
                        <Chart/>
                    </Grid>
                </Grid>
                <Grid item style={{paddingTop: 5}}>
                    <BotList/>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container flexDirection="column">
                <Grid container flexDirection="row">
                    <Grid item style={{paddingTop: 5, width:"100%"}}>
                        <BotList/>
                    </Grid>
                    <Grid item xs={12}
                          style={{ paddingTop: 5, height: "400px"}}>
                        <Chart/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

}

export default GridBot
