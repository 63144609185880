import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  statusCode: 0,
  message: "",
  status: undefined,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    success: (state, action) => {
      state.status = "success"
      state.message = action.payload.message
      state.codeError = 200
      state.isOpen = true
    },
    failure: (state, action) => {
      state.status = "error"
      state.message = action.payload.message
      state.codeError = action.payload.codeError
      state.isOpen = true
    },
    closeSnackBar: (state) => {
      state.isOpen = false
    },
  }
});

export const { loading, success, failure, closeSnackBar } = appSlice.actions;

export default appSlice.reducer;
