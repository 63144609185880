import {put, takeEvery} from 'redux-saga/effects'
import axios from "axios";
import {
    bybitGetKlinesFailure,
    bybitGetKlinesSuccess,
    bybitGetExchangeInfoFailure,
    bybitGetExchangeInfoSuccess,
    bybitGetTickerFailure,
    bybitGetTickerSuccess,
    bybitGetExchangeInfoPairFailure,
    bybitGetExchangeInfoPairSuccess
} from "./bybitSlice";
import {failure} from "../../app/appSlice";
import privateApiClient from "../../services/privateApiClient";


function* getTickerCall() {
    try {
        const dcaList = yield privateApiClient.get(`/dca-config/?include_grid=true&format=json`,);
        const dcaListActive = dcaList.data.filter(pilot => pilot.bot_status === "ACTIVE");
        const uniqueActivePairs = dcaListActive.reduce((acc, item) => acc.concat(item.active_pairs), []);
        const uniqueActivePairsStr = '["' + uniqueActivePairs.join('","') + '"]';
        if (uniqueActivePairsStr !=='[""]') {
            const response = yield axios.get(`https://api.bybit.com/api/v3/ticker/price?symbols=${uniqueActivePairsStr}`);
            if (response.status !== 200) {
                yield put(failure({message: "Get ticker fail"}));
                yield put(bybitGetTickerFailure({error: response.toString()}));
            }
            yield put(bybitGetTickerSuccess({data: response.data}));
        }
    } catch (error) {
        yield put(failure({message: "Get ticker fail"}));
        yield put(bybitGetTickerFailure({error: error.toString()}));
    }
}

function* getKlinesCall(action) {
    try {
        const {symbol, interval} = action.payload;
        let formated_interval
        switch (interval) {
            case '5m':
                formated_interval = '5';
                break;
            case '15m':
                formated_interval = '15';
                break;
            case '1h':
                formated_interval = '60';
                break;
            case '4h':
                formated_interval = '240';
                break;
            case '1d':
                formated_interval = 'D';
                break;
            case '1w':
                formated_interval = 'W';
                break;
            default:
                formated_interval = "D";
                break;
        }

        const response = yield axios.get(`https://api.bybit.com//v5/market/kline?category=spot&symbol=${symbol}&interval=${formated_interval}&limit=300`);

        if (response.status !== 200) {
            yield put(failure({message: "Get klines fail"}));
            yield put(bybitGetKlinesFailure({error: response.toString()}));
        }
        let klines = []
        for (let i in response.data.result.list) {
            klines.push({
                "open": Number(response.data.result.list[i][1]),
                "high": Number(response.data.result.list[i][2]),
                "low": Number(response.data.result.list[i][3]),
                "close": Number(response.data.result.list[i][4]),
                "time": response.data.result.list[i][0] / 1000
            })
        }
        klines = klines.sort((a, b) => a.time - b.time);
        yield put(bybitGetKlinesSuccess({data: klines}));
    } catch (error) {
        yield put(failure({message: "Get klines fail"}));
        yield put(bybitGetKlinesFailure({error: error.toString()}));
    }
}

function* getExchangeInfoCall() {
    try {
        const compatibleQuoteAsset = ["BTC", "USDT"];
        const response = yield axios.get('https://api.bybit.com/v5/market/instruments-info?category=spot');

        let listAssets = [];

        if (response.status !== 200) {
            yield put(failure({ message: "Get instruments fail" }));
            yield put(bybitGetExchangeInfoFailure({ error: response.toString() }));
        }

        for (let i = 0; i < response.data.result.list.length; i++) {
            const instrument = response.data.result.list[i];
            const quoteAsset = response.data.result.list[i].quoteCoin;
            const baseAsset = response.data.result.list[i].baseCoin;
            const symbol = response.data.result.list[i].symbol;
            const tickSize = response.data.result.list[i].priceFilter.tickSize;
            const minPrice = response.data.result.list[i].priceFilter.tickSize;
            const maxPrice = 999999999.00;
            const minNotional = 10.00;

            if (compatibleQuoteAsset.includes(quoteAsset)) {
                const { instrument_name, quote_currency, base_currency, price_tick_size, min_price, max_price, status, ...rest } = instrument;
                const exchange = "BYT"
                const uppercaseStatus = status.toUpperCase();
                listAssets.push({ symbol, exchange, quoteAsset, baseAsset, tickSize, minPrice, maxPrice, minNotional, status: uppercaseStatus, ...rest });
            }
        }

        yield put(bybitGetExchangeInfoSuccess({ data: listAssets }));
    } catch (error) {
        yield put(failure({ message: "Get instruments fail" }));
        yield put(bybitGetExchangeInfoFailure({ error: error.toString() }));
    }
}



function* getExchangeInfoPairCall(action) {
    try {
        const {pair} = action.payload;
        const response = yield axios.get(`https://api.bybit.com/api/v3/exchangeInfo?symbol=${pair}`);

        if (response.status !== 200) {
            yield put(failure({message: "Get exchangeInfo pair fail"}));
            yield put(bybitGetExchangeInfoPairFailure({error: response.toString()}));
        }

        yield put(bybitGetExchangeInfoPairSuccess({data: response.data.symbols[0]}));
    } catch (error) {
        yield put(failure({message: "Get exchangeInfo pair fail"}));
        yield put(bybitGetExchangeInfoPairFailure({error: error.toString()}));
    }
}

function* bybitSaga() {
    yield takeEvery("bybit/bybitGetTickerLaunch", getTickerCall);
    yield takeEvery("bybit/bybitGetKlinesLaunch", getKlinesCall);
    yield takeEvery("bybit/bybitGetExchangeInfoLaunch", getExchangeInfoCall);
    yield takeEvery("bybit/bybitGetExchangeInfoPairLaunch", getExchangeInfoPairCall);
}

export default bybitSaga;