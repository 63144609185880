import React from 'react';
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import StopIcon from '@mui/icons-material/Stop';
import {Modal, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import useStyles from "./dcaPairControlPanel.styles";
import ManualAveragingModal from "../manualAveragingModal/manualAveragingModal";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import DcaPairChartModal from "../dcaPairChartModal/dcaPairChartModal";
import StopPairModal from "../stopPairModal/stopPairModal";

export const DcaPairControlPanel = ({bot_id, pair, base_asset, quote_asset, current_position_size}) => {
    const classes = useStyles();
    const [anchorChart, setAnchorChart] = React.useState(null);
    const openChart = Boolean(anchorChart);
    const [anchorManualAveraging, setAnchorManualAveraging] = React.useState(null);
    const openManualAveraging = Boolean(anchorManualAveraging);
    const [anchorStop, setAnchorStop] = React.useState(null);
    const openStop = Boolean(anchorStop);
    const [isModalShown, setIsModalShown] = React.useState(false);
    const [isChartModal, setIsChartModal] = React.useState(false);
    const [isManualAveragingModal, setIsManualAveragingModal] = React.useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handlePopoverOpenChart = (event) => {
        setAnchorChart(event.currentTarget);
    };
    const handlePopoverCloseChart = () => {
        setAnchorChart(null);
    };
    const handlePopoverOpenManualAveraging = (event) => {
        setAnchorManualAveraging(event.currentTarget);
    };
    const handlePopoverCloseManualAveraging = () => {
        setAnchorManualAveraging(null);
    };

    const handlePopoverOpenStop = (event) => {
        setAnchorStop(event.currentTarget);
    };
    const handlePopoverCloseStop = () => {
        setAnchorStop(null);
    };

    const handleModalClose = () => setIsModalShown(false);

    return (
        <div>
            <Modal
                open={isModalShown}
                onClose={handleModalClose}
                aria-labelledby="dcaPairStatisticsModal"
                aria-describedby="dcaPairStatisticsModalDescription"
            >
                <React.Fragment>
                    {isChartModal === true ? <DcaPairChartModal bot_id={bot_id} pair={pair} base_asset={base_asset}/> : isManualAveragingModal === true ?
                            <ManualAveragingModal bot_id={bot_id} pair={pair} base_asset={base_asset} current_position_size={current_position_size} quote_asset={quote_asset} handleModalClose={handleModalClose}/> :
                                <StopPairModal bot_id={bot_id} pair={pair} handleModalClose={handleModalClose}/>
                    }
                </React.Fragment>
            </Modal>
            <IconButton aria-label="bar-chart" className={classes.icon} onClick={() => {
                setIsChartModal(true)
                setIsManualAveragingModal(false)
                setIsModalShown(current => !current);
            }
            }>
                <CandlestickChartIcon
                    aria-owns={openChart ? 'mouse-over-popover-chart' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpenChart}
                    onMouseLeave={handlePopoverCloseChart}
                />
            </IconButton>
            {!isMobile && (<Popover
                id="mouse-over-popover-chart"
                sx={{pointerEvents: 'none',}}
                open={openChart}
                anchorEl={anchorChart}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{
                    vertical: 'top', horizontal: 'left'
                }}
                onClose={handlePopoverCloseChart}
                disableRestoreFocus
            >
                <Typography sx={{p: 1}}>Chart</Typography>
            </Popover>)}
            <IconButton aria-label="manual-averaging" className={classes.icon} onClick={() => {
                setIsChartModal(false)
                setIsManualAveragingModal(true)
                setIsModalShown(current => !current);
            }
            }>
                <TouchAppIcon
                    aria-owns={openManualAveraging ? 'mouse-over-popover-manualAveraging' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpenManualAveraging}
                    onMouseLeave={handlePopoverCloseManualAveraging}
                />
            </IconButton>
            {!isMobile && (<Popover
                id="mouse-over-popover-manualAveraging"
                sx={{pointerEvents: 'none',}}
                open={openManualAveraging}
                anchorEl={anchorManualAveraging}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{
                    vertical: 'top', horizontal: 'left'
                }}
                onClose={handlePopoverCloseManualAveraging}
                disableRestoreFocus
            >
                <Typography sx={{p: 1}}>Manual averaging</Typography>
            </Popover>)}
            <IconButton aria-label="stop" className={classes.icon} onClick={() => {
                setIsChartModal(false)
                setIsManualAveragingModal(false)
                setIsModalShown(current => !current);
            }
            }>
                <StopIcon
                    aria-owns={openStop ? 'mouse-over-popover-stop' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpenStop}
                    onMouseLeave={handlePopoverCloseStop}
                />
            </IconButton>
            {!isMobile && (<Popover
                id="mouse-over-popover-stop"
                sx={{pointerEvents: 'none',}}
                open={openStop}
                anchorEl={anchorStop}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{
                    vertical: 'top', horizontal: 'left'
                }}
                onClose={handlePopoverCloseStop}
                disableRestoreFocus
            >
                <Typography sx={{p: 1}}>Stop</Typography>
            </Popover>)}
        </div>
    );
}
