import React, {useEffect} from 'react';
import AccountLineChart from "../accountLineChart/accountLineChart";
import {useDispatch, useSelector} from "react-redux";
import {Paper} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AccountPieChart from "../accountPieChart/accountPieChart";
import useStyles from "./aggregatedAccountBalance.styles";
import {getHistoryLaunch} from "../../features/django/djangoSlice";

export const AggregatedAccountBalance = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const accountInformation = useSelector((state) => state.django.accountInformation);
    const accountBalances = useSelector((state) => state.django.accountInformation && state.django.accountInformation.aggregate ? state.django.accountInformation.aggregate.accounts : []);
    const historyData = (useSelector((state) => state.django.historyData));


    useEffect(() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const formattedDate = tomorrow.toISOString().split('T')[0];
        dispatch(getHistoryLaunch({
            date: formattedDate
        }));
    }, [])

    return (
        <Paper style={{padding: 10}}>
            <Typography variant="h6" id="chartTitle" sx={{pl: {sm: 2}, pr: {xs: 1, sm: 1}}}
                        style={{paddingBottom: 20, paddingTop: 10}} color="primary">
                Aggregated account balance
            </Typography>
            <Grid container flexDirection="line">
                <Grid item xs={2}>
                    <Grid container style={{height: "100%"}} flexDirection="column" justifyContent="space-around">
                        <Grid item className={classes.kpi}>
                            <Typography variant="subtitle2" color="primary">Total portfolio value</Typography>
                            <Typography>{accountInformation && accountInformation.aggregate ? parseFloat(accountInformation.aggregate.accountTotalUSDT).toFixed(0) + ' $' : '$ 00.00'}</Typography>
                        </Grid>
                        <Grid item className={classes.kpi}>
                            <Typography variant="subtitle2" color="primary">Available for investment</Typography>
                            <Typography>{accountInformation && accountInformation.aggregate ? (parseFloat(accountInformation.aggregate.accountTotalAvailableUSDT) / parseFloat(accountInformation.aggregate.accountTotalUSDT) * 100).toFixed(0) + ' %': '00.00'}</Typography>
                        </Grid>
                        <Grid item className={classes.kpi}>
                            <Typography variant="subtitle2" color="primary">Bot profit (24h)</Typography>
                            {historyData && historyData[6] && typeof historyData[6].value !== "undefined" ?
                                <div>
                                    <Typography sx={{display: 'inline'}}>{historyData[6].value.toFixed(2)}&nbsp;$&nbsp;</Typography>
                                </div>
                                :
                                <Typography>0.00 $</Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" color="primary" style={{paddingLeft: 28, paddingBottom: 10}}>Bot profit (last 7days)</Typography>
                    {typeof (historyData) !== "undefined" ?
                        <AccountLineChart chartData={historyData}/>
                        :
                        ""}
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle2" color="primary" style={{paddingLeft: 28, paddingBottom: 10}}>Portfolio distribution</Typography>
                    {typeof (accountBalances) !== "undefined" ?
                        <AccountPieChart chartData={accountBalances}/>
                        :
                        ""
                    }
                </Grid>
            </Grid>
        </Paper>
    );
}
