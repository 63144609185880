import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    kpi: {
        padding: ' 4px 7px 4px 7px',
        borderColor: "#C3BCDB44",
        borderStyle: "solid",
        borderWidth: "1px"
    },
});

export default useStyles;