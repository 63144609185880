import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import BotListHistory from "../botListHistory/botListHistory";
import BotListActive from "../botListActive/botListActive";
import {Paper, useMediaQuery} from "@mui/material";
import BotListActiveMobile from "../botListActive/botListActiveMobile";
import BotListHistoryMobile from "../botListHistory/botListHistoryMobile";
import {useEffect} from "react";
import {getDCAListLaunch, postUpdatePNLLaunch} from "../../features/django/djangoSlice";
import {useDispatch} from "react-redux";
import BotConfiguration from "../botConfiguration/botConfiguration";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const BotList = () => {
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        dispatch(postUpdatePNLLaunch())
        dispatch(getDCAListLaunch())
    }, [dispatch])

    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: isMobile ? 0 : 2}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Current bots" {...a11yProps(0)} />
                        <Tab label="Bot History" {...a11yProps(1)} />
                        {isMobile && (<Tab label="New bot" {...a11yProps(2)} />)}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {isMobile ?
                        <BotListActiveMobile/>
                        :
                        <BotListActive/>
                    }
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {isMobile ?
                        <BotListHistoryMobile/>
                        :
                        <BotListHistory/>
                    }
                </TabPanel>
                {isMobile && (
                <TabPanel value={value} index={2}>
                    <BotConfiguration/>
                </TabPanel>
                )}
            </Paper>
        </Box>
    );
}