import {put, takeEvery} from 'redux-saga/effects'
import {convertListCoins} from "../../utils/utils";
import axios from "axios";
import {
    binanceGet24hTickerFailure,
    binanceGet24hTickerSuccess,
    binanceGetKlinesFailure,
    binanceGetKlinesSuccess,
    binanceGetExchangeInfoFailure,
    binanceGetExchangeInfoSuccess,
    binanceGetTickerFailure,
    binanceGetTickerSuccess,
    binanceGetExchangeInfoPairFailure,
    binanceGetExchangeInfoPairSuccess
} from "./binanceSlice";
import {failure} from "../../app/appSlice";
import privateApiClient from "../../services/privateApiClient";

function* get24hTickerCall(action) {
    try {
        const {coinList} = action.payload;
        const response = yield axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbols=[${convertListCoins(coinList)}]`);

        if (response.status !== 200) {
            yield put(failure({message: "Get 24h ticker fail"}));
            yield put(binanceGet24hTickerFailure({error: response.toString()}));
        }
        yield put(binanceGet24hTickerSuccess({data: response.data}));
    } catch (error) {
        yield put(failure({message: "Get 24h ticker fail"}));
        yield put(binanceGet24hTickerFailure({error: error.toString()}));
    }
}
function* getTickerCall() {
    try {
        const dcaList = yield privateApiClient.get(`/dca-config/?include_grid=true&format=json`,);
        const dcaListActive = dcaList.data.filter(pilot => pilot.bot_status === "ACTIVE");
        const uniqueActivePairs = dcaListActive.reduce((acc, item) => acc.concat(item.active_pairs), []);
        const uniqueActivePairsStr = '["' + uniqueActivePairs.join('","') + '"]';
        if (uniqueActivePairsStr !=='[""]') {
            const response = yield axios.get(`https://api.binance.com/api/v3/ticker/price?symbols=${uniqueActivePairsStr}`);
            if (response.status !== 200) {
                yield put(failure({message: "Get ticker fail"}));
                yield put(binanceGetTickerFailure({error: response.toString()}));
            }
            yield put(binanceGetTickerSuccess({data: response.data}));
        }
    } catch (error) {
        yield put(failure({message: "Get ticker fail"}));
        yield put(binanceGetTickerFailure({error: error.toString()}));
    }
}

function* getKlinesCall(action) {
    try {
        const {symbol, interval, limit} = action.payload;

        const response = yield axios.get(`https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${interval}&limit=${limit}`);

        if (response.status !== 200) {
            yield put(failure({message: "Get klines fail"}));
            yield put(binanceGetKlinesFailure({error: response.toString()}));
        }
        let klines = []
        for (let i in response.data) {
            klines.push({
                "open": response.data[i][1],
                "high": response.data[i][2],
                "low": response.data[i][3],
                "close": response.data[i][4],
                "time": response.data[i][0] / 1000
            })
        }
        yield put(binanceGetKlinesSuccess({data: klines}));
    } catch (error) {
        yield put(failure({message: "Get klines fail"}));
        yield put(binanceGetKlinesFailure({error: error.toString()}));
    }
}

function* getExchangeInfoCall() {
    try {
        const compatibleQuoteAsset = ["BTC", "USDT"];
        const response = yield axios.get(`https://api.binance.com/api/v3/exchangeInfo?permissions=SPOT`);
        let listAssets = [];

        if (response.status !== 200) {
            yield put(failure({message: "Get exchangeInfo fail"}));
            yield put(binanceGetExchangeInfoFailure({error: response.toString()}));
        }


        for (let i = 0; i < response.data.symbols.length; i++) {
            if (compatibleQuoteAsset.includes(response.data.symbols[i].quoteAsset)) {
                listAssets.push(response.data.symbols[i])
            }
        }
        yield put(binanceGetExchangeInfoSuccess({data: listAssets}));
    } catch (error) {
        yield put(failure({message: "Get exchangeInfo fail"}));
        yield put(binanceGetExchangeInfoFailure({error: error.toString()}));
    }
}

function* getExchangeInfoPairCall(action) {
    try {
        const {pair} = action.payload;
        const response = yield axios.get(`https://api.binance.com/api/v3/exchangeInfo?symbol=${pair}`);

        if (response.status !== 200) {
            yield put(failure({message: "Get exchangeInfo pair fail"}));
            yield put(binanceGetExchangeInfoPairFailure({error: response.toString()}));
        }

        yield put(binanceGetExchangeInfoPairSuccess({data: response.data.symbols[0]}));
    } catch (error) {
        yield put(failure({message: "Get exchangeInfo pair fail"}));
        yield put(binanceGetExchangeInfoPairFailure({error: error.toString()}));
    }
}

function* binanceSaga() {
    yield takeEvery("binance/binanceGet24hTickerLaunch", get24hTickerCall);
    yield takeEvery("binance/binanceGetTickerLaunch", getTickerCall);
    yield takeEvery("binance/binanceGetKlinesLaunch", getKlinesCall);
    yield takeEvery("binance/binanceGetExchangeInfoLaunch", getExchangeInfoCall);
    yield takeEvery("binance/binanceGetExchangeInfoPairLaunch", getExchangeInfoPairCall);
}

export default binanceSaga;