import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useStyles from "./startBotModal.styles";
import {StyledButton} from "../button/styledButton";
import Grid from "@mui/material/Grid";
import {useDispatch} from "react-redux";
import {
    getBotListLaunch,
    getDCAListLaunch,
    postRunBotLaunch,
    postRunDCALaunch
} from "../../features/django/djangoSlice";


const StartBotModal = ({bot_id, bot_type, handleModalClose}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Box className={classes.modal}>
            <Grid container flexDirection="column">
                <Grid item>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{padding: 20}}>
                        Do you want to start the bot?
                    </Typography>
                </Grid>
                <Grid container flexDirection="row" justifyContent="space-around">
                    <Grid item className={classes.button}>
                        <StyledButton
                            variant="contained"
                            label="Start the bot"
                            fullWidth={true}
                            action={() => {
                                if (bot_type === "dca") {
                                    dispatch(postRunDCALaunch({
                                        bot_id: bot_id
                                    }))
                                    handleModalClose()
                                    setTimeout(() => {
                                        dispatch(getDCAListLaunch())
                                    }, 10000)
                                } else {
                                    dispatch(postRunBotLaunch({
                                        bot_id: bot_id
                                    }))
                                    handleModalClose()
                                    setTimeout(() => {
                                        dispatch(getBotListLaunch())
                                    }, 10000)
                                }
                            }}/></Grid>
                    <Grid item className={classes.button}>
                        <StyledButton
                            variant="contained"
                            label="Cancel"
                            fullWidth={true}
                            color="error"
                            action={() => {
                                handleModalClose()
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
        ;
}

export default StartBotModal;