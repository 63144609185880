import {createChart, ColorType} from 'lightweight-charts';
import React, {useEffect, useRef} from 'react';
import {binanceGetKlinesLaunch} from "../../features/binance/binanceSlice";
import {useDispatch, useSelector} from "react-redux";
import {blueGrey, green, grey, orange, red} from "@mui/material/colors";
import * as LightweightCharts from "lightweight-charts";
import FormControl from "@mui/material/FormControl";
import {Select, useMediaQuery} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {getDCAGridLaunch} from "../../features/django/djangoSlice";
import Grid from "@mui/material/Grid";
import useStyles from "../dcaChart/dcaChart.styles";
import {countDecimals} from "../../utils/utils";

export const DcaChart = ({bot_id, pair, base_asset, new_break_even_price, modal_type}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const chartContainerRef = useRef();
    const klines = useSelector((state) => state.binance.klines);
    const dcaGridData = useSelector((state) => state.django.dcaGridData);
    const [interval, setInterval] = React.useState("1h");
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleChange = (event) => {
        setInterval(event.target.value);
    };

    function getSmallestPriceDifference(klines, maxDecimalCount) {
        let minDifference = Infinity;

        klines.forEach(kline => {
            const differences = [
                Math.abs(kline.open - kline.high),
                Math.abs(kline.open - kline.low),
                Math.abs(kline.open - kline.close),
                Math.abs(kline.high - kline.low),
                Math.abs(kline.high - kline.close),
                Math.abs(kline.low - kline.close),
            ];

            const minKlineDifference = Math.min(...differences);

            minDifference = Math.min(minDifference, minKlineDifference);
        });

        if (maxDecimalCount === 0) {
            return "1";
        }

        const minMove = "0." + "0".repeat(maxDecimalCount - 1) + "1";
        return minMove;
    }


    useEffect(() => {
        dispatch(binanceGetKlinesLaunch({symbol: pair, interval: interval, limit: 1000}))
        dispatch(getDCAGridLaunch({botId: bot_id, pair:pair}))
    }, [dispatch, interval])

    useEffect(
        () => {
            const handleResize = () => {
                chart.applyOptions({
                    width: chartContainerRef.current.clientWidth,
                    height: chartContainerRef.current.clientHeight
                });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: {type: ColorType.Solid, color: "#24344d"}, textColor: "white",
                },
                grid: {
                    vertLines: {color: blueGrey[800]},
                    horzLines: {color: blueGrey[800]},
                },
                width: chartContainerRef.current.clientWidth, height: chartContainerRef.current.clientHeight,
            });
            // chart.timeScale().fitContent();
            chart.timeScale().applyOptions({
                    timeVisible: true,
                    borderColor: blueGrey[800],
                    barSpacing: 5,
                }
            )

            // Customizing the Crosshair
            chart.applyOptions({
                crosshair: {
                    // Change mode from default 'magnet' to 'normal'.
                    // Allows the crosshair to move freely without snapping to datapoints
                    mode: LightweightCharts.CrosshairMode.Normal,

                    // Vertical crosshair line (showing Date in Label)
                    vertLine: {
                        color: "#C3BCDB44"
                    },

                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        color: "#C3BCDB44",
                        labelBackgroundColor: "#C3BCDB44",
                    },
                },
            });

            const maxDecimalCount = klines.reduce((max, kline) => {
                const decimals = Math.max(countDecimals(kline.open), countDecimals(kline.high), countDecimals(kline.low), countDecimals(kline.close));
                return Math.max(max, decimals);
            }, 0);


            const candlestickSeries = chart.addCandlestickSeries({
                upColor: green[400],
                downColor: red[400],
                borderVisible: false,
                wickUpColor: green[400],
                wickDownColor: red[400]
            });

            candlestickSeries.setData(klines);

            candlestickSeries.applyOptions({
                priceFormat: {
                    type: 'price',
                    precision: maxDecimalCount,
                    minMove: getSmallestPriceDifference(klines, maxDecimalCount),
                }
            });



            if (typeof (dcaGridData[0]) !== "undefined") {
                let totalCost = 0;
                let totalAmount = 0;

                for (let i in dcaGridData) {
                    if (dcaGridData[i].order_status === "FILLED") {
                        totalCost += dcaGridData[i].order_executed_qty * dcaGridData[i].order_price;
                        totalAmount += dcaGridData[i].order_executed_qty;
                    }
                }

                const breakEvenPrice = totalCost / totalAmount;

                for (let i in dcaGridData) {
                    if (dcaGridData[i]["line_type"] === "TAKE_PROFIT"){
                        const myPriceLine = {
                            price: dcaGridData[i].order_price,
                            color: green[400],
                            lineWidth: 1,
                            lineStyle:  0,
                            axisLabelVisible: true,
                            // title: 'TAKE_PROFIT',
                        }
                        candlestickSeries.createPriceLine(myPriceLine);
                        window.addEventListener('resize', handleResize);
                    }
                    else if (dcaGridData[i]["line_type"] === "BASE"){
                        const myPriceLine = {
                            price: dcaGridData[i].order_price,
                            color: grey[400],
                            lineWidth: 1,
                            lineStyle: 4,
                            axisLabelVisible: true,
                            // title: 'Base',
                        }
                        candlestickSeries.createPriceLine(myPriceLine);
                        window.addEventListener('resize', handleResize);
                    } else {
                        if (dcaGridData[i]["order_status"] === "FILLED") {
                            const breakEvenLine = {
                                price: breakEvenPrice,
                                color: orange[400],
                                lineWidth: 1,
                                lineStyle: 4,
                                axisLabelVisible: true,
                                // title: 'Break-Even',
                            };
                            candlestickSeries.createPriceLine(breakEvenLine)
                        }
                        const myPriceLine = {
                            price: dcaGridData[i].order_price,
                            color: "#2ec5d3",
                            lineWidth: 1,
                            lineStyle: dcaGridData[i]["order_status"] === "FILLED" ? 4:0,
                            axisLabelVisible: true,
                            // title: 'TAKE_PROFIT',
                        }
                        candlestickSeries.createPriceLine(myPriceLine);
                        window.addEventListener('resize', handleResize);
                    }
                }
                if (new_break_even_price !== 0) {
                    const myPriceLine = {
                        price: new_break_even_price,
                        color: orange[400],
                        lineWidth: 1,
                        lineStyle: 0,
                        axisLabelVisible: true,
                        // title: 'Base',
                    }
                    candlestickSeries.createPriceLine(myPriceLine);
                    window.addEventListener('resize', handleResize);
                }

            }

            return () => {
                window.removeEventListener('resize', handleResize);
                chart.remove();
            };
        },
        [klines, pair, new_break_even_price]
    );
    return (
        <Grid item id="chart"
              ref={chartContainerRef}
              style={{ position: "relative", height: isMobile && modal_type==="averaging" ? "30vh" : "80vh", width: "100%" }}
        >
            <Grid item className="lw-attribution"
                  style={{position: "absolute", zIndex: "3", paddingTop: 17, paddingLeft: 100, paddingRight: 10}}>
                <img
                    src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${base_asset.toLowerCase()}.svg`}
                    className={classes.icon}
                    alt="token_logo"
                /> · {pair}
            </Grid>
            <FormControl sx={{m: 1, minWidth: 120}} size="small"
                         style={{
                             position: "absolute",
                             zIndex: "3",
                             paddingTop: 0,
                             paddingLeft: 10,
                             paddingRight: 30
                         }}>
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={interval}
                    label="Interval"
                    onChange={handleChange}
                >
                    <MenuItem value="5m">5m</MenuItem>
                    <MenuItem value="15m">15m</MenuItem>
                    <MenuItem value="1h">1h</MenuItem>
                    <MenuItem value="4h">4h</MenuItem>
                    <MenuItem value="1d">1d</MenuItem>
                    <MenuItem value="1w">1w</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );
}
