import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    icon:{
        marginLeft:10,
        height:20,
        width:20,
        verticalAlign:-4
    },
});

export default useStyles;