import * as React from 'react';
import Typography from "@mui/material/Typography";
import {Paper} from "@mui/material";
import Grid from "@mui/material/Grid";
import useStyles from "./botConfiguration.styles";
import {StyledSelect} from "../select/styledSelect";
import {useDispatch, useSelector} from "react-redux";
import {binanceGetExchangeInfoLaunch} from "../../features/binance/binanceSlice";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useFormik} from 'formik';
import * as yup from 'yup';
import {getBotGridLaunch, postBotConfigLaunch, saveSelectedPair} from "../../features/django/djangoSlice";
import {StyledTextField} from "../textField/styledTextField";
import {StyledButton} from "../button/styledButton";
import {countDecimals} from "../../utils/utils";
import {cryptoComGetExchangeInfoLaunch} from "../../features/cryptoCom/cryptoComSlice";
import {bybitGetExchangeInfoLaunch} from "../../features/bybit/bybitSlice";

const BotConfiguration = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [exchange, setExchange] = React.useState("");
    const exchangeInfo = useSelector((state) => {
        if (exchange === 'BIN') {
            return state.binance.exchangeInfo;
        } else if (exchange === 'CRO') {
            return state.cryptoCom.exchangeInfo;
        } else if (exchange === 'BYT') {
            return state.bybit.exchangeInfo;
        } else {
            return null;
        }
    });
    const accountInformation = useSelector((state) => state.django.accountInformation);
    const selectedPair = useSelector((state) => state.django.selectedPair);
    const [market, setMarket] = React.useState("");
    const [baseAsset, setBaseAsset] = React.useState("");
    const [quoteAsset, setQuoteAsset] = React.useState("");
    const [pair, setPair] = React.useState("");
    const [listPair, setListPair] = React.useState([{text: '', value: ''}]);
    const [availableQuote, setAvailableQuote] = React.useState(0);
    const [pairMinNotional, setPairMinNotional] = React.useState([]);
    const [investmentAmountMin, setInvestmentAmountMin] = React.useState();
    const [lowPrice, setLowPrice] = React.useState("");
    const [highPrice, setHighPrice] = React.useState("");
    const [isFieldDisabled, setIsFieldDisabled] = React.useState(true);

    let listExchange = [
        {text: 'Crypto.com', value: 'CRO'},
        {text: 'Bybit.com', value: 'BYT'},
        {text: 'Binance', value: 'BIN'},
    ]

    useEffect(() => {
        if (exchange === 'BIN') {
            dispatch(binanceGetExchangeInfoLaunch());
        } else if (exchange === 'CRO') {
            dispatch(cryptoComGetExchangeInfoLaunch());
        } else if (exchange === 'BYT') {
            dispatch(bybitGetExchangeInfoLaunch());
        }

    }, [dispatch, exchange])

    let listMarket = [
        {text: 'USDT', value: 'USDT'},
        {text: 'BTC', value: 'BTC'},
    ];

    useEffect(() => {
        setPair("")
        setBaseAsset("")
        setQuoteAsset("")
        // setAvailableQuote(0)
        setListPair([{text: '', value: ''}])
        for (let i in exchangeInfo) {
            if (exchangeInfo[i].hasOwnProperty("symbol") && exchangeInfo[i].status === "TRADING" && exchangeInfo[i].quoteAsset === market) {
                setListPair(listPair => [...listPair, {text: exchangeInfo[i].symbol, value: exchangeInfo[i].symbol}])
            }
        }
        if (exchange === 'CRO' && accountInformation.hasOwnProperty("crypto_com")) {
            for (let i in accountInformation.crypto_com.accounts) {
                if (accountInformation.crypto_com.accounts[i].asset === market) {
                    setAvailableQuote(accountInformation.crypto_com.accounts[i].available)
                }
            }
        }
        else if (exchange === 'BYT' && accountInformation.hasOwnProperty("bybit")) {
            for (let i in accountInformation.bybit.accounts) {
                if (accountInformation.bybit.accounts[i].asset === market) {
                    setAvailableQuote(accountInformation.bybit.accounts[i].available)
                }
            }
        }
    }, [exchangeInfo, market, accountInformation])


    useEffect(() => {
        for (let i in exchangeInfo) {
            if (exchangeInfo[i].hasOwnProperty("symbol") && exchangeInfo[i].hasOwnProperty("tickSize") && exchangeInfo[i].symbol === pair) {
                if (exchangeInfo[i].hasOwnProperty("tickSize")) {
                    dispatch(saveSelectedPair({
                        "pair": exchangeInfo[i].symbol,
                        "quoteAsset": exchangeInfo[i].quoteAsset,
                        "baseAsset": exchangeInfo[i].baseAsset,
                        "exchange": exchangeInfo[i].exchange,
                        "tickSize": exchangeInfo[i].tickSize,
                        "minPrice": exchangeInfo[i].minPrice,
                        "maxPrice": exchangeInfo[i].maxPrice,
                        "minNotional": exchangeInfo[i].minNotional,
                        "grid": []
                    }))
                    dispatch(getBotGridLaunch({botId: 0}))
                }
                if (exchangeInfo[i].hasOwnProperty("minNotional")) {
                    setPairMinNotional(exchangeInfo[i].minNotional)
                }
                setIsFieldDisabled(false)
                setBaseAsset(exchangeInfo[i].baseAsset)
                setQuoteAsset(exchangeInfo[i].quoteAsset)
            }
        }
    }, [dispatch, pair, exchangeInfo])

    //
    const validationSchema = yup.object({
        investment_amount: yup
            .number('Investment amount')
            .min(investmentAmountMin, `Minimum required is ${investmentAmountMin}`)
            .max(availableQuote, "Not enough funds available")
            .integer('Investment amount should be an integer')
            .required('Investment amount is required'),
        low_price: yup
            .number('Low price')
            .min((selectedPair.hasOwnProperty("minPrice") ? Number(selectedPair.minPrice) : 0), "Price is too low")
            .lessThan(highPrice, 'Must be lower than the high price')
            .required('Low price is required')
            .test('is-decimal', 'Too many decimal(s), ', value => (value + "").match(new RegExp('^\\d+(\\.\\d{0,' + (selectedPair.hasOwnProperty("tickSize") ? countDecimals(selectedPair.tickSize) : 0) + '})?$')),),
        high_price: yup
            .number('High price')
            .when("selectedPair.maxPrice", (maxPrice, schema) => {
                return maxPrice > 0 ? schema.max(Number(maxPrice), "Price is too high") : schema
            })
            .moreThan(lowPrice, 'Must be greater than the low price')
            .required('High price is required')
            .test('is-decimal', 'Too many decimal(s), ', value => (value + "").match(new RegExp('^\\d+(\\.\\d{0,' + (selectedPair.hasOwnProperty("tickSize") ? countDecimals(selectedPair.tickSize) : 0) + '})?$'))),
        grid_step_size: yup
            .number('Step, %')
            .required('Step is required')
            .test('is-decimal', 'Too many decimal(s), ', value => (value + "").match(new RegExp('^\\d+(\\.\\d{0,2})?$')),),
        grid_lines: yup
            .number('Levels (5-180)')
            .min(5, "Minimum 5 lines")
            .max(180, "Maximum 180 lines")
            .required('Step is required')
    });

    const formik = useFormik({
        initialValues: {
            investment_amount: '',
            low_price: '',
            high_price: '',
            grid_step_size: '',
            grid_lines: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(postBotConfigLaunch({
                exchange: exchange,
                profit_currency: market,
                pair: pair,
                base_asset: baseAsset,
                quote_asset: quoteAsset,
                start_mode: "MKT",
                investment_amount: values.investment_amount,
                low_price: values.low_price,
                high_price: values.high_price,
                grid_step_size: values.grid_step_size,
                grid_lines: values.grid_lines,
                navigate
            }))
        }
    })

    useEffect(() => {
        setInvestmentAmountMin(formik.values.grid_lines * pairMinNotional)
    }, [dispatch, formik.values.grid_lines, pairMinNotional])

    useEffect(() => {
        setHighPrice(formik.values.high_price)
    }, [dispatch, formik.values.high_price])

    useEffect(() => {
        setLowPrice(formik.values.low_price)
    }, [dispatch, formik.values.low_price])

    useEffect(() => {
        if (formik.values.low_price !== "" && formik.values.high_price !== "" && formik.values.investment_amount !== "" && formik.values.grid_step_size !== "" && formik.values.grid_lines) {
            let temp_grid = []
            for (let step = 0; step < Number(formik.values.grid_lines) + 1; step++) {
                temp_grid[step] = {}
                temp_grid[step].line_id = Number(step)
                if (step === 0) {
                    temp_grid[step].order_price = Number(formik.values.low_price)
                } else {
                    temp_grid[step].order_price = temp_grid[step - 1].order_price + (temp_grid[step - 1].order_price * formik.values.grid_step_size / 100)
                }
            }
            dispatch(saveSelectedPair({
                "pair": selectedPair.pair,
                "priceFilter": selectedPair.tickSize,
                "quoteAsset": selectedPair.quoteAsset,
                "baseAsset": selectedPair.baseAsset,
                "exchange": selectedPair.exchange,
                "tickSize": selectedPair.tickSize,
                "minPrice": selectedPair.minPrice,
                "maxPrice": selectedPair.maxPrice,
                "minNotional": selectedPair.minNotional,
                "grid": temp_grid
            }))
        }
    }, [formik.values.low_price, formik.values.high_price, formik.values.investment_amount, formik.values.grid_step_size, formik.values.grid_lines])

    useEffect(() => {
        formik.values.investment_amount = ""
        formik.values.high_price = ""
        formik.values.low_price = ""
        formik.values.grid_step_size = ""
        formik.values.grid_lines = ""
    }, [pair, market, exchange])


    return (
        <Paper className={classes.paper} style={{padding: 10}}>
            <Grid container direction={"column"}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid item style={{paddingBottom: 5}}>
                        <Typography variant="h6" color="primary">Choose a pair</Typography>
                    </Grid>
                    <Grid item style={{paddingBottom: 24}}>
                        <StyledSelect
                            labelId="exchange"
                            id="outlined"
                            value={exchange}
                            label="Exchange"
                            listItems={listExchange}
                            handleChange={setExchange}
                        />
                    </Grid>
                    <Grid item style={{paddingBottom: 24}}>
                        <StyledSelect
                            labelId="market"
                            id="outlined"
                            value={market}
                            label="Market"
                            listItems={listMarket}
                            handleChange={setMarket}
                        />
                    </Grid>
                    <Grid item>
                        <StyledSelect
                            labelId="pair"
                            id="outlined"
                            value={pair}
                            label="Pair"
                            listItems={listPair}
                            handleChange={setPair}
                        />
                    </Grid>
                    <Grid item style={{paddingTop: 15, paddingBottom: 5}}>
                        <Typography variant="h6" color="primary">Configure your bot</Typography>
                    </Grid>
                    <Grid item>
                        <StyledTextField disabled={isFieldDisabled}
                                         id="investment_amount"
                                         variant="outlined"
                                         name="investment_amount"
                                         type="text"
                                         placeholder="ex: 1000.00"
                                         label="Investment amount"
                                         value={formik.values.investment_amount}
                                         onChange={formik.handleChange}
                                         error={formik.touched.investment_amount && Boolean(formik.errors.investment_amount)}
                                         helperText={formik.touched.investment_amount && formik.errors.investment_amount}
                                         style={{height: 80, width: "100%"}}/>
                    </Grid>
                    <Grid item>
                        <StyledTextField disabled={isFieldDisabled}
                                         id="low_price"
                                         variant="outlined"
                                         name="low_price"
                                         type="text"
                                         placeholder="ex: 15000.00"
                                         label="Low price"
                                         value={formik.values.low_price}
                                         onChange={(e) => {
                                             formik.handleChange(e);
                                             formik.values.grid_step_size = "";
                                             formik.values.grid_lines = "";
                                         }}
                                         error={formik.touched.low_price && Boolean(formik.errors.low_price)}
                                         helperText={formik.touched.low_price && formik.errors.low_price}
                                         style={{height: 80, width: "100%"}}/>
                    </Grid>
                    <Grid item>
                        <StyledTextField disabled={isFieldDisabled}
                                         id="high_price"
                                         variant="outlined"
                                         name="high_price"
                                         type="text"
                                         placeholder="ex: 25000.00"
                                         label="High price"
                                         value={formik.values.high_price}
                                         onChange={(e) => {
                                             formik.handleChange(e);
                                             formik.values.grid_step_size = "";
                                             formik.values.grid_lines = "";
                                         }}
                                         error={formik.touched.high_price && Boolean(formik.errors.high_price)}
                                         helperText={formik.touched.high_price && formik.errors.high_price}
                                         style={{height: 80, width: "100%"}}/>
                    </Grid>
                    <Grid item>
                        <StyledTextField disabled={isFieldDisabled}
                                         id="grid_lines"
                                         variant="outlined"
                                         name="grid_lines"
                                         type="text"
                                         placeholder="ex: 50"
                                         label="Levels (5-180)"
                                         value={formik.values.grid_lines}
                                         onChange={(e) => {
                                             formik.handleChange(e);
                                             formik.values.grid_step_size = isFinite((((Math.log(formik.values.high_price / formik.values.low_price)) / e.target.value) * 100).toFixed(2)) ? (((Math.log(formik.values.high_price / formik.values.low_price)) / e.target.value) * 100).toFixed(2) : ""
                                         }}
                                         error={formik.touched.grid_lines && Boolean(formik.errors.grid_lines)}
                                         helperText={formik.touched.grid_lines && formik.errors.grid_lines}
                                         style={{height: 80, width: "100%"}}/>
                    </Grid>
                    <Grid item>
                        <StyledTextField disabled={isFieldDisabled}
                                         id="grid_step_size"
                                         variant="outlined"
                                         name="grid_step_size"
                                         type="text"
                                         placeholder="ex: 0.30"
                                         label="Step, %"
                                         value={formik.values.grid_step_size}
                                         onChange={(e) => {
                                             formik.handleChange(e);
                                             formik.values.grid_lines = isFinite(Math.trunc(((Math.log(formik.values.high_price / formik.values.low_price)) / e.target.value) * 100)) ? Math.trunc(((Math.log(formik.values.high_price / formik.values.low_price)) / e.target.value) * 100) : "";
                                         }}
                                         error={formik.touched.grid_step_size && Boolean(formik.errors.grid_step_size)}
                                         helperText={formik.touched.grid_step_size && formik.errors.grid_step_size}
                                         style={{height: 80, width: "100%"}}/>
                    </Grid>
                    <Grid item>
                        <StyledButton
                            variant="contained"
                            label="Create bot"
                            type="submit"
                            fullWidth={true}
                        />
                    </Grid>
                </form>
            </Grid>
        </Paper>
    )
        ;
}

export default BotConfiguration;