import React from 'react';
import {Button} from "@mui/material";

export const StyledButton = ({variant, label, ariaLabel, action, type, fullWidth, color}) => {
    return (
        <Button
            variant={variant}
            aria-label={ariaLabel}
            onClick={action}
            type={type}
            fullWidth={fullWidth}
            color={color}
        >
            {label}
        </Button>
    );
}
