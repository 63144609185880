import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: '#24344d',
        boxShadow: 24,
        p: 4,
    },
    icon:{
        marginRight:7,
        height:25,
        width:25,
        verticalAlign:-4
    },
    kpi: {
        padding: ' 4px 7px 4px 7px',
        borderColor: "#C3BCDB44",
        borderStyle: "solid",
        borderWidth:"1px"
    },
});

export default useStyles;