import React from 'react';
import {CssBaseline, useMediaQuery} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Grid4x4OutlinedIcon from "@mui/icons-material/Grid4x4Outlined";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import ListItemText from "@mui/material/ListItemText";
import LoginIcon from "@mui/icons-material/Login";
import BugReportIcon from "@mui/icons-material/BugReport";
import Box from "@mui/material/Box";
import {DrawerHeader} from "../drawerHeader/drawerHeader";
import {Main} from "../main/main";
import {theme} from "../../utils/materialUiTheme";
import {useNavigate} from "react-router-dom";
import {isExpired} from "react-jwt";
import {Logout} from "@mui/icons-material";
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';

const pages = [
    {title: "Home", path: "/"},
    {title: "Grid Bots", path: "/grid_bot"},
    {title: "DCA Bots", path: "/dca_bot"},
    // {title: "My binance", path: "/binance"},
];
const drawerWidth = 240;

export const CustomDrawer = ({open, handleDrawerClose, body}) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const accessToken = localStorage.getItem('accessToken');
    const isMyTokenExpired = isExpired(accessToken);

    const handleLogout = () => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");

        };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="temporary"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    {pages.map(({title, path, icon}, index) => (
                        <ListItem key={title} disablePadding onClick={handleDrawerClose}>
                            <ListItemButton onClick={() => navigate(path)}>
                                <ListItemIcon>
                                    {index === 0 ? <DashboardIcon/> : index === 1 ? <Grid4x4OutlinedIcon/> : index === 2 ? <HorizontalSplitIcon/> :
                                        <SettingsInputComponentIcon/>}
                                </ListItemIcon>
                                <ListItemText primary={title}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider/>
                <List>
                    {['login'].map((text) => (
                        <ListItem key={text} disablePadding>
                            {isMyTokenExpired ? (
                                    <ListItemButton href="/login">
                                        <ListItemIcon>
                                            <LoginIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary='Login'/>
                                    </ListItemButton>
                                ) : (
                                    <ListItemButton onClick={handleLogout} href="/login">
                                        <ListItemIcon>
                                            <Logout/>
                                        </ListItemIcon>
                                        <ListItemText primary="Logout"/>
                                    </ListItemButton>
                                )}
                        </ListItem>
                    ))}
                </List>
                {process.env.REACT_APP_ENVIRONMENT !== "prod" &&
                    <Divider/>}
                {process.env.REACT_APP_ENVIRONMENT !== "prod" && <List>
                    {['Test'].map((text) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton href="/test">
                                <ListItemIcon>
                                    <BugReportIcon/>
                                </ListItemIcon>
                                <ListItemText primary={text}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                }
            </Drawer>
            {isMobile ? (
                <Box sx={{width: '100%'}}>
                    <DrawerHeader/>
                    {body()}
                </Box>
            ) : (
                <Main open={open}>
                    <DrawerHeader/>
                    {body()}
                </Main>
            )}
        < /Box>
    )
};
