import {makeStyles} from "@mui/styles";
import {theme} from "../../utils/materialUiTheme";

const useStyles = makeStyles({
    icon:{
        marginRight:10,
        height:20,
        width:20,
        verticalAlign:-4
    },
    metricsRow: {
        backgroundColor: theme.palette.action.hover,
    },
    kpi: {
        borderColor: "#C3BCDB44",
        borderStyle: "solid",
        borderWidth:"1px"
    },
});

export default useStyles;