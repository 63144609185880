import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'

import binanceReducer from '../features/binance/binanceSlice';
import bybitReducer from '../features/bybit/bybitSlice';
import cryptoComReducer from '../features/cryptoCom/cryptoComSlice';
import appReducer from './appSlice';
import binanceSaga from "../features/binance/binanceSaga";
import bybitSaga from "../features/bybit/bybitSaga";
import cryptoComSaga from "../features/cryptoCom/cryptoComSaga";
import djangoSaga from "../features/django/djangoSaga";
import djangoReducer from '../features/django/djangoSlice'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
export const store = configureStore({
  reducer: {
    binance: binanceReducer,
    bybit: bybitReducer,
    cryptoCom: cryptoComReducer,
    app: appReducer,
    django: djangoReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }).concat(sagaMiddleware),
});

sagaMiddleware.run(binanceSaga)
sagaMiddleware.run(bybitSaga)
sagaMiddleware.run(cryptoComSaga)
sagaMiddleware.run(djangoSaga)