import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: '#24344d',
        boxShadow: 24,
        p: 4,
    },
    chart: {
        padding: ' 15px 0px 0px 0px',
        borderColor: "#C3BCDB44",
        borderStyle: "solid",
        borderWidth:"1px",
        borderRadius: "3px"
    },
});

export default useStyles;