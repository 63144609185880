import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    paper: {
        marginTop: 80,
        margin: 'auto',
        width: '25%',
        minWidth: 300,
        padding: 30
    },
    icon:{
        marginRight:10,
        height:25,
        width:25,
        verticalAlign:-8
    },
    root: {
        width: '100%',
    },
    kpi: {
        borderColor: "#C3BCDB44",
        borderStyle: "solid",
        borderWidth:"1px"
    },
    showMoreBtn: {
        textTransform: 'none',
        textDecoration: 'underline',
        color: 'inherit',
        padding: 0,
        minWidth: 0,
    },
});

export default useStyles;