import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    root: {
        width: 270,
        padding: ' 4px 7px 4px 7px',
    },
    icon:{
        verticalAlign:-2,
    }
});

export default useStyles;