export const convertListCoins = (list) => {
    return list.split(', ').map((coin) => { return `"${coin}"`});
};

export const countDecimals = (value) => {
    value = Number(value)
    if(Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
}

export const sumObj = (chartData) => {
    // an object to store the `created` and `amount` as key=>value
    var newObj = {};
    // loop over the objects in the array
    chartData.forEach(function(obj) {
        // if the key is present in `newObj` then we need to add the amount to it's value
        if(obj.time in newObj) {
            newObj[obj.time] += obj.value;
        }else {
            // else just add the key and the amount as value
            newObj[obj.time] = obj.value;
        }
    });
    //  create an array to store the final objects
    var arr = [];
    // loop over the properties in `newObj`
    for(var prop in newObj) {
        // push an object each time
        arr.push({time: Number(prop), value: newObj[prop]});
    }
    // return the final result
    return arr;
}

export const daysDiff = (start_date, stop_date) =>{
    var date1 = new Date(start_date)
    var date2 = new Date(stop_date)
    var timDiff = date2.getTime() - date1.getTime()
    var daysDiff = timDiff / (1000 * 3600 * 24)
    var hoursDiff = Math.ceil((daysDiff - Math.round(daysDiff))*24)
    return Math.round(daysDiff)+" day(s) "+hoursDiff+" hour(s)"
}

export const capitalizeFirstLetter = (string) => {
    return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
}