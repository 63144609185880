import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    tickerData: [],
    klines: [],
    status: 'idle',
    exchangeInfo: [],
    exchangeInfoPair:[]
};

export const cryptoComSlice = createSlice({
    name: 'cryptoCom',
    initialState,
    reducers: {
        cryptoComGetTickerLaunch: state => {
            state.status = 'loading'
        },
        cryptoComGetTickerSuccess: (state, action) => {
            state.tickerData = action.payload.data
        },
        cryptoComGetTickerFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.tickerData = []
        },
        cryptoComGetKlinesLaunch: state => {
            state.status = 'loading'
        },
        cryptoComGetKlinesSuccess: (state, action) => {
            state.status = 'success';
            state.klines = action.payload.data
        },
        cryptoComGetKlinesFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.klines = []
        },
        cryptoComGetExchangeInfoLaunch: state => {
            state.status = 'loading'
        },
        cryptoComGetExchangeInfoSuccess: (state, action) => {
            state.status = 'success';
            state.exchangeInfo = action.payload.data
        },
        cryptoComGetExchangeInfoFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.exchangeInfo = []
        },
        cryptoComGetExchangeInfoPairLaunch: state => {
            state.status = 'loading'
        },
        cryptoComGetExchangeInfoPairSuccess: (state, action) => {
            state.status = 'success';
            state.exchangeInfoPair = action.payload.data
        },
        cryptoComGetExchangeInfoPairFailure: (state, action) => {
            state.status = 'rejected';
            state.message = action.payload;
            state.exchangeInfoPair = []
        },
    },
});

export const {
    cryptoComGetTickerLaunch,
    cryptoComGetTickerSuccess,
    cryptoComGetTickerFailure,
    cryptoComGetKlinesLaunch,
    cryptoComGetKlinesSuccess,
    cryptoComGetKlinesFailure,
    cryptoComGetExchangeInfoLaunch,
    cryptoComGetExchangeInfoFailure,
    cryptoComGetExchangeInfoSuccess,
    cryptoComGetExchangeInfoPairLaunch,
    cryptoComGetExchangeInfoPairFailure,
    cryptoComGetExchangeInfoPairSuccess,
} = cryptoComSlice.actions;

export default cryptoComSlice.reducer;
