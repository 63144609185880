import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        width:"80%",
        backgroundColor: '#24344d',
        boxShadow: 24,
        p: 4,
    },
    button: {
        width: 200,
        padding:10,
        paddingBottom:20,
    }
});

export default useStyles;