import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useStyles from "./copyBotModal.styles";
import {StyledButton} from "../button/styledButton";
import Grid from "@mui/material/Grid";
import {useDispatch} from "react-redux";
import {copyBotConfigLaunch, copyDCAConfigLaunch} from "../../features/django/djangoSlice";


const CopyBotModal = ({bot_id, bot_type, handleModalClose}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Box className={classes.modal}>
            <Grid container flexDirection="column">
                <Grid item>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{padding: 20}}>
                        Do you want to copy the bot?
                    </Typography>
                </Grid>
                <Grid container flexDirection="row" justifyContent="space-around" >
                    <Grid item className={classes.button}>
                        {bot_type === "dca"?
                        <StyledButton
                                      variant="contained"
                                      label="Copy the bot"
                                      fullWidth={true}
                                      action={() => {
                                          dispatch(copyDCAConfigLaunch({
                                              bot_id: bot_id
                                          }))
                                          handleModalClose()
                                      }}/>:
                        <StyledButton
                            variant="contained"
                            label="Copy the bot"
                            fullWidth={true}
                            action={() => {
                                dispatch(copyBotConfigLaunch({
                                    bot_id: bot_id
                                }))
                                handleModalClose()
                            }}/>}
                    </Grid>
                    <Grid item className={classes.button}>
                        <StyledButton
                                      variant="contained"
                                      label="Cancel"
                                      fullWidth={true}
                                      color="error"
                                      action={() => {
                                          handleModalClose()
                                      }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
        ;
}

export default CopyBotModal;