import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {visuallyHidden} from '@mui/utils';
import {useSelector} from "react-redux";
import useStyles from "./tokenList.styles";
import {green, grey, red} from "@mui/material/colors";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'asset',
        numeric: false,
        disablePadding: false,
        label: 'Token',
    },
    {
        id: 'share',
        numeric: true,
        disablePadding: false,
        label: 'Share',
    },
    {
        id: 'priceChangePercent24h',
        numeric: true,
        disablePadding: false,
        label: 'Change (24H)',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'Price',
    },
    {
        id: 'free',
        numeric: true,
        disablePadding: false,
        label: 'Available Amount',
    },
    {
        id: 'value',
        numeric: true,
        disablePadding: false,
        label: 'Total value',
    },
];

function EnhancedTableHead(props) {
    const {order, orderBy, onRequestSort} =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar() {

    return (
        <Toolbar
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1}
            }}
        >
            <Typography
                sx={{flex: '1 1 100%'}}
                variant="h6"
                id="tableTitle"
                component="div"
                color="primary"
            >
                Token list
            </Typography>
        </Toolbar>
    );
}


const TokenList = () => {
    const classes = useStyles();
    const accountBalances = useSelector((state) => state.django.accountInformation?.aggregate?.accounts || []);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('value');
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };


    const getTrendingColor = (priceChangePercent) => {
        if (priceChangePercent > 0)
            return green[400]
        else if (priceChangePercent < 0)
            return red[400]
        return ""
    }

    // useEffect(() => {
    //     const loadImage = async (asset) => {
    //         try {
    //             const img = await import(`../../assets/cryptocurrency-icons/${asset.toLowerCase()}.svg`);
    //             setImageSrcs((prevSrcs) => ({ ...prevSrcs, [asset]: img.default }));
    //         } catch (err) {
    //             setImageSrcs((prevSrcs) => ({ ...prevSrcs, [asset]: 'path/to/fallback/image.svg' }));
    //         }
    //     };
    //
    //     accountBalances.forEach(({ asset }) => {
    //         loadImage(asset);
    //     });
    // }, [accountBalances]);

    return (
        <div>
            <Box sx={{width: '100%'}}>
                <Paper sx={{width: '100%', mb: 2}}>
                    <EnhancedTableToolbar/>
                    <TableContainer>
                        <Table
                            sx={{minWidth: 750}}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={accountBalances.length}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                                {stableSort(accountBalances, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow key={row.asset}>
                                                <TableCell align="left">
                                                    <img src={`cryptocurrency-icons/${row.asset.toLowerCase()}.png`} className={classes.icon} alt="token_logo"/>
                                                    {row.asset}</TableCell>
                                                <TableCell align="right">{(row.share).toFixed(2)} %</TableCell>
                                                <TableCell align="right"><Typography variant="body2" color={getTrendingColor(row.priceChangePercent24h)}>{(row.priceChangePercent24h).toFixed(2)} %</Typography></TableCell>
                                                <TableCell align="right">$ {row.price}</TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="body2" display="inline">{((row.available/row.balance)*row.value).toFixed(2)} $&nbsp;&nbsp;</Typography>
                                                    <Typography variant="body2" color={grey[500]} display="inline">({((row.available/row.balance)*100).toFixed(2)} %)</Typography>
                                                    </TableCell>
                                                <TableCell align="right">$ {row.value.toFixed(2)}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={accountBalances.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense}/>}
                    label="Dense padding"
                />
            </Box>
        </div>
    );
}

export default TokenList;