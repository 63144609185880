import * as React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardContent,
    CircularProgress,
    Container,
    Paper
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useStyles from "./dcaStatisticsModal.styles";
import {getDCAHistoryLaunch} from "../../features/django/djangoSlice";
import {useDispatch, useSelector} from "react-redux";
import {green, red} from "@mui/material/colors";
import TablePagination from "@mui/material/TablePagination";
import {useEffect} from "react";
import BotBarChart from "../botBarChart/botBarChart";
import Grid from "@mui/material/Grid";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BotPairBarChart from "../botPairBarChart/botPairBarChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const DcaStatisticsModalMobile = ({bot_id}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dcaHistoryData = (useSelector((state) => state.django.dcaHistoryData));
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);

    useEffect(() => {
        dispatch(getDCAHistoryLaunch({
            botId: bot_id
        }))
    }, [bot_id, dispatch])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const getOrderTypeColor = (order_type) => {
        if (order_type === 0)
            return ""
        else if (order_type === 1)
            return green[400]
        else if (order_type === 2)
            return green[400]
        else
            return red[400]
    }


    const processHistoryPairData = (filledData) => {
        const groupedData = filledData.reduce((acc, curr) => {
            if (curr.order_pnl !== null) {
                if (!acc[curr.pair]) {
                    acc[curr.pair] = {pair: curr.pair, pnl: 0};
                }
                acc[curr.pair].pnl += curr.order_pnl;
            }
            return acc;
        }, {});

        return Object.values(groupedData);
    };

    const processedData = dcaHistoryData ? processHistoryPairData(dcaHistoryData.filledData) : [];

    return (
        <div>
            {dcaHistoryData ?
                <Box className={classes.modal} sx={{width: '90%'}}>
                    <Paper sx={{width: '100%'}}>
                        <div className={classes.root}>
                            <AttachMoneyIcon color="primary" style={{marginLeft: 10, marginRight: 5, marginTop: 10}}
                                             fontSize="5px"/>
                            <Typography
                                sx={{display: 'inline', marginTop: 10}}
                                variant="h6"
                                id="tableTitle"
                                color="primary"
                            >
                                Daily profit
                            </Typography>
                            <BotBarChart chartData={dcaHistoryData.chartData}/>
                            <AttachMoneyIcon color="primary" style={{marginLeft: 10, marginRight: 5, marginTop: 10}}
                                             fontSize="5px"/>
                            <Typography
                                sx={{display: 'inline', marginTop: 10}}
                                variant="h6"
                                id="tableTitle"
                                color="primary"
                            >
                                Profit per pair
                            </Typography>
                            <BotPairBarChart chartData={processedData}/>
                            <Container maxWidth="false">
                                <Grid container justifyContent="flex-start" alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color="primary" sx={{textAlign: 'left'}}>
                                            Order ID
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body2" color="primary"
                                                    sx={{textAlign: 'left', marginLeft: '11px'}}>
                                            Type
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body2" color="primary"
                                                    sx={{textAlign: 'right', marginRight: '40px'}}>
                                            Profit
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                            {dcaHistoryData && dcaHistoryData.filledData && dcaHistoryData.filledData.length > 0 && dcaHistoryData.filledData !== [] ?
                                dcaHistoryData.filledData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <Accordion key={row.id}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={row.order_id}>
                                                <Grid container justifyContent="flex-start" alignItems="center">
                                                    <Grid item xs={5}>
                                                        <Typography>{row.order_id}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body2" color={getOrderTypeColor(row.line_id)}
                                                                    sx={{textAlign: 'left'}}>
                                                            {row.line_type}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography variant="body2"
                                                                    sx={{textAlign: 'right', marginRight: '16px'}}>
                                                            {row.order_pnl ? row.order_pnl.toFixed(2):""}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <CardContent className={classes.kpi}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{display: 'inline'}}
                                                                        color="textSecondary">Quantity:&nbsp;
                                                            </Typography>
                                                            <Typography variant="body1" color="textSecondary"
                                                                        sx={{display: 'inline'}}>
                                                                {row.order_original_qty}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{display: 'inline'}}
                                                                        color="textSecondary">Price:&nbsp;
                                                            </Typography>
                                                            <Typography variant="body1" color="textSecondary"
                                                                        sx={{display: 'inline'}}>
                                                                {row.order_price}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{display: 'inline'}}
                                                                        color="textSecondary">Buy quote:&nbsp;
                                                            </Typography>
                                                            <Typography variant="body1"
                                                                        sx={{display: 'inline'}}>
                                                                {row.order_side === "BUY" && row.order_quote_qty !== null ? row.order_quote_qty.toFixed(2): row.order_side === "SELL" && row.buy_order_quote_qty !== null ? row.buy_order_quote_qty.toFixed(2):""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{display: 'inline'}}
                                                                        color="textSecondary">Sell quote:&nbsp;
                                                            </Typography>
                                                            <Typography variant="body1" color="textSecondary"
                                                                        sx={{display: 'inline'}}>
                                                                {row.order_side === "SELL" && row.order_quote_qty !== null ? row.order_quote_qty.toFixed(2):""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" sx={{display: 'inline'}}
                                                                        color="textSecondary">Time:&nbsp;
                                                            </Typography>
                                                            <Typography variant="body1" sx={{display: 'inline'}}>
                                                                {row.order_created_time !== null ? (new Date(row.order_created_time)).toLocaleString() : ""}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </AccordionDetails>
                                        </Accordion>
                                    )) :
                                <Typography variant="body1" color="textSecondary"></Typography>}
                            <TablePagination
                                rowsPerPageOptions={[2, 4, 6]}
                                component="div"
                                count={dcaHistoryData.filledData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </Paper>
                </Box> :
                <CircularProgress disableShrink/>}
        </div>
    )
        ;
}

export default DcaStatisticsModalMobile;