import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    icon:{
        marginRight:5,
        marginLeft:5,
        height:40,
        width:40,
        verticalAlign:-8
    }
});

export default useStyles;