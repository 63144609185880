import React from 'react';
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PreviewIcon from '@mui/icons-material/Preview';
import {useDispatch, useSelector} from "react-redux";

import StatisticsModal from "../statisticsModal/statisticsModal";
import {Modal, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import useStyles from "./botControlPanel.styles";
import StartBotModal from "../startBotModal/startBotModal";
import StopBotModal from "../stopBotModal/stopBotModal";
import DeleteBotModal from "../deleteBotModal/deleteBotModal";
import StatisticsModalMobile from "../statisticsModal/statisticsModalMobile";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyBotModal from "../copyBotModal/copyBotModal";
import ListAltIcon from '@mui/icons-material/ListAlt';
import LogModal from "../logModal/logModal";
import {saveSelectedPair} from "../../features/django/djangoSlice";


export const BotControlPanel = ({bot_status, bot_id, pair, tick_size, quote_asset, base_asset, exchange}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorPlay, setAnchorPlay] = React.useState(null);
    const openPlay = Boolean(anchorPlay);
    const [anchorStop, setAnchorStop] = React.useState(null);
    const openStop = Boolean(anchorStop);
    const [anchorConfig, setAnchorConfig] = React.useState(null);
    const openConfig = Boolean(anchorConfig);
    const [anchorChart, setAnchorChart] = React.useState(null);
    const openChart = Boolean(anchorChart);
    const [anchorLog, setAnchorLog] = React.useState(null);
    const openLog = Boolean(anchorLog);
    const [anchorCopy, setAnchorCopy] = React.useState(null);
    const openCopy = Boolean(anchorCopy);
    const [anchorDelete, setAnchorDelete] = React.useState(null);
    const openDelete = Boolean(anchorDelete);
    const [isModalShown, setIsModalShown] = React.useState(false);
    const [isChartModal, setIsChartModal] = React.useState(false);
    const [isStartModal, setIsStartModal] = React.useState(false);
    const [isStopModal, setIsStopModal] = React.useState(false);
    const [isCopyModal, setIsCopyModal] = React.useState(false);
    const [isLogModal, setIsLogModal] = React.useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const botList = (useSelector((state) => state.django.botListData)).filter(pilot => pilot.bot_status !== "DEACTIVATED");
    const dcaList = (useSelector((state) => state.django.dcaListData)).filter(pilot => pilot.bot_status !== "DEACTIVATED");

    const handlePopoverOpenPlay = (event) => {
        setAnchorPlay(event.currentTarget);
    };
    const handlePopoverClosePlay = () => {
        setAnchorPlay(null);
    };
    const handlePopoverOpenStop = (event) => {
        setAnchorStop(event.currentTarget);
    };
    const handlePopoverCloseStop = () => {
        setAnchorStop(null);
    };
    const handlePopoverOpenConfig = (event) => {
        setAnchorConfig(event.currentTarget);
    };
    const handlePopoverCloseConfig = () => {
        setAnchorConfig(null);
    };
    const handlePopoverOpenChart = (event) => {
        setAnchorChart(event.currentTarget);
    };
    const handlePopoverCloseChart = () => {
        setAnchorChart(null);
    };
    const handlePopoverOpenLog = (event) => {
        setAnchorLog(event.currentTarget);
    };
    const handlePopoverCloseLog = () => {
        setAnchorLog(null);
    };
    const handlePopoverOpenCopy = (event) => {
        setAnchorCopy(event.currentTarget);
    };
    const handlePopoverCloseCopy = () => {
        setAnchorCopy(null);
    };
    const handlePopoverOpenDelete = (event) => {
        setAnchorDelete(event.currentTarget);
    };
    const handlePopoverCloseDelete = () => {
        setAnchorDelete(null);
    };

    const handleModalClose = () => setIsModalShown(false);

    const isFunctionToBeFired = !botList.some(
        (bot) => bot.pair === pair && bot.bot_status === 'ACTIVE'
    ) && !dcaList.some(
        (bot) => bot.pairs_m2m.includes(pair) && bot.bot_status === 'ACTIVE'
    );

    return (
        <div>
            <Modal
                open={isModalShown}
                onClose={handleModalClose}
                aria-labelledby="statisticsModal"
                aria-describedby="statisticsModalDescription"
            >
                <React.Fragment>
                    {isChartModal === true ? isMobile ? <StatisticsModalMobile bot_id={bot_id}/> :
                        <StatisticsModal bot_id={bot_id}/> : isStartModal === true ?
                        <StartBotModal bot_id={bot_id} handleModalClose={handleModalClose}/> : isStopModal === true ?
                            <StopBotModal bot_id={bot_id} handleModalClose={handleModalClose}/> : isCopyModal === true ?
                                <CopyBotModal bot_id={bot_id} handleModalClose={handleModalClose}/> : isLogModal === true ?
                                    <LogModal bot_id={bot_id} handleModalClose={handleModalClose}/> :
                                    <DeleteBotModal bot_id={bot_id} handleModalClose={handleModalClose}/>}
                </React.Fragment>
            </Modal>
            {bot_status === "NEW" && isFunctionToBeFired ?
                (
                    <IconButton aria-label="play" className={classes.icon} onClick={() => {
                        setIsChartModal(false)
                        setIsStopModal(false)
                        setIsStartModal(true)
                        setIsCopyModal(false)
                        setIsLogModal(false)
                        setIsModalShown(current => !current);
                    }
                    }>
                        <PlayArrowOutlinedIcon
                            aria-owns={openPlay ? 'mouse-over-popover-play' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpenPlay}
                            onMouseLeave={handlePopoverClosePlay}/>
                    </IconButton>
                ) : (bot_status === "NEW" && !isFunctionToBeFired ? (
                    <IconButton aria-label="play-disabled" className={classes.icon}>
                        <PlayArrowOutlinedIcon className={classes.icon} color="disabled"/>
                    </IconButton>
                ) : (bot_status === "DEACTIVATED" ? (
                        <IconButton aria-label="stop-disabled" className={classes.icon}>
                            <StopIcon className={classes.icon} color="disabled"/>
                        </IconButton>
                    ) :
                    (
                        <IconButton aria-label="stop" className={classes.icon} onClick={() => {
                            setIsChartModal(false)
                            setIsStopModal(true)
                            setIsStartModal(false)
                            setIsCopyModal(false)
                            setIsLogModal(false)
                            setIsModalShown(current => !current);
                        }
                        }>
                            <StopIcon
                                aria-owns={openStop ? 'mouse-over-popover-stop' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpenStop}
                                onMouseLeave={handlePopoverCloseStop}
                            />
                        </IconButton>
                    )))}
            {!isMobile && (<Popover
                id="mouse-over-popover-play"
                sx={{pointerEvents: 'none',}}
                open={openPlay}
                anchorEl={anchorPlay}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{
                    vertical: 'top', horizontal: 'left'
                }}
                onClose={handlePopoverClosePlay}
                disableRestoreFocus
            >
                <Typography sx={{p: 1}}>Start</Typography>
            </Popover>)}
            {!isMobile && (<Popover
                id="mouse-over-popover-stop"
                sx={{pointerEvents: 'none',}}
                open={openStop}
                anchorEl={anchorStop}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{
                    vertical: 'top', horizontal: 'left'
                }}
                onClose={handlePopoverCloseStop}
                disableRestoreFocus
            >
                <Typography sx={{p: 1}}>Stop</Typography>
            </Popover>)}
            <IconButton aria-label="preview" className={classes.icon} onClick={() => {
                dispatch(saveSelectedPair({
                    'pair': pair,
                    "quoteAsset": quote_asset,
                    "baseAsset": base_asset,
                    "exchange": exchange,
                    'tickSize': 1 / 10 ** tick_size,
                    'grid': [],
                    'bot_id': bot_id
                }))
            }
            }>
                <PreviewIcon
                    aria-owns={openConfig ? 'mouse-over-popover-config' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpenConfig}
                    onMouseLeave={handlePopoverCloseConfig}
                />
            </IconButton>
            {!isMobile && (<Popover
                id="mouse-over-popover-config"
                sx={{pointerEvents: 'none',}}
                open={openConfig}
                anchorEl={anchorConfig}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{
                    vertical: 'top', horizontal: 'left'
                }}
                onClose={handlePopoverCloseConfig}
                disableRestoreFocus
            >
                <Typography sx={{p: 1}}>See config</Typography>
            </Popover>)}
            <IconButton aria-label="bar-chart" className={classes.icon} onClick={() => {
                setIsChartModal(true)
                setIsStopModal(false)
                setIsStartModal(false)
                setIsCopyModal(false)
                setIsLogModal(false)
                setIsModalShown(current => !current);
            }
            }>
                <BarChartIcon
                    aria-owns={openChart ? 'mouse-over-popover-chart' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpenChart}
                    onMouseLeave={handlePopoverCloseChart}
                />
            </IconButton>
            {!isMobile && (<Popover
                id="mouse-over-popover-chart"
                sx={{pointerEvents: 'none',}}
                open={openChart}
                anchorEl={anchorChart}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{
                    vertical: 'top', horizontal: 'left'
                }}
                onClose={handlePopoverCloseChart}
                disableRestoreFocus
            >
                <Typography sx={{p: 1}}>Performance</Typography>
            </Popover>)}
            <IconButton aria-label="log-file" className={classes.icon} onClick={() => {
                setIsChartModal(false)
                setIsStopModal(false)
                setIsStartModal(false)
                setIsCopyModal(false)
                setIsLogModal(true)
                setIsModalShown(current => !current);
            }
            }>
                <ListAltIcon
                    aria-owns={openLog ? 'mouse-over-popover-log' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpenLog}
                    onMouseLeave={handlePopoverCloseLog}
                />
            </IconButton>
            {!isMobile && (<Popover
                id="mouse-over-popover-log"
                sx={{pointerEvents: 'none',}}
                open={openLog}
                anchorEl={anchorLog}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{
                    vertical: 'top', horizontal: 'left'
                }}
                onClose={handlePopoverCloseLog}
                disableRestoreFocus
            >
                <Typography sx={{p: 1}}>Logs</Typography>
            </Popover>)}
            <IconButton aria-label="copy" className={classes.icon} onClick={() => {
                setIsChartModal(false)
                setIsStopModal(false)
                setIsStartModal(false)
                setIsCopyModal(true)
                setIsLogModal(false)
                setIsModalShown(current => !current);
            }
            }>
                <ContentCopyIcon
                    aria-owns={openCopy ? 'mouse-over-popover-copy' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpenCopy}
                    onMouseLeave={handlePopoverCloseCopy}
                />
            </IconButton>
            {!isMobile && (<Popover
                id="mouse-over-popover-Copy"
                sx={{pointerEvents: 'none',}}
                open={openCopy}
                anchorEl={anchorCopy}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{
                    vertical: 'top', horizontal: 'left'
                }}
                onClose={handlePopoverCloseCopy}
                disableRestoreFocus
            >
                <Typography sx={{p: 1}}>Copy</Typography>
            </Popover>)}
            {bot_status === "DEACTIVATED" || bot_status === "NEW" ? (
                    <IconButton aria-label="delete" className={classes.icon} onClick={() => {
                        setIsChartModal(false)
                        setIsStopModal(false)
                        setIsStartModal(false)
                        setIsCopyModal(false)
                        setIsLogModal(false)
                        setIsModalShown(current => !current);
                    }
                    }>
                        <DeleteForeverIcon
                            aria-owns={openDelete ? 'mouse-over-popover-delete' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpenDelete}
                            onMouseLeave={handlePopoverCloseDelete}
                        />
                    </IconButton>)
                :
                (<IconButton aria-label="delete" className={classes.icon} onClick={() => {
                }
                }>
                    <DeleteForeverIcon color="disabled"
                                       aria-owns={openDelete ? 'mouse-over-popover-delete' : undefined}
                                       aria-haspopup="true"
                                       onMouseEnter={handlePopoverOpenDelete}
                                       onMouseLeave={handlePopoverCloseDelete}
                    />
                </IconButton>)}
            {!isMobile && (<Popover
                id="mouse-over-popover-delete"
                sx={{pointerEvents: 'none',}}
                open={openDelete}
                anchorEl={anchorDelete}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{
                    vertical: 'top', horizontal: 'left'
                }}
                onClose={handlePopoverCloseDelete}
                disableRestoreFocus
            >
                <Typography sx={{p: 1}}>Delete</Typography>
            </Popover>)}
        </div>
    );
}
