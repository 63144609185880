import { createTheme } from '@mui/material/styles';
// import {blue} from "@mui/material/colors";

export const theme = createTheme({
    palette: {
        type: 'dark',
        mode: 'dark',
        primary: {
            main: '#5893df',
        },
        secondary: {
            main: '#2ec5d3',
        },
        background: {
            default: '#192231',
            paper: '#24344d',
        },
        trending: {
            light: '#57ff00',
            main: '#f57c00',
            dark: '#ef0000',
            contrastText: 'rgba(215,22,22,0.87)'
        },
        // Provide every color token (light, main, dark, and contrastText) when using
        // custom colors for props in Material UI's components.
        // Then you will be able to use it like this: `<Button color="custom">`
        // (For TypeScript, you need to add module augmentation for the `custom` value)
        custom: {
            light: '#ffa726',
            main: '#f57c00',
            dark: '#ef6c00',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
    //     // Used by `getContrastText()` to maximize the contrast between
    //     // the background and the text.
    //     contrastThreshold: 3,
    //     // Used by the functions below to shift a color's luminance by approximately
    //     // two indexes within its tonal palette.
    //     // E.g., shift from Red 500 to Red 300 or Red 700.
    //     tonalOffset: 0.2,
    },
    // typography: {
    //     fontFamily: [
    //         '-apple-system',
    //         'BlinkMacSystemFont',
    //         '"Segoe UI"',
    //         'Roboto',
    //         '"Helvetica Neue"',
    //         'Arial',
    //         'sans-serif',
    //         '"Apple Color Emoji"',
    //         '"Segoe UI Emoji"',
    //         '"Segoe UI Symbol"',
    //     ].join(','),
    //     fontSize: 12,
    //     typography: {
    //         subtitle1: {
    //             fontSize: 12,
    //         },
    //         subtitle2: {
    //             fontSize: 4
    //         },
    //         body1: {
    //             fontWeight: 500,
    //         },
    //         button: {
    //             fontStyle: 'italic',
    //         },
    //     },
    // },
    // components: {
    //     MuiButton: {
    //         variants: [
    //             {
    //                 props: { variant: 'contained' },
    //                 style: {
    //                     border: `1px solid ${blue[500]}`,
    //                 },
    //             },
    //         ],
    //     },
    // },
});
