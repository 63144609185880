import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useStyles from "./logModal.styles";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import { getLogFileLaunch} from "../../features/django/djangoSlice";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardContent,
    CircularProgress,
    Paper, useMediaQuery
} from "@mui/material";
import {useEffect, useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TablePagination from "@mui/material/TablePagination";
import Toolbar from "@mui/material/Toolbar";
import ListAltIcon from '@mui/icons-material/ListAlt';
import {green, red, yellow} from "@mui/material/colors";
import {StyledSelect} from "../select/styledSelect";

const LogModal = ({bot_id, bot_type}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const logFile = (useSelector((state) => state.django.logFile));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [logType, setLogType] = useState('');
    const listLogType = [
        {text: 'INFO', value: 'INFO'},
        {text: 'WARNING', value: 'WARNING'},
        {text: 'ERROR', value: 'ERROR'},
        {text: 'CRITICAL', value: 'CRITICAL'},
        {text: 'ALL', value: ''},
        ]

    useEffect(() => {
        dispatch(getLogFileLaunch({
            botId: bot_id,
            botType: bot_type
        }))
    }, [bot_id, dispatch])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const getLogTypeColor = (type) => {
        if (type === "INFO")
            return green[500]
    else if (type === "WARNING")
            return yellow[500]
        else if (type === "ERROR" || type === "CRITICAL")
            return red[500]
        else
            return ""
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value));
        setPage(0);
    };

    const logs = logFile
        .split('\n')
        .map(log => {
            const [timestamp_type, pid, message] = log.split(' - ');
            const timestampTypeArray = timestamp_type.split(' [');
            const timestamp = timestampTypeArray[0];
            const type = timestampTypeArray.length > 1 ? timestampTypeArray[1].slice(0, -1) : '';
            return {
                timestamp,
                type,
                pid,
                message
            };
        })
        .reverse();

    const filteredLogs = logType ? logs.filter(log => log.type === logType) : logs;

    return (
        <Box className={isMobile ? classes.modalMobile : classes.modalDesktop}>
            <div>
                {logs ?
                    <Box className={isMobile ? classes.modalMobile : classes.modalDesktop} sx={{width: '90%'}}>
                        <Paper sx={{width: '100%'}}>
                            <div className={classes.root}>
                                <Toolbar
                                    sx={{
                                        pl: {sm: 2},
                                        pr: {xs: 1, sm: 1}
                                    }}
                                >
                                    <ListAltIcon color="primary" style={{marginRight:5, marginTop:25, marginBottom:25}}/>
                                    <Typography
                                        sx={{flex: '1 100%'}}
                                        variant="h6"
                                        id="chartTitle"
                                        component="div"
                                        color="primary"
                                    >
                                        Daily profit
                                    </Typography>
                                    <StyledSelect
                                        labelId="log_type"
                                        id="outlined"
                                        value={logType}
                                        label="Log type"
                                        listItems={listLogType}
                                        handleChange={setLogType}
                                    />
                                </Toolbar>
                                {filteredLogs
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <Accordion key={`${row.timestamp}-${index}`}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={row.timestamp}>
                                                    <Grid container justifyContent="flex-start" alignItems="center">
                                                        <Grid item xs={9}>
                                                            <Typography>{row.timestamp}</Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="body2" sx={{textAlign: 'left'}} color={getLogTypeColor(row.type)}>
                                                                {row.type}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <CardContent className={classes.kpi}>
                                                        <Typography variant="body1" sx={{display: 'inline'}}
                                                                    color="textSecondary">{row.pid ? row.pid : ""}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary"
                                                                    sx={{display: 'inline'}}>
                                                            {row.message ? row.message : ""}
                                                        </Typography>
                                                    </CardContent>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                }
                                <TablePagination
                                    component="div"
                                    count={filteredLogs.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 15]}
                                    labelDisplayedRows={({from, to, count}) => `${from}-${to} / ${count}`}
                                />
                            </div>
                        </Paper>
                    </Box> :
                    <CircularProgress disableShrink/>}
            </div>
        </Box>
    )
        ;
}

export default LogModal;