import React, {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, CardContent, Container, Paper, Typography} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import {useSelector} from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {green, grey, red} from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import useStyles from "./tokenList.styles";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {visuallyHidden} from "@mui/utils";


const headCells = [
    {
        id: 'asset',
        numeric: false,
        disablePadding: false,
        label: 'Token',
    },
    {
        id: 'share',
        numeric: true,
        disablePadding: false,
        label: 'Share',
    },
    {
        id: 'priceChangePercent24h',
        numeric: true,
        disablePadding: false,
        label: 'Change (24H)',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'Price',
    },
    {
        id: 'available',
        numeric: true,
        disablePadding: false,
        label: 'Available Amount',
    },
    {
        id: 'value',
        numeric: true,
        disablePadding: false,
        label: 'Total value',
    },
];

function EnhancedTableHead(props) {
    const {order, orderBy, onRequestSort} =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const TokenListMobile = () => {
    const classes = useStyles();
    const accountBalances = useSelector((state) => state.django.accountInformation?.aggregate?.accounts || []);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value));
        setPage(0);
    };

    const getTrendingColor = (priceChangePercent) => {
        if (priceChangePercent > 0)
            return green[400]
        else if (priceChangePercent < 0)
            return red[400]
        return ""
    }

    return (
        <div className={classes.root}>
            <Box sx={{width: '100%'}}>
                <Paper sx={{width: '100%', mb: 3}} style={{padding: 10}}>
                    <Container maxWidth="false">
                        <Grid container direction="row">
                            <Grid item xs={5}>
                                <Grid container justifyContent="flex-start">
                                    <Typography color="primary"
                                                sx={{textAlign: 'left', marginRight: '16px'}}>Token</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container justifyContent="flex-end" direction="row">
                                    <Grid item>
                                        <Typography color="primary" variant="body2"
                                                    sx={{textAlign: 'right', marginRight: '12px'}}>
                                            Change
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justifyContent="flex-end" direction="row">
                                    <Grid item>
                                        <Typography color="primary" variant="body2"
                                                    sx={{textAlign: 'right', marginRight: '40px'}}>Value</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    {accountBalances
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                            <Accordion key={row.asset}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={row.asset}>
                                    <Grid container justifyContent="flex-start" alignItems="center">
                                        <img src={`cryptocurrency-icons/${row.asset.toLowerCase()}.png`} className={classes.icon} alt="token_logo"/>
                                        <Typography sx={{width: '33%', flexShrink: 0}}>{row.asset}</Typography>
                                    </Grid>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={4}>
                                            <Typography variant="body2" sx={{textAlign: 'right'}}
                                                        color={getTrendingColor(row.priceChangePercent24h)}>
                                                {row.priceChangePercent24h.toFixed(2)} %
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body2" sx={{textAlign: 'right', marginRight: '16px'}}>
                                                $ {row.value.toFixed(2)}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CardContent className={classes.kpi}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography variant="body1" sx={{display: 'inline'}}
                                                            color="textSecondary">Share:&nbsp;
                                                </Typography>
                                                <Typography variant="body1" sx={{display: 'inline'}}>
                                                    {(row.share).toFixed(2)} %
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1" sx={{display: 'inline'}}
                                                            color="textSecondary">Price:&nbsp;
                                                </Typography>
                                                <Typography variant="body1" sx={{display: 'inline'}}>
                                                    {(row.price)} $
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" sx={{display: 'inline'}}
                                                            color="textSecondary">Available
                                                    amount:&nbsp;
                                                </Typography>
                                                <Typography variant="body1" sx={{display: 'inline'}}>
                                                    {((row.available / row.balance) * row.value).toFixed(2)} $&nbsp;&nbsp;
                                                </Typography>
                                                <Typography variant="body2" color={grey[500]}
                                                            display="inline">({((row.available / row.balance) * 100).toFixed(2)} %)</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    <TablePagination
                        component="div"
                        count={accountBalances.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 20, 30]}
                        labelDisplayedRows={({from, to, count}) => `${from}-${to} / ${count}`}
                    />
                </Paper>
            </Box>
        </div>
    );
}

export default TokenListMobile;