import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    paper: {
        marginTop: 80,
        margin: 'auto',
        width: '25%',
        minWidth: 300,
        padding: 30
    },
    icon:{
        marginRight:10,
        height:25,
        width:25,
        verticalAlign:-8
    }
});

export default useStyles;