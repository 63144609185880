import * as React from 'react';

import {
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
} from 'recharts'


const COLORS = ['#79A8E5', '#57D0DB', '#E57373', '#FFB74D', '#64B5F6', '#81C784', '#3D669C', '#9DC4EC', '#4ECDC4', '#E67A77', '#FFCE54', '#5DADE2', '#8BC34A', '#4A89DC', '#2ECC71', '#F7CA18', '#3498DB', '#F5B041', '#48C9B0', '#E74C3C', '#F39C12', '#2980B9', '#C8F7C5', '#9B59B6', '#FDE3A7', '#1ABC9C', '#D35400'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const AccountPieChart = ({ chartData }) => {

    // Sort data by value
    const sortedData = [...chartData].sort((a, b) => b.value - a.value);

    // Get top 8 assets
    const top8Assets = sortedData.slice(0, 8).map((entry) => entry.asset);

    // Filter legend items for top 8 assets
    const legendPayload = sortedData
        .filter((entry) => top8Assets.includes(entry.asset))
        .map((entry, index) => ({ value: entry.asset, id: `cell-${index}`, type: 'square', color: COLORS[index % COLORS.length] }));

    return (
        <ResponsiveContainer width='95%' height={230}>
            <PieChart width={730} height={250}>
                <Pie data={sortedData} dataKey="value" nameKey="asset" fill="#82ca9d" labelLine={false} label={renderCustomizedLabel}>
                    {sortedData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                    <Legend align="left" layout="vertical" verticalAlign="middle" payload={legendPayload} />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default AccountPieChart;
