import React, {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, CardContent, Container, Typography} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import {useSelector} from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {green, grey, orange, red} from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import useStyles from "./botListActive.styles";
import {BotControlPanel} from "../botControlPanel/botControlPanel";


const BotListActiveMobile = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const botList = (useSelector((state) => state.django.botListData)).filter(pilot => pilot.bot_status !== "DEACTIVATED");
    const botListActive = (useSelector((state) => state.django.botListData)).filter(pilot => pilot.bot_status === "ACTIVE" && pilot.pid_status === "RUNNING");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value));
        setPage(0);
    };

    const getTrendingColor = (priceChangePercent) => {
        if (priceChangePercent > 0)
            return green[400]
        else if (priceChangePercent < 0)
            return red[400]
        return ""
    }

    const getStatusColor = (status, pid_status) => {
        if (pid_status === "NOT RUNNING")
            return red[500]
        else if (status === "ACTIVE")
            return green[400]
        else if (status === "RANGE")
            return orange[500]
        else if (status === "DEACTIVATED")
            return grey[500]
        else
            return ""
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="false">
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-start">
                            <Typography color="primary" sx={{textAlign: 'left', marginRight: '16px'}}>Pair ({botListActive.length}/{botList.length})</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-end" direction="row">
                            <Grid item>
                                <Typography color="primary" variant="body2"
                                            sx={{textAlign: 'right', marginRight: '20px'}}>
                                    PNL
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography color="primary" variant="body2"
                                            sx={{textAlign: 'right', marginRight: '44px'}}>Profit</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {botList
                .sort((a, b) => b.id - a.id)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                <Accordion key={row.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={row.id}>
                        <Grid container justifyContent="flex-start" alignItems="center">
                            <img src={`cryptocurrency-icons/${row.base_asset.toLowerCase()}.png`} className={classes.icon} alt="token_logo"/>
                            <Typography sx={{width: '33%', flexShrink: 0}} color={getStatusColor(row.bot_status, row.pid_status)}>{row.pair}</Typography>
                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Typography variant="body2" sx={{textAlign: 'right', marginRight: '16px'}}
                                            color={row.pnl !== 0 ? getTrendingColor(parseFloat((row.pnl / row.investment_amount - 1) * 100)) : ""}>
                                    {(row.pnl !== null && row.pnl !== 0 ? parseFloat((row.pnl / row.investment_amount - 1) * 100).toFixed(2) : parseFloat("0.00").toFixed(2))}%
                                </Typography>
                                <Typography variant="body2" sx={{textAlign: 'right', marginRight: '16px'}}>
                                    {(row.pnl !== null ? parseFloat(row.pnl).toFixed(2) : parseFloat("0.00").toFixed(2))}$
                                </Typography>
                                {/*</div>*/}
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" sx={{textAlign: 'right', marginRight: '16px'}}
                                            color={getTrendingColor(parseFloat(row.profit) / row.investment_amount * 100)}>{row.profit !== null ? (parseFloat(row.profit) / row.investment_amount * 100).toFixed(2) : parseFloat("0.00").toFixed(2)}%</Typography>
                                <Typography
                                    sx={{width: '33%', flexShrink: 0, textAlign: 'right', marginRight: '16px'}}
                                    variant="body2">{(row.profit !== null ? parseFloat(row.profit).toFixed(2) : parseFloat("0.00").toFixed(2))}$</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CardContent className={classes.kpi}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">ID:&nbsp;
                                    </Typography>
                                    <Typography variant="body1" sx={{ display: 'inline' }} >
                                        {row.id}
                                    </Typography>
                                    {row.exchange === "CRO"?
                                        <img src={`cryptocurrency-icons/crypto.png`} className={classes.icon} alt="token_logo"/>
                                        :
                                        <img src={`cryptocurrency-icons/bybit.png`} className={classes.icon} alt="token_logo"/>
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Status:&nbsp;
                                    </Typography>
                                    <Typography variant="body1" sx={{ display: 'inline' }} color={getStatusColor(row.bot_status)}>{row.bot_status}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Nb trans:&nbsp;
                                    </Typography>
                                    <Typography variant="body1" sx={{ display: 'inline' }} >
                                        {(row.nb_transactions !== null ? parseFloat(row.nb_transactions) : 0)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Avg profit:&nbsp;
                                    </Typography>
                                    <Typography variant="body1" color={getTrendingColor(parseFloat(row.profit) / row.investment_amount * 100)} sx={{ display: 'inline' }} >
                                        {(((row.profit / ((new Date().getTime() - new Date(row.creation_date).getTime()) / (1000 * 3600 * 24))) / row.investment_amount * 100).toFixed(2))}%
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Start:&nbsp;
                                    </Typography>
                                    <Typography variant="body1" sx={{ display: 'inline' }}>
                                        {row.creation_date !== null ? (new Date(row.creation_date)).toLocaleString('en-GB',{ day: '2-digit', month: '2-digit', year: 'numeric' }) : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Init:&nbsp;
                                    </Typography>
                                    <Typography variant="body1" sx={{ display: 'inline' }} >
                                        {(row.investment_amount !== null ? parseFloat(row.investment_amount).toFixed(2) : parseFloat("0.00").toFixed(2))}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" sx={{ display: 'inline' }} color="textSecondary">Config:&nbsp;
                                    </Typography>
                                    <Typography variant="body1" sx={{ display: 'inline' }}>{row.low_price !== null ? row.low_price : ""} | {row.high_price !== null ? row.high_price : ""} | {row.grid_step_size !== null ? row.grid_step_size : ""}% | {row.grid_lines !== null ? row.grid_lines : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-end" justifyContent="center">
                                <Grid item sx={{ mt: 2 }}>
                                    <BotControlPanel bot_status={row.bot_status} bot_id={row.id}
                                                       pair={row.pair} tick_size={row.tick_size} quote_asset={row.quote_asset} base_asset={row.base_asset} exchange={row.exchange}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </AccordionDetails>
                </Accordion>
            ))}
            <TablePagination
                component="div"
                count={botList.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
        </div>
    );
};

export default BotListActiveMobile;