import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import useStyles from './dcaPairListActive.styles';
import {useSelector} from "react-redux";
import {green, red} from "@mui/material/colors";
import {DcaPairControlPanel} from "../dcaPairControlPanel/dcaPairControlPanel";

const DCAPairListActive = ({ activePairs, botRow }) => {
    const classes = useStyles();
    const tickerData = useSelector((state) => state.binance.tickerData);

    const getTrendingColor = (priceChangePercent) => {
        if (priceChangePercent > 0)
            return green[400]
        else if (priceChangePercent < 0)
            return red[400]
        return ""
    }

    const getAveragingColor = (numberOfAveragingFilledOrders, averagingOrderQuantity) => {
        if (numberOfAveragingFilledOrders === averagingOrderQuantity)
            return red[400]
        else
            return ""
    }

    return (
        <TableRow className={classes.metricsRow}>
            <TableCell colSpan={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Pair (N°)</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Profit</TableCell>
                                <TableCell>Investment</TableCell>
                                <TableCell>Filled Averaging </TableCell>
                                <TableCell>Duration</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activePairs.map((activePair) => {
                                const baseLine = botRow.dca_grid.find(
                                    (line) => line.pair === activePair && line.line_type === 'BASE'
                                );
                                const takeProfitLine = botRow.dca_grid.find(
                                    (line) => line.pair === activePair && line.line_type === 'TAKE_PROFIT'
                                );
                                const baseAsset = baseLine ? baseLine.base_asset : 'N/A';
                                const quoteAsset = baseLine ? baseLine.quote_asset : 'N/A';
                                const pair = baseLine ? baseLine.pair : 'N/A';
                                const currentPrice = tickerData.find((pair) => pair.symbol === activePair) ? tickerData.find((pair) => pair.symbol === activePair).price : 'N/A';
                                const currentValue = (takeProfitLine.order_original_qty * currentPrice).toFixed(2)
                                const baseAndAveragingFilledOrders = botRow.dca_grid.filter(
                                    (line) => line.pair === activePair && (line.line_type === 'BASE' || line.line_type === 'AVERAGING') && line.order_status === 'FILLED'
                                );
                                const totalOrderQuoteQty = baseAndAveragingFilledOrders.reduce((sum, order) => sum + order.order_quote_qty, 0).toFixed(2);
                                const currentProfit = (currentValue - totalOrderQuoteQty).toFixed(2)
                                const averagingFilledOrders = botRow.dca_grid.filter(
                                    (line) => line.pair === activePair && line.line_type === 'AVERAGING' && line.order_status === 'FILLED'
                                );
                                const numberOfAveragingFilledOrders = averagingFilledOrders.length;
                                const baseOrderCreatedTime = baseLine.order_created_time;
                                const currentTime = new Date();
                                const durationMs = currentTime - new Date(baseOrderCreatedTime);
                                const durationHours = durationMs / (1000 * 60 * 60);
                                const days = Math.floor(durationHours / 24)
                                const hours = Math.floor(durationHours % 24);
                                const durationFormatted = `${days}D/${hours}H`;


                                return (
                                    <TableRow key={activePair}>
                                        <TableCell align="left">
                                            <img
                                                src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${baseAsset.toLowerCase()}.svg`}
                                                className={classes.icon}
                                                alt="token_logo"
                                            />
                                            {activePair} ({baseLine.active_pair_number})
                                        </TableCell>
                                        <TableCell align="left">
                                                <Typography variant="body1" sx={{ display: 'inline' }} color={getTrendingColor(currentValue - totalOrderQuoteQty)}>
                                                    {currentValue}
                                                </Typography>
                                                <Typography variant="body2" sx={{ display: 'inline'}} color="text.secondary">
                                                    {" " + quoteAsset}
                                                </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <div>
                                                <Typography variant="body1" sx={{ display: 'inline' }} color={getTrendingColor(currentProfit)}>
                                                    {(currentProfit/totalOrderQuoteQty * 100).toFixed(2)} %
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="body2" sx={{ display: 'inline' }}>
                                                    {currentProfit}
                                                </Typography>
                                                <Typography variant="body2" sx={{ display: 'inline'}} color="text.secondary">
                                                    {" " + quoteAsset}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <div>
                                                <Typography variant="body1" sx={{ display: 'inline' }}>
                                                    {totalOrderQuoteQty}
                                                </Typography>
                                                <Typography variant="body2" sx={{ display: 'inline'}} color="text.secondary">
                                                    {" " + quoteAsset}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="body2" sx={{ display: 'inline' }}>
                                                    {takeProfitLine.order_original_qty}
                                                </Typography>
                                                <Typography variant="body2" sx={{ display: 'inline'}} color="text.secondary">
                                                    {" " + baseAsset}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant="body1" color={getAveragingColor(numberOfAveragingFilledOrders, botRow.averaging_order_quantity)}>
                                                {numberOfAveragingFilledOrders}/{botRow.averaging_order_quantity}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            {durationFormatted}
                                        </TableCell>
                                        <TableCell align="right">
                                            <DcaPairControlPanel bot_id={botRow.id} pair={pair} base_asset={baseAsset} quote_asset={quoteAsset} current_position_size={takeProfitLine.order_original_qty}/>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TableCell>
        </TableRow>
    );
};

export default DCAPairListActive;
